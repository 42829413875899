import type { FC } from "react";
import { useState, useEffect } from "react";
import { TextInput, Select } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { StandardForm, Dialog, CustomStyledSelect } from "../..";
import { IDriver, ICompany, IVehicle, LicensesEnum } from "../../../types";
import { useTranslation } from "react-i18next";
import { Driver } from "../../../models";

export interface DriverModalProp {
  data: IDriver;
  companies: ICompany[];
  vehicles: IVehicle[];
  isShowing: boolean;
  onConfirm: (data: IDriver) => void;
  onCancel: () => void;
}

export const DriverForm: FC<DriverModalProp> = function (
  props: DriverModalProp,
) {
  const defaultCompany = () => {
    return props.data.companyId.length > 0
      ? props.data.companyId
      : props.companies.length >= 1
        ? props.companies[0].id
        : undefined;
  };

  const filterVehiclesForCompany = (companyId: string | undefined) => {
    if (companyId === undefined) {
      return [] as IVehicle[];
    }
    return props.vehicles.filter((vehicle) => vehicle.companyId === companyId);
  };

  const [selectedCompany, setSelectedCompany] = useState(defaultCompany());
  const [availableVehicles, setAvailableVehicles] = useState(
    filterVehiclesForCompany(defaultCompany()),
  );

  const { t } = useTranslation(["common", "user", "company", "inventory"]);

  const [isNew] = useState(Driver.isNew(props.data));

  const licenses = Object.values(LicensesEnum);
  const licenseOptions = licenses.map((l) => {
    return { value: l, label: l };
  });

  useEffect(() => {
    setAvailableVehicles(filterVehiclesForCompany(selectedCompany));
  }, [selectedCompany]);

  return (
    <StandardForm
      showHeader={false}
      description={t("modals:descriptions.new_driver")}
      fields={[
        {
          id: "companyId",
          label: t("company:select_company"),
          grid_style: "col-span-2",
          input: (
            <Select
              id="companyId"
              name="companyId"
              defaultValue={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
            >
              {props.companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Select>
          ),
        },
        {
          id: "firstName",
          label: t("common:firstname"),
          input: (
            <TextInput
              id="firstName"
              name="firstName"
              placeholder={t("common:placeholder.firstname")}
              defaultValue={props.data.firstName ?? ""}
              type="text"
              required={true}
            />
          ),
        },
        {
          id: "lastName",
          label: t("common:lastname"),
          input: (
            <TextInput
              id="lastName"
              name="lastName"
              placeholder={t("common:placeholder.lastname")}
              defaultValue={props.data.lastName ?? ""}
              type="text"
              required={true}
            />
          ),
        },
        {
          id: "email",
          label: t("common:email"),
          input: (
            <TextInput
              id="email"
              name="email"
              placeholder={t("common:placeholder.email")}
              defaultValue={props.data.email ?? ""}
            />
          ),
        },
        {
          id: "phoneNumber",
          label: t("common:phone"),
          input: (
            <TextInput
              id="phoneNumber"
              name="phoneNumber"
              placeholder={t("common:placeholder.phone")}
              defaultValue={props.data.phoneNumber ?? ""}
            />
          ),
        },
        {
          id: "licenses",
          label: t("user:driving_license"),
          grid_style: "col-span-2",
          input: (
            <CustomStyledSelect
              id="licenses"
              defaultValue={props.data.licenses ?? [licenseOptions[0].value]} // Handling default value
              placeholder={t("modals:placeholders.licenses")} // Placeholder for multi-select
              emptyOption={false} // You may want to omit empty option for multi-select
              isMultiple={true} // Multi-select
              options={licenseOptions.map((license) => ({
                key: license.value,
                label: license.label,
              }))}
            />
          ),
        },
        {
          id: "vehicleId",
          label: t("inventory:vehicle"),
          grid_style: "col-span-2",
          input: (
            <CustomStyledSelect
              id="vehicleId"
              defaultValue={
                props.data.vehicleId !== undefined &&
                props.data.vehicleId.length > 1
                  ? props.data.vehicleId
                  : ""
              }
              placeholder={t("modals:placeholders.vehicle")}
              emptyOption
              options={availableVehicles.map((vehicle) => ({
                key: vehicle.id,
                label: vehicle.registrationNumber,
              }))}
            />
          ),
        },
      ]}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      buttonConfirmText={t("modals:button_texts.driver")}
      buttonConfirmPrependIcon={
        isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null
      }
      buttonCloseText={t("common:close")}
    />
  );
};

export const DriverModal: FC<DriverModalProp> = function (
  props: DriverModalProp,
) {
  const { t } = useTranslation(["common", "user", "modals"]);

  return (
    <Dialog
      title={
        Driver.isNew(props.data)
          ? t("user:create_driver")
          : t("user:update_driver")
      }
      content={<DriverForm {...props} />}
      show={props.isShowing}
      toggleModal={props.onCancel}
      hideButtons={true}
    />
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "flowbite-react";
import { useState, type FC } from "react";
import { HiChevronLeft } from "react-icons/hi";
import { ITableCell, ITableRow } from "../../../types";
import { StandardTable } from "../Table";
import { TableSkeleton } from "../../atoms/Skeleton";
import { ButtonSearch } from "../..";
import { useTranslation } from "react-i18next";

export interface SplitTableProps {
  tableRows: ITableRow[];
  tableHeaders: ITableCell[];
  isLoading?: boolean;
  topRightContent?: React.ReactNode;
  content?: React.ReactNode;
  searchable?: boolean;
  updateTable?: (searchText: string) => void;
  onRowClick: (item: any) => void;
}

export const SplitTable: FC<SplitTableProps> = (props) => {
  const { t } = useTranslation(["common"]);
  const [split, setSplit] = useState(false);

  const transition = "lg:transition-all lg:duration-200 lg:ease-in";

  const handleRowClick = (item: any) => {
    if (!split) {
      setSplit(true);
    }
    props.onRowClick(item);
  };

  const closeSplit = () => {
    props.onRowClick(undefined);
    setSplit(false);
  };

  return (
    <div className="flex">
      {/* Main Table Section */}
      <div
        className={`${split ? "w-2/5 overflow-hidden hidden lg:block" : "w-full overflow-x-scroll"} ${transition}`}
      >
        {props.searchable && (
          <ButtonSearch
            placeholder={t("common:search")}
            buttonText={t("common:search")}
            buttonClick={(input) =>
              props.updateTable ? props.updateTable(input) : null
            }
          />
        )}
        {props.isLoading ? (
          <TableSkeleton />
        ) : (
          <StandardTable
            onRowClick={(item) => handleRowClick(item)}
            header={props.tableHeaders}
            rows={props.tableRows}
          />
        )}
      </div>
      <div
        className={`${
          split
            ? "w-full lg:w-3/5 lg:ml-4 lg:border-l lg:dark:border-l-gray-700"
            : "w-0 h-0 overflow-hidden"
        } ${transition}`}
      >
        <div className="lg:mx-4">
          <div className="flex justify-between items-center mb-4">
            <Button onClick={() => closeSplit()} size="sm" color="gray">
              <HiChevronLeft size={18} />
            </Button>
            {props.topRightContent}
          </div>
          {props.content}
        </div>
      </div>
    </div>
  );
};

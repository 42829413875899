/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from "flowbite-react";
import type { FC } from "react";
import { HiAdjustments } from "react-icons/hi";
import { LanguageDropdown } from "../LanguageDropdown";
import { useTranslation } from "react-i18next";

export const BottomMenu: FC = function () {
  const { t } = useTranslation("navigation");

  return (
    <div className="flex items-center justify-center gap-x-5">
      <div>
        <LanguageDropdown />
      </div>
    </div>
  );
};

import type { CustomFlowbiteTheme } from "flowbite-react";

export const PrimaryTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      primary:
        "bg-lgb-primary border border-transparent focus:ring-4 hover:bg-lgb-primary-lighten text-white",
      primary700: "bg-lgb-primary-700 hover:bg-lgb-primary-400 text-white",
      red300: "bg-lgb-red-300 hover:bg-lgb-red-200 text-white",
      mainGreen: "bg-lgb-main-green hover:bg-lgb-main-green-lighten text-white",
      green400: "lgb-green-400",
    },
  },
  textInput: {
    field: {
      input: {
        colors: {
          primary: "text-red",
          gray: "border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-700",
        },
      },
    },
  },
  label: {
    root: {
      colors: {
        default: "text-gray-900 dark:text-white",
      },
    },
  },
  select: {
    field: {
      select: {
        colors: {
          gray: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 dark:border-gray-700 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 rounded-lg p-2.5 text-sm",
        },
      },
    },
  },
  sidebar: {
    root: {
      inner:
        "h-full overflow-y-auto overflow-x-hidden bg-gray-50 py-4 px-3 dark:bg-darkBlue",
    },
  },
  modal: {
    header: {
      base: "flex items-start justify-between rounded-t dark:border-gray-600",
    },
    body: {
      base: "flex-1 overflow-auto px-6 pb-6 pt-2",
    },
  },
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState } from "react";
import { TextInput, Textarea, Select } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { StandardForm, Dialog } from "../..";
import { IParameter, ParameterType } from "../../../types";
import { useTranslation } from "react-i18next";
import { Parameter } from "../../../models";

export interface ParameterModalProp {
  data: IParameter;
  isShowing: boolean;
  onConfirm: (data: IParameter) => void;
  onCancel: () => void;
}

export const ParameterForm: FC<ParameterModalProp> = function (
  props: ParameterModalProp,
) {
  const { t } = useTranslation(["common", "configuration", "modals"]);

  const parameterOptions = [
    {
      value: ParameterType.VehicleType,
      label: t("configuration:parameter_types.vehicle"),
    },
    {
      value: ParameterType.ProductType,
      label: t("configuration:parameter_types.product"),
    },
  ];

  const [isNew] = useState(Parameter.isNew(props.data));

  return (
    <StandardForm
      description={t("modals:descriptions.resource_types")}
      showHeader={false}
      fields={[
        {
          id: "type",
          label: t("configuration:fields.type"),
          input: (
            <Select
              id="type"
              key="type"
              name="type"
              defaultValue={props.data.type}
              required={true}
            >
              {parameterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          ),
        },
        {
          id: "code",
          label: t("configuration:fields.code"),
          input: (
            <TextInput
              id="code"
              key="code"
              name="code"
              placeholder={t("configuration:parameter_example")}
              defaultValue={props.data.code}
              type="text"
              required={true}
            />
          ),
        },
        {
          id: "description",
          label: t("configuration:fields.description_title"),
          grid_style: "col-span-2",
          input: (
            <Textarea
              id="description"
              key="description"
              name="description"
              className="text-sm"
              placeholder={t("configuration:fields.description")}
              defaultValue={props.data.description ?? ""}
            />
          ),
        },
      ]}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      buttonConfirmText={t("modals:button_texts.resource_type")}
      buttonConfirmPrependIcon={
        isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null
      }
      buttonCloseText={t("common:close")}
    />
  );
};

export const ParameterModal: FC<ParameterModalProp> = function (
  props: ParameterModalProp,
) {
  const { t } = useTranslation(["common", "configuration"]);

  return (
    <Dialog
      title={
        Parameter.isNew(props.data)
          ? t("configuration:create_parameter")
          : t("configuration:edit_parameter")
      }
      content={<ParameterForm {...props} />}
      show={props.isShowing}
      toggleModal={props.onCancel}
      hideButtons={true}
    />
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, type FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownSearch,
  DropdownSearchRadioItem,
} from "../../../../components";
import { Driver } from "../../../../models";
import { useWorkspace } from "../../../../context/WorkspaceContext";
import { useDrivers } from "../../../../hooks";
import { TableSkeletonsByAmount } from "../../../../components/atoms/Skeleton/TableSkeleton";
import { IOrder } from "../../../../types";

export interface DriverSelectionProps {
  order: IOrder;
  selectedDriver?: string;
  comment: string;
  validationFailed: boolean;
  update: (driverId: string) => void;
  updateComment: (comment: string) => void;
}

const DriverSelection: FC<DriverSelectionProps> = (props) => {
  const { t } = useTranslation("orders");
  const [current, setCurrent] = useState(props.selectedDriver);
  const { activeWorkspace } = useWorkspace();
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState([] as Driver[]);

  const query = useDrivers(activeWorkspace?.workspaceId ?? "");
  const drivers = query.data;

  // Log drivers to check if companyId is present
  useEffect(() => {
    if (drivers) {
      console.log("Fetched drivers:", drivers);
    }
  }, [drivers]);

  useEffect(() => {
    if (searchText.length) {
      setSearchResults(
        drivers?.filter((x) =>
          (x.firstName + x.lastName)
            ?.toLowerCase()
            .includes(searchText.toLowerCase()),
        ) || [],
      );
    } else {
      setSearchResults(drivers || []);
    }
  }, [searchText, drivers]);

  useEffect(() => {
    setCurrent(props.selectedDriver);
  }, [props.selectedDriver]);

  /**
   * Handle search logic
   * @param val search string
   */
  function handleSearch(val: string) {
    setSearchText(val);
  }

  const selectDriver = (val: Driver) => {
    // Check if the selected driver has a valid companyId
    if (!val.companyId) {
      console.error("Selected driver has no companyId. Cannot proceed.");
      alert(t("create.driver_selection.missing_companyId")); // Show user feedback
      return;
    }
    props.update(val.email);
    setCurrent(val.email);
  };

  return (
    <div>
      <DropdownSearch
        placeholder={t("create.driver_selection.search_placeholder")}
        inputChanged={(val) => handleSearch(val)}
        showResults
        plain
      >
        <div>
          {query.isLoading && <TableSkeletonsByAmount quantity={6} />}
          {!query.isLoading &&
            searchResults.map((driver: Driver) => {
              return (
                <DropdownSearchRadioItem
                  key={driver.id}
                  selected={current === driver.email}
                  buttonClickEvent={() => selectDriver(driver)}
                  id={driver.id}
                  title={driver.firstName + " " + driver.lastName}
                ></DropdownSearchRadioItem>
              );
            })}
        </div>
      </DropdownSearch>
    </div>
  );
};

export default DriverSelection;

/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";

export interface StepperProp {
  stepClickEvent?: (step: number) => void;
  currentStep: number;
  steps: Step[];
}

export interface Step {
  number: number;
  icon?: React.ReactNode;
  description: string;
  disabled?: boolean;
}

/**
 * TODO:
 *   - Step transition animation
 *   - Hover effects
 *   - Improve handling of icons
 */

export const Stepper: FC<StepperProp> = (stepsProp) => {
  let itemList = stepsProp.steps.map((item, index) => {
    /* Check if this is the final step. Used to apply needed classes */
    const isFinalStep = index === stepsProp.steps.length - 1;
    const isFirstStep = index === 0;
    const isCompleted = index <= stepsProp.currentStep;
    const isDisabled = item.disabled || false;
    const fadeAnimation =
      " before:animate-in before:fade-in before:fade-out before:duration-300 after:animate-in after:fade-in after:fade-out after:duration-300 ";
    const defaultStepStyle =
      "hover:cursor-pointer rounded-full font-bold relative animate-in fade-in fade-out duration-300" +
      (isCompleted
        ? " bg-lgb-primary text-white border-2 border-lgb-primary"
        : isDisabled
          ? " text-gray-600 dark:text-white border-2 border-gray-600/50 dark:border-gray-400 opacity-40"
          : " text-gray-600 dark:text-white border-2 border-gray-600/50 dark:border-gray-400");
    const iconStepStyle =
      "hover:cursor-pointer font-bold relative px-1" +
      (isCompleted ? " text-lgb-primary" : " text-gray-600 dark:text-white");
    const descriptionStyle =
      "hover:cursor-pointer pt-2 w-24 text-sm leading-4 text-center absolute left-1/2 transform -translate-x-1/2" +
      (isCompleted
        ? " text-darkBlue dark:text-white font-medium"
        : " font-normal");

    const checkIcon = (
      <svg
        className="w-[24px] h-[24px]"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m7 10 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    );

    return (
      <div
        key={index}
        className={
          isFirstStep || isFinalStep
            ? "w-1/3 sm:w-1/3 md:w-1/3"
            : "w-2/3 sm:w-2/3 md:w-2/3"
        }
      >
        {/* First step. Line only after the step marker */}
        {isFirstStep && (
          <li
            className={
              "flex items-center after:content-[''] after:w-full after:border-b-0 after:border-t-2 after:inline-block h-9" +
              fadeAnimation +
              (isCompleted
                ? " h-9 after:border-lgb-primary dark:after:border-lgb-primary"
                : " after:border-gray-500/25 dark:after:border-gray-200/50")
            }
          >
            <span
              className={item.icon ? iconStepStyle : defaultStepStyle}
              onClick={() =>
                stepsProp.stepClickEvent
                  ? stepsProp.stepClickEvent(item.number - 1)
                  : null
              }
            >
              <span className="flex items-center justify-center w-6 h-6 ">
                {isCompleted && item.icon
                  ? checkIcon
                  : item.icon
                    ? item.icon
                    : item.number}
              </span>
              <p className={descriptionStyle}>{item.description}</p>
            </span>
          </li>
        )}
        {/* All steps inbetween first and last. Line on both sides of the step marker */}
        {!(isFirstStep || isFinalStep) && (
          <li
            className={
              "flex items-center w-full after:content-[''] after:w-full after:border-b-0 after:border-t-2 h-9 after:inline-block before:content-[''] before:w-full before:border-b-0 before:border-t-2 before:inline-block " +
              fadeAnimation +
              (isCompleted
                ? " dark:before:border-lgb-primary before:border-lgb-primary dark:after:border-lgb-primary after:border-lgb-primary"
                : " dark:before:border-gray-200/50 before:border-gray-500/25 dark:after:border-gray-200/50 after:border-gray-500/25")
            }
          >
            <span
              className={item.icon ? iconStepStyle : defaultStepStyle}
              onClick={() =>
                stepsProp.stepClickEvent
                  ? stepsProp.stepClickEvent(item.number - 1)
                  : null
              }
            >
              <span className="flex items-center justify-center w-6 h-6">
                {isCompleted && item.icon
                  ? checkIcon
                  : item.icon
                    ? item.icon
                    : item.number}
              </span>
              <p className={descriptionStyle}>{item.description}</p>
            </span>
          </li>
        )}
        {/* The final step. Line is only before the step marker */}
        {isFinalStep && (
          <li
            className={
              "flex items-center before:content-[''] before:w-full before:border-b-0 before:border-t-2 before:inline-block h-9" +
              fadeAnimation +
              (isCompleted
                ? " h-9 before:border-lgb-primary dark:before:border-lgb-primary"
                : " before:border-gray-500/25 dark:before:border-gray-200/50")
            }
          >
            <span
              className={item.icon ? iconStepStyle : defaultStepStyle}
              onClick={() =>
                stepsProp.stepClickEvent
                  ? stepsProp.stepClickEvent(item.number - 1)
                  : null
              }
            >
              <span className="flex items-center justify-center w-6 h-6">
                {isCompleted && item.icon
                  ? checkIcon
                  : item.icon
                    ? item.icon
                    : item.number}
              </span>
              <p className={descriptionStyle}>{item.description}</p>
            </span>
          </li>
        )}
      </div>
    );
  });

  return (
    <div>
      <div className="flex lg:px-4 pb-10">{itemList}</div>
    </div>
  );
};

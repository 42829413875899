import { FC } from "react";
import { Card } from "../../atoms/Card";
import { StatusBadge } from "../../atoms/Badges";
import { HiOutlinePencil, HiCamera } from "react-icons/hi";
import { IRouteItem } from "../../../types";
import { useTranslation } from "react-i18next";

interface RouteStopCardProps {
  className?: string;
  routeStop: IRouteItem;
  stopType: "Delivery" | "Pickup" | "DeliveryAndPickup";
  hasImages: boolean;
  hasSignature: boolean;
}

const RouteStopCard: FC<RouteStopCardProps> = (props) => {
  const { t } = useTranslation("orders");
  return (
    <div className={props.className}>
      <Card
        containerClass="w-full h-full"
        type="dashed"
        bgColorClass="dark:bg-lgb-on-dark-background border-1"
        borderClass="!py-2 !px-4 min-[1170px]:!p-4 border-lgb-grey-100 dark:border-lgb-grey-600"
        children={
          <div className="flex justify-between flex-row-reverse min-[1170px]:flex-col gap-2 dark:text-lgb-grey-100">
            <div className="flex flex-row-reverse min-[1170px]:flex min-[1170px]:flex-row gap-4 justify-between items-center">
              {props.stopType === "DeliveryAndPickup" ? (
                <StatusBadge
                  status={t("orders:pickup_and_delivery")}
                  bgStyle="bg-lgb-yellow-100 dark:bg-lgb-yellow-200 text-lgb-yellow-400 dark:text-lgb-yellow-400"
                  rounded
                />
              ) : (
                <StatusBadge
                  status={
                    props.stopType === "Pickup"
                      ? t("orders:pickup")
                      : t("orders:delivery")
                  }
                  bgStyle={
                    props.stopType === "Pickup"
                      ? "bg-lgb-blue-100 dark:bg-lgb-blue-200 text-lgb-blue-400 dark:text-lgb-blue-400"
                      : props.stopType === "Delivery"
                        ? "bg-lgb-green-100 dark:bg-lgb-green-200 text-lgb-green-400 dark:text-lgb-green-400"
                        : "bg-lgb-grey-100 text-lgb-grey-600"
                  }
                  rounded
                />
              )}
              <p className="text-lgb-grey-400 text-nowrap">
                {props.routeStop.timing?.earliest
                  ? t("common:earliest")
                  : props.routeStop.timing?.latest
                    ? t("common:latest")
                    : props.routeStop.timing?.before
                      ? t("common:before") +
                        " " +
                        props.routeStop.timing?.before
                      : props.routeStop.timing?.after
                        ? t("common:after") +
                          " " +
                          props.routeStop.timing?.after
                        : ""}
              </p>
              <div className="block min-[1170px]:hidden flex gap-2 mt-1">
                {props.hasSignature && (
                  <HiOutlinePencil size={14} className="text-lgb-yellow-400" />
                )}
                {props.hasImages && (
                  <HiCamera size={14} className="text-lgb-pink-400" />
                )}
              </div>
            </div>
            <div>
              <p className="font-bold">
                {props.routeStop.location.addressLine}
              </p>
              <p className="text-lgb-grey-400">
                {props.routeStop.location.displayName}
              </p>
              {/* Visible on bigger screens */}
              <div className="hidden min-[1170px]:block min-[1170px]:flex min-[1170px]:gap-2 mt-1">
                {props.hasSignature && (
                  <HiOutlinePencil size={14} className="text-lgb-yellow-400" />
                )}
                {props.hasImages && (
                  <HiCamera size={14} className="text-lgb-pink-400" />
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default RouteStopCard;

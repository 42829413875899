import { FC, ReactNode } from "react";
import { BeatLoader } from "react-spinners";

interface KPICardProps {
  icon: ReactNode; // Accepts any React node
  amount: string | number;
  status: string;
  backgroundColor: string;
  url: string;
  isLoading: boolean;
}

const KPICard: FC<KPICardProps> = function ({
  icon,
  amount,
  status,
  backgroundColor,
  url,
  isLoading,
}) {
  return (
    <a href={url}>
      <div className="bg-white shadow-md rounded-lg p-4 dark:bg-darkBlue  h-full hover:bg-gray-100 dark:hover:bg-lgb-grey-600 cursor-pointer border dark:border-lgb-grey-600">
        <div className="flex items-center h-full flex-wrap">
          <div
            className="mr-4"
            style={{
              backgroundColor: backgroundColor,
              padding: "8px",
              borderRadius: "8px",
            }}
          >
            {icon}
          </div>{" "}
          {/* Display the icon */}
          {isLoading ? (
            // Render spinner while loading
            <BeatLoader color="#7214FF" />
          ) : (
            <div className="flex flex-row items-center justify-between gap-3 flex-wrap">
              <p className="font-bold text-xl dark:text-white">{amount}</p>
              <p className="text-primaryDark dark:text-white text-sm">
                {status}
              </p>
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

export default KPICard;

import type { FC } from "react";
interface TableSkeletonsByAmountProps {
  quantity: number;
  avatar?: boolean;
}
interface TableRowSkeletonProps {
  avatar?: boolean;
}

const TableRowSkeleton: FC<TableRowSkeletonProps> = (props) => {
  return (
    <div className="flex items-center justify-between pt-4">
      <div className="flex items-center justify-between">
        {props.avatar && (
          <svg
            className="w-8 h-8 text-gray-200 dark:text-gray-700 me-4"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
        )}
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </div>
      <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
  );
};

export const TableSkeleton: FC = function () {
  return (
    <div
      role="status"
      className="min-w-full max-w-md space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
    >
      <div className="flex items-center justify-between">
        <div className="h-2.5 bg-gray-100 dark:bg-gray-700 w-12"></div>
      </div>
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
    </div>
  );
};

export const TableSkeletonsByAmount: FC<TableSkeletonsByAmountProps> = (
  props,
) => {
  return (
    <div className="min-w-full max-w-md space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
      {[...Array(props.quantity)].map((e, i) => (
        <TableRowSkeleton key={i} avatar={props.avatar} />
      ))}
    </div>
  );
};

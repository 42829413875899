import type { FC } from "react";

export const UnitedKingdom: FC = function () {
  return (
    <svg
      className="mr-2 h-4 w-4 rounded-full"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g id="Group_5" data-name="Group 5" transform="translate(-235)">
        <g id="united-kingdom" transform="translate(235)">
          <circle
            id="Ellipse_2"
            data-name="Ellipse 2"
            cx="10"
            cy="10"
            r="10"
            fill="#f0f0f0"
          />
          <g id="Group_2" data-name="Group 2" transform="translate(0.31 0.31)">
            <path
              id="Path_6"
              data-name="Path 6"
              d="M10.369,100.142a8.963,8.963,0,0,0-1.55,3.13H13.5Z"
              transform="translate(-8.819 -96.482)"
              fill="#0052b4"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M374.685,103.273a8.964,8.964,0,0,0-1.55-3.13l-3.13,3.13Z"
              transform="translate(-355.312 -96.483)"
              fill="#0052b4"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M8.819,322.784a8.964,8.964,0,0,0,1.55,3.13l3.13-3.13Z"
              transform="translate(-8.819 -310.202)"
              fill="#0052b4"
            />
            <path
              id="Path_9"
              data-name="Path 9"
              d="M325.912,10.369a8.964,8.964,0,0,0-3.13-1.55V13.5Z"
              transform="translate(-310.2 -8.819)"
              fill="#0052b4"
            />
            <path
              id="Path_10"
              data-name="Path 10"
              d="M100.142,373.135a8.964,8.964,0,0,0,3.13,1.55v-4.68Z"
              transform="translate(-96.482 -355.312)"
              fill="#0052b4"
            />
            <path
              id="Path_11"
              data-name="Path 11"
              d="M103.272,8.819a8.964,8.964,0,0,0-3.13,1.55l3.13,3.13Z"
              transform="translate(-96.482 -8.819)"
              fill="#0052b4"
            />
            <path
              id="Path_12"
              data-name="Path 12"
              d="M322.783,374.685a8.964,8.964,0,0,0,3.13-1.55l-3.13-3.13Z"
              transform="translate(-310.201 -355.312)"
              fill="#0052b4"
            />
            <path
              id="Path_13"
              data-name="Path 13"
              d="M370.005,322.784l3.13,3.13a8.963,8.963,0,0,0,1.55-3.13Z"
              transform="translate(-355.312 -310.202)"
              fill="#0052b4"
            />
          </g>
          <g id="Group_3" data-name="Group 3">
            <path
              id="Path_14"
              data-name="Path 14"
              d="M19.908,8.692H11.3V.085a10.088,10.088,0,0,0-2.608,0V8.692H.085a10.088,10.088,0,0,0,0,2.608H8.692v8.608a10.088,10.088,0,0,0,2.608,0V11.3h8.608a10.088,10.088,0,0,0,0-2.608Z"
              fill="#d80027"
            />
            <path
              id="Path_15"
              data-name="Path 15"
              d="M322.783,322.783h0L326.8,326.8q.277-.277.529-.578l-3.437-3.437h-1.106Z"
              transform="translate(-309.44 -309.44)"
              fill="#d80027"
            />
            <path
              id="Path_16"
              data-name="Path 16"
              d="M78.994,322.784h0L74.98,326.8q.277.277.578.529l3.437-3.437Z"
              transform="translate(-72.345 -309.441)"
              fill="#d80027"
            />
            <path
              id="Path_17"
              data-name="Path 17"
              d="M64.476,78.995h0L60.462,74.98q-.277.277-.529.578l3.437,3.437h1.106Z"
              transform="translate(-57.827 -72.345)"
              fill="#d80027"
            />
            <path
              id="Path_18"
              data-name="Path 18"
              d="M322.783,64.477h0l4.014-4.014q-.277-.277-.578-.529l-3.437,3.437Z"
              transform="translate(-309.44 -57.828)"
              fill="#d80027"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Norway: FC = function () {
  return (
    <svg
      className="mr-2 h-4 w-4 rounded-full"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g id="Group_4" data-name="Group 4">
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="9.98"
          cy="9.98"
          r="9.98"
          fill="#f0f0f0"
        />
        <g id="Group_1" data-name="Group 1" transform="translate(0.317)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M8.819,322.783a9.209,9.209,0,0,0,3.284,4.9v-4.9Z"
            transform="translate(-8.819 -309.584)"
            fill="#d80027"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M233.739,329.549c.264.023.531.035.8.035a9.205,9.205,0,0,0,8.885-6.8h-9.685Z"
            transform="translate(-224.058 -309.584)"
            fill="#d80027"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M243.424,6.8A9.205,9.205,0,0,0,234.539,0c-.27,0-.536.012-.8.035V6.8Z"
            transform="translate(-224.058)"
            fill="#d80027"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M12.1,52.9a9.21,9.21,0,0,0-3.284,4.9H12.1Z"
            transform="translate(-8.819 -50.994)"
            fill="#d80027"
          />
        </g>
        <path
          id="Path_5"
          data-name="Path 5"
          d="M19.915,14.559H7.826V6.085a9.92,9.92,0,0,0-2.609.98v7.494H.085a10.131,10.131,0,0,0,0,2.614H5.217v7.494a9.92,9.92,0,0,0,2.609.98V17.173H19.915a10.131,10.131,0,0,0,0-2.614Z"
          transform="translate(0 -5.866)"
          fill="#0052b4"
        />
      </g>
    </svg>
  );
};

export const Germany: FC = function () {
  return (
    <svg
      className="mr-2 h-4 w-4 rounded-full"
      xmlns="http://www.w3.org/2000/svg"
      id="flag-icon-css-de"
      viewBox="0 0 512 512"
    >
      <path fill="#ffce00" d="M0 341.3h512V512H0z" />
      <path d="M0 0h512v170.7H0z" />
      <path fill="#d00" d="M0 170.7h512v170.6H0z" />
    </svg>
  );
};

export const Italy: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12.5739C0 12.7979 0.130859 13.0013 0.3351 13.094L6.54053 16.0024L6.5707 8.18499L0 5.15234V12.5739Z"
        fill="#FE2F67"
      />
      <path
        d="M10.3085 6.72266L7.14359 8.18338L7.11328 16.0002L13.3806 13.0917C13.5849 12.9991 13.7157 12.7957 13.7157 12.5716V7.26032C13.3532 7.36865 12.9701 7.42878 12.5729 7.42878C11.7326 7.42878 10.9529 7.16706 10.3085 6.72266Z"
        fill="#FE2F67"
      />
      <path
        d="M8.56892 3.42927C8.56892 3.10557 8.61178 2.79238 8.68471 2.49076L7.09096 1.76632C6.94085 1.69768 6.76842 1.69768 6.6183 1.76632C6.6183 1.76632 0.301549 4.64439 0.285156 4.65388L6.85463 7.68597L9.81261 6.32073C9.0482 5.59176 8.56892 4.56644 8.56892 3.42927Z"
        fill="#FE2F67"
      />
      <path
        d="M12.5731 0C10.6795 0 9.14453 1.53502 9.14453 3.42857C9.14453 5.32213 10.6795 6.85714 12.5731 6.85714C14.4667 6.85714 16.0017 5.32213 16.0017 3.42857C16.0017 1.53502 14.4667 0 12.5731 0ZM13.9813 2.10603L12.8384 4.96317C12.8027 5.05246 12.7246 5.11775 12.6306 5.137C12.6113 5.1409 12.5921 5.14286 12.5731 5.14286C12.498 5.14286 12.4252 5.11328 12.3711 5.05915L11.2282 3.91629C11.1166 3.80469 11.1166 3.62388 11.2282 3.51228C11.3398 3.40067 11.5206 3.40067 11.6323 3.51228L12.4688 4.34877L13.4506 1.89397C13.5092 1.74693 13.6766 1.67606 13.822 1.73465C13.9685 1.79325 14.0399 1.95954 13.9813 2.10603Z"
        fill="#FE2F67"
      />
    </svg>
  );
};

export const China: FC = function () {
  return (
    <svg
      className="mr-2 h-4 w-4 rounded-full"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id="flag-icon-css-cn"
      viewBox="0 0 512 512"
    >
      <defs>
        <path id="a" fill="#ffde00" d="M1-.3L-.7.8 0-1 .6.8-1-.3z" />
      </defs>
      <path fill="#de2910" d="M0 0h512v512H0z" />
      <use
        width="30"
        height="20"
        transform="matrix(76.8 0 0 76.8 128 128)"
        xlinkHref="#a"
      />
      <use
        width="30"
        height="20"
        transform="rotate(-121 142.6 -47) scale(25.5827)"
        xlinkHref="#a"
      />
      <use
        width="30"
        height="20"
        transform="rotate(-98.1 198 -82) scale(25.6)"
        xlinkHref="#a"
      />
      <use
        width="30"
        height="20"
        transform="rotate(-74 272.4 -114) scale(25.6137)"
        xlinkHref="#a"
      />
      <use
        width="30"
        height="20"
        transform="matrix(16 -19.968 19.968 16 256 230.4)"
        xlinkHref="#a"
      />
    </svg>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import { Button } from "flowbite-react";
import { HiOutlinePlus, HiTrash, HiPencilAlt } from "react-icons/hi";
import {
  PageLayout,
  TableSkeleton,
  StandardTable,
  ProductModal,
  DialogForm,
} from "../../components";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { useProducts, useParameters } from "../../hooks";
import { useTranslation } from "react-i18next";
import { ParameterType, ITableRow, IProduct } from "../../types";
import { Parameter, Product } from "../../models";

export const ProductsPage: FC = function () {
  const [isUpsertModalOpen, openUpsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const { activeWorkspace } = useWorkspace();
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [productData, setProductData] = useState(Product.default());
  const [productCategories, setProductCategories] = useState([] as Parameter[]);
  const { updateCacheKey } = useCache();
  const categoriesQuery = useParameters(
    activeWorkspace?.workspaceId ?? "",
    ParameterType.ProductType,
  );
  const query = useProducts(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "inventory"]);

  const isQueryLoading = useMemo(
    () =>
      query.isLoading ||
      query.isRefetching ||
      categoriesQuery.isLoading ||
      categoriesQuery.isRefetching,
    [query, categoriesQuery],
  );

  const products = useMemo(() => query.data ?? [], [query]);

  const categories = useMemo(
    () => categoriesQuery.data ?? [],
    [categoriesQuery],
  );

  useEffect(() => {
    if (!isQueryLoading) {
      setProductCategories(categories);
    }
  }, [isQueryLoading, categories]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        products.map((product) => {
          return {
            id: product.id,
            cells: [
              {
                id: "descriptionShort",
                children: product.descriptionShort,
                showOnSmallScreen: true,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setProductData(product);
                  openUpsertModal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setProductData(product);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, products]);

  return (
    <PageLayout>
      <div className="flex flex-col">
        <div className="flex flex-col sm:flex-row justify-between items-start p-4">
          <div>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              {t("inventory:product_title")}
            </h1>
            <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
              {t("inventory:productpage_description")}
            </p>
          </div>
          <Button
            color="light"
            onClick={() => {
              setProductData(Product.default());
              openUpsertModal(true);
            }}
            type="submit"
            className="mt-2 sm:mt-0 mb-4 sm:mb-0" // Adjusted top and bottom margins
          >
            <HiOutlinePlus className="mr-2 h-5 w-5" />
            {t("inventory:new_product_button")}
          </Button>
        </div>

        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              {isLoading ? (
                <TableSkeleton />
              ) : (
                <StandardTable
                  header={[
                    {
                      id: "descriptionShort",
                      children: t("inventory:product_description"),
                      showOnSmallScreen: true,
                    },
                  ]}
                  hasActions={true}
                  rows={tableRows}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <ProductModal
        data={productData}
        productTypes={productCategories}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: IProduct) => {
          if (Product.isNew(productData))
            Product.create(activeWorkspace?.workspaceId ?? "", formData);
          else Product.update(productData, formData);
          openUpsertModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        onCancel={() => openUpsertModal(false)}
      />

      <DialogForm
        title={
          t("inventory:delete_product") +
          " " +
          productData.descriptionShort +
          "?"
        }
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        buttonConfirmColor="failure"
        confirmButton={() => {
          Product.delete(productData);
          openDeleteModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />
    </PageLayout>
  );
};

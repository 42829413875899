import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { Product } from "../models";
import { useCache } from "../context/CacheContext";

const useProducts = (workspaceId: string) => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData(
    [Product.collectionName, cacheKey, workspaceId],
    Product.list(workspaceId),
    {
      subscribe: false,
    },
  );
};

export default useProducts;

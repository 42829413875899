import React, { useState, useEffect } from "react";
import Coupon from "../components/molecules/Cards/Coupon";
import { PageLayout } from "../components";
import { TextInput, Select } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { ICouponData } from "../types";

const CouponPage: React.FC = () => {
  const [coupons, setCoupons] = useState<ICouponData[]>([]);
  const [filteredCoupons, setFilteredCoupons] = useState<ICouponData[]>([]);
  const [selectedCounty, setSelectedCounty] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [availableCounties, setAvailableCounties] = useState<string[]>([]);
  const [error, setError] = useState<string>("");
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    fetch("/coupons.json")
      .then((response) => response.json())
      .then((data) => {
        const parsedData: ICouponData[] = data.map((coupon: ICouponData) => ({
          ...coupon,
          expireDate: new Date(coupon.expireDate),
        }));
        setCoupons(parsedData);
        setFilteredCoupons(parsedData);

        const counties: string[] = Array.from(
          new Set(
            parsedData
              .map((coupon: ICouponData) => coupon.address.physical?.region)
              .filter((county): county is string => typeof county === "string"),
          ),
        );
        setAvailableCounties(counties);
      })
      .catch((error) => console.error("Error fetching coupons:", error));
  }, []);

  useEffect(() => {
    filterCoupons(selectedCounty, searchTerm);
  }, [selectedCounty, searchTerm]);

  const filterCoupons = (county: string, search: string) => {
    const filtered = coupons.filter((coupon) => {
      const countyMatch =
        !county ||
        (coupon.address.physical &&
          coupon.address.physical.region &&
          coupon.address.physical.region
            .toLowerCase()
            .includes(county.toLowerCase()));
      const searchMatch =
        !search ||
        coupon.title.toLowerCase().includes(search.toLowerCase()) ||
        coupon.description.toLowerCase().includes(search.toLowerCase()) ||
        (coupon.address.physical &&
          coupon.address.physical.address &&
          coupon.address.physical.address
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (coupon.address.physical &&
          coupon.address.physical.city &&
          coupon.address.physical.city
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (coupon.address.physical &&
          coupon.address.physical.zip &&
          coupon.address.physical.zip
            .toLowerCase()
            .includes(search.toLowerCase()));

      return countyMatch && searchMatch;
    });
    setFilteredCoupons(filtered);

    if (county && filtered.length === 0) {
      setError("No coupons available for this county.");
    } else {
      setError("");
    }
  };

  const handleCountySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCounty(e.target.value);
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <PageLayout>
      <div className="container mx-auto">
        <div className="my-4 flex justify-between">
          <TextInput
            type="text"
            placeholder={t("coupons.coupon_search_placeholder")}
            className=" mr-2 w-full"
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          <Select value={selectedCounty} onChange={handleCountySelect}>
            <option value="">{t("coupons.coupon_filter_placeholder")}</option>
            {availableCounties.map((county) => (
              <option key={county} value={county}>
                {county}
              </option>
            ))}
          </Select>
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 mt-6">
          {filteredCoupons.map((coupon) => (
            <Coupon
              key={coupon.id}
              id={coupon.id}
              image={coupon.image}
              label={coupon.label}
              title={coupon.title}
              description={coupon.description}
              expireDate={coupon.expireDate}
              address={coupon.address}
              ctaText="Open deal"
              howToUse={coupon.howToUse}
            />
          ))}
        </div>
      </div>
    </PageLayout>
  );
};

export default CouponPage;

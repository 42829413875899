import { FC } from "react";
import KPICard from "../../molecules/Cards/KpiCard";
import {
  UnderProgress,
  CompletedOrders,
  WaitingOrders,
} from "../../atoms/Icons/Icons";
import { useTranslation } from "react-i18next";
import { Order } from "../../../models";
import { OrderStatus } from "../../../types/order/IOrder";

export interface KpiStatusesProp {
  orders: Order[];
  isLoading: boolean;
}

export const KpiStatuses: FC<KpiStatusesProp> = (props) => {
  const { t } = useTranslation("stats");
  return (
    <div className=" h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
      <KPICard
        icon={<UnderProgress />}
        isLoading={props.isLoading}
        amount={props.orders.length}
        status={t("orders.all")}
        backgroundColor="#F1E8FF"
        url={"orders/all"}
      />
      <KPICard
        icon={<WaitingOrders />}
        isLoading={props.isLoading}
        amount={
          props.orders.filter((x) =>
            [
              OrderStatus.Draft,
              OrderStatus.New,
              OrderStatus.Scheduled,
            ].includes(x.status),
          ).length
        }
        status={t("orders.scheduled")}
        backgroundColor="#FFF9EA"
        url={"orders/scheduled"}
      />
      <KPICard
        icon={<UnderProgress />}
        isLoading={props.isLoading}
        amount={
          props.orders.filter((x) => x.status === OrderStatus.InProgress).length
        }
        status={t("orders.under_progress")}
        backgroundColor="#F1E8FF"
        url={"orders/ongoing"}
      />

      <KPICard
        icon={<CompletedOrders />}
        isLoading={props.isLoading}
        amount={
          props.orders.filter((x) => x.status === OrderStatus.Completed).length
        }
        status={t("orders.completed")}
        backgroundColor="#E7F7F3"
        url={"orders/completed"}
      />
    </div>
  );
};

export default KpiStatuses;

/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import { Button } from "flowbite-react";
import { HiOutlinePlus, HiTrash, HiPencilAlt } from "react-icons/hi";
import {
  PageLayout,
  DialogForm,
  TableSkeleton,
  StandardTable,
  VehicleModal,
} from "../../components";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { useVehicles, useParameters, useCompanies } from "../../hooks";
import { useTranslation } from "react-i18next";
import { ITableRow, IVehicle, ParameterType } from "../../types";
import { Company, Vehicle, Parameter } from "../../models";

export const VehiclesPage: FC = function () {
  const [isUpsertModalOpen, openUpsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [vehicleData, setVehicleData] = useState(Vehicle.default());
  const [vehicleCategories, setVehicleCategories] = useState([] as Parameter[]);
  const [workspaceCompanies, setWorkspaceCompanies] = useState([] as Company[]);
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const query = useVehicles(activeWorkspace?.workspaceId ?? "");
  const categoriesQuery = useParameters(
    activeWorkspace?.workspaceId ?? "",
    ParameterType.VehicleType,
  );
  const companiesQuery = useCompanies(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "inventory"]);

  const isQueryLoading = useMemo(
    () =>
      query.isLoading ||
      query.isRefetching ||
      categoriesQuery.isLoading ||
      categoriesQuery.isRefetching ||
      companiesQuery.isLoading ||
      companiesQuery.isRefetching,
    [query, categoriesQuery, companiesQuery],
  );

  const vehicles = useMemo(() => query.data ?? [], [query]);

  const categories = useMemo(
    () => categoriesQuery.data ?? [],
    [categoriesQuery],
  );

  const companies = useMemo(() => companiesQuery.data ?? [], [companiesQuery]);

  useEffect(() => {
    if (!isQueryLoading) {
      setVehicleCategories(categories);
    }
  }, [isQueryLoading, categories]);

  useEffect(() => {
    if (!isQueryLoading) {
      setWorkspaceCompanies(companies);
    }
  }, [isQueryLoading, companies]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        vehicles.map((vehicle) => {
          return {
            id: vehicle.id,
            cells: [
              {
                id: "categoryCode",
                children: vehicle.categoryCode,
                showOnSmallScreen: true,
              },
              {
                id: "registrationNumber",
                children: vehicle.registrationNumber,
                showOnSmallScreen: true,
              },
              {
                id: "name",
                children: vehicle.name,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setVehicleData(vehicle);
                  openUpsertModal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setVehicleData(vehicle);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, vehicles]);

  return (
    <PageLayout>
      <div className="relative pb-20">
        <div className="flex flex-col sm:flex-row justify-between items-start p-4">
          <div>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              {t("inventory:vehicles_title")}
            </h1>
            <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
              {t("inventory:vehiclepage_description")}
            </p>
          </div>
          <Button
            color="light"
            onClick={() => {
              setVehicleData(Vehicle.default());
              openUpsertModal(true);
            }}
            type="submit"
            className="mt-2 sm:mt-0 mb-4 sm:mb-0" // Adjusted top and bottom margins
          >
            <HiOutlinePlus className="mr-2 h-5 w-5" />
            {t("inventory:new_vehicle_button")}
          </Button>
        </div>

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow">
                {isLoading ? (
                  <TableSkeleton />
                ) : (
                  <StandardTable
                    header={[
                      {
                        id: "categoryCode",
                        children: t("inventory:vehicle_type"),
                        showOnSmallScreen: true,
                      },
                      {
                        id: "registrationNumber",
                        children: t("inventory:registration_number"),
                        showOnSmallScreen: true,
                      },
                      {
                        id: "name",
                        children: t("common:name"),
                      },
                    ]}
                    hasActions={true}
                    rows={tableRows}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <VehicleModal
        data={vehicleData}
        cateogries={vehicleCategories}
        companies={workspaceCompanies}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: IVehicle) => {
          if (Vehicle.isNew(vehicleData))
            Vehicle.create(activeWorkspace?.workspaceId ?? "", formData);
          else Vehicle.update(vehicleData, formData);
          openUpsertModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        onCancel={() => openUpsertModal(false)}
      />

      <DialogForm
        title={t("inventory:delete_vehicle") + " " + vehicleData.name + "?"}
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        buttonConfirmColor="failure"
        confirmButton={() => {
          Vehicle.delete(vehicleData);
          openDeleteModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />
    </PageLayout>
  );
};

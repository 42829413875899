import { useState } from "react";
import { Tooltip } from "react-tooltip";
import { Card } from "flowbite-react";
import { CopyIcon } from "../Icons/Icons";

interface CardWithTooltipCopyProps {
  title: string;
  data: string;
  showCopyIcon?: boolean; // Optional boolean prop
}

const CardWithTooltipCopy: React.FC<CardWithTooltipCopyProps> = ({
  title,
  data,
  showCopyIcon = true, // Default value is true if not provided
}) => {
  const [tooltipContent, setTooltipContent] = useState("Click to copy");

  const handleClick = () => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        setTooltipContent("Copied");
        setTimeout(() => {
          setTooltipContent("Click to copy");
        }, 2200);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  return (
    <Card className="dark:bg-darkBlue dark:border-none">
      <div id="tooltip-with-copy">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col">
            <h4 className="text-sm font-semibold text-slate-400">{title}</h4>

            <div className="mb-4 font-medium text-darkBlue text-lg  dark:text-white">
              {data}
            </div>
          </div>
          {/* Render copy icon only if showCopyIcon is true */}
          {showCopyIcon && (
            <>
              <CopyIcon
                onClick={handleClick}
                data-tooltip-id="copyclick"
                data-tooltip-content={tooltipContent}
                className="fill-darkBlue dark:fill-white hover:fill-lgb-primary dark:hover:fill-lgb-primary h-6 hover:cursor-pointer transition-transform transform hover:rotate-6 w-auto focus:outline-none"
              />
              <Tooltip
                id="copyclick"
                style={{
                  backgroundColor: "#7214FF",
                  color: "white",
                  fontWeight: "600",
                  borderRadius: "8px",
                }}
                offset={10}
                content={tooltipContent}
              />
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default CardWithTooltipCopy;

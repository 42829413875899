/* eslint-disable jsx-a11y/anchor-is-valid */
import { type FC } from "react";

export interface ImageListProp {
  imgUrls: string[];
}

export const ImageList: FC<ImageListProp> = (props) => {
  return (
    <div>
      <div className="flex gap-4 flex-wrap">
        {props.imgUrls.map((x, index) => {
          return (
            <a href={x} target="_blank" rel="noreferrer">
              <img
                key={index}
                width={56}
                height={56}
                src={x}
                alt="Orderimage"
                className="rounded-md object-fill h-14 w-14 cursor-pointer hover:ring-2 hover:ring-lgb-blue-400"
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

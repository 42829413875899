/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, PropsWithRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Label, TextInput } from "flowbite-react";
import { ValidationError } from "../../../types";
import loginBackground from "../../../assets/logibud_brand_background.svg";
import secondaryLogo from "../../../assets/logo/logibud_secondary_white.svg";

interface LoginFormProps {
  onClicklogin: (email: string, password: string) => any;
  validation: ValidationError;
  isProcessing?: boolean | false;
}

const SignInPage: FC<PropsWithRef<LoginFormProps>> = function ({
  onClicklogin,
  validation,
  isProcessing,
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation(["common", "user"]);

  function handleLogin(event: any) {
    event.preventDefault();
    return onClicklogin(email, password);
  }

  return (
    <div
      style={{
        backgroundImage: `url(${loginBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        padding: "0",
      }}
      className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12"
    >
      <div className="my-6 flex items-center gap-x-1 lg:my-0 hidden sm:flex">
        <img src={secondaryLogo} className="max-w-[200px] hidden sm:block" />
      </div>
      <Card
        style={{
          background:
            "linear-gradient(135deg, rgba(6,11,39,1) 0%, #0c164d 100%)",
          border: "1px solid #42197f",
          maxWidth: "640px",
          boxShadow: "10px -9px 72px -9px rgba(114,20,255,0.42)",
        }}
        horizontal
        imgSrc=""
        imgAlt=""
        className="w-full md:max-w-[1024px] [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full sm:[&>*]:p-16 lg:[&>img]:block h-full sm:h-auto"
      >
        <div className="flex flex-col justify-between h-full">
          <img
            src={secondaryLogo}
            className="max-w-[200px] block sm:hidden mb-5"
          />
          <div>
            <h1 className="mb-3 text-2xl font-bold text-white ">
              {t("user:login.title")}
            </h1>

            <form onSubmit={handleLogin}>
              <div className="mb-4 flex flex-col gap-y-3">
                <Label className="text-gray-400" htmlFor="email">
                  {t("user:login.email.label")}
                </Label>
                <TextInput
                  id="email"
                  name="email"
                  style={{ fontSize: 20 }}
                  placeholder={t("user:login.email.placeholder")}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  color={
                    validation.errorFields &&
                    validation.errorFields.includes("email")
                      ? "failure"
                      : "grey"
                  }
                />
              </div>
              <div className="mb-4 flex flex-col gap-y-1">
                <Label className="text-gray-400" htmlFor="password">
                  {t("user:login.password.label")}
                </Label>
                <TextInput
                  id="password"
                  name="password"
                  placeholder={t("user:login.password.placeholder")}
                  type="password"
                  style={{ fontSize: 20 }}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  color={
                    validation.errorFields &&
                    validation.errorFields.includes("password")
                      ? "failure"
                      : "grey"
                  }
                />
              </div>
              <div className="mb-4 flex flex-col gap-y-1">
                {validation.isError && (
                  <p className="mt-1 text-sm text-red-600 dark:text-red-500">
                    {validation.errorMessage}
                  </p>
                )}
              </div>
              <div className="mb-4 flex items-center w-full">
                <Button
                  type="submit"
                  className="hover:bg-opacity-90 w-full h-14  bg-lgb-primary lg:w-full lg:max-w-full text-lg rounded-full" // Adjust font size here (e.g., text-lg)
                  isProcessing={isProcessing}
                >
                  <span className="text-lg">{t("user:login.login")}</span>
                </Button>
              </div>
            </form>
          </div>
          <div className="mb-6 flex items-left justify-between flex flex-wrap gap-6">
            <p className="text-sm text-white">
              <a
                href="/reset_password"
                className="w-1/2 text-right text-lg text-primary-600 dark:text-primary-300"
              >
                {t("user:login.lost_password")}
              </a>
            </p>
            <p className="text-lg text-white">
              <a
                href="/signup"
                className="text-primary-600 dark:text-primary-300"
              >
                {t("user:login.signup")}
              </a>
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SignInPage;

import { FC, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebaseConfig";
import { useUserSession } from "../../context/UserContext";
import { SignupForm } from "../../components";
import { ValidationError } from "../../types";

const authSuccess: ValidationError = { isError: false };

export const SignUpPage: FC = () => {
  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(firebaseAuth);

  const [errors, setErrors] = useState(authSuccess);
  const { t } = useTranslation("common");
  const { createUserSession, setUserSession, persistUserSession } =
    useUserSession();

  useEffect(() => {
    if (user) var userSession = createUserSession(user?.user);
    if (userSession) {
      setUserSession(userSession);
      if (userSession) persistUserSession(userSession);
    }

    if (error) {
      var authResult: ValidationError = {
        isError: true,
        errorFields: [],
        errorMessage: t("errors.signup_failure"),
      };
      if (error.code === "auth/invalid-email") {
        authResult.errorFields = ["email"];
        authResult.errorMessage = t("errors.email_invalid");
      }
      if (error.code === "auth/email-already-in-use") {
        authResult.errorFields = ["email"];
        authResult.errorMessage = t("errors.email_already_in_use");
      }
      if (error.code === "auth/missing-password") {
        authResult.errorFields = ["password"];
        authResult.errorMessage = t("errors.wrong_password");
      }
      if (error.code === "auth/weak-password") {
        authResult.errorFields = ["password"];
        authResult.errorMessage = t("errors.password_too_weak");
      }
      setErrors(authResult);
    }
  }, [user, error, setUserSession, createUserSession, persistUserSession, t]);

  if (user) {
    return <Navigate to="/load_workspace" />;
  }

  return (
    <div className="bg-gray-50 dark:bg-gray-900 w-screen h-screen">
      <SignupForm
        onClicksignup={async (email: string, password: string) =>
          createUserWithEmailAndPassword(email, password)
        }
        validation={errors}
        isProcessing={loading}
      />
    </div>
  );
};

import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../firebaseConfig";
import { useUserSession } from "../../context/UserContext";

export const LogoutPage = () => {
  const { setUserSession, revokeUserSession } = useUserSession();

  useEffect(() => {
    signOut(firebaseAuth).then((res) => {
      setUserSession(undefined);
      revokeUserSession();
    });
  }, [revokeUserSession, setUserSession]);

  return <Navigate to="/login" />;
};

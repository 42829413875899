import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { Location } from "../models";

const useLocations = (workspaceId: string) => {
  return useFirestoreQueryData(
    [Location.collectionName + "_" + workspaceId],
    Location.list(workspaceId),
    {
      subscribe: false,
    }
  );
};

export default useLocations;

import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { Driver } from "../models";
import { useCache } from "../context/CacheContext";

const useDrivers = (workspaceId: string) => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData(
    [Driver.collectionName, cacheKey, workspaceId],
    Driver.list(workspaceId),
    {
      subscribe: false,
    },
  );
};

export default useDrivers;

/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { Outlet } from "react-router-dom";
import { PageLayout } from "../components";

const OrderPage: FC = function () {
  return (
    <PageLayout>
      <Outlet />
    </PageLayout>
  );
};

export default OrderPage;

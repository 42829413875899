/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Order } from "../../models";
import { useOrders } from "../../hooks";
import { useWorkspace } from "../../context/WorkspaceContext";
import { OrderList } from "../../components";
import { Button } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../types/order/IOrder";

export const OngoingOrdersPage: FC = function () {
  const [searchParams] = useSearchParams();
  const [splitIsOpen, setSplitIsOpen] = useState(false);
  const { activeWorkspace } = useWorkspace();
  const { t } = useTranslation(["common", "orders", "validation"]);
  const navigate = useNavigate();

  searchParams.get("__firebase_request_key");

  const query = useOrders(activeWorkspace?.workspaceId ?? "", [
    OrderStatus.InProgress,
  ]);

  const orders = useMemo(() => query.data ?? [], [query]) as Order[];

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  return (
    <>
      <div className="flex justify-between items-start lg:p-4">
        <div className={splitIsOpen ? "hidden lg:block" : ""}>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {t("orders:ongoing_orders.page_title")}
          </h1>
          <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
            {t("orders:ongoing_orders.page_description")}
          </p>
        </div>
        <Button
          color="light"
          onClick={() => navigate("/orders/new")}
          type="submit"
          className={"mt-2 " + (splitIsOpen ? "hidden lg:block" : "")}
        >
          <HiOutlinePlus className="lg:mr-2 h-5 w-5" />
          <span className="hidden lg:block">{t("orders:create.title")}</span>
        </Button>
      </div>
      <OrderList
        orders={orders}
        isLoading={isQueryLoading}
        isOpen={setSplitIsOpen}
      />
    </>
  );
};

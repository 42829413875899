/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState } from "react";
import { TextInput } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { StandardForm, Dialog, SelectCountry } from "../..";
import { ICompany } from "../../../types";
import { useTranslation } from "react-i18next";
import { Company } from "../../../models";

export interface CompanyModalProp {
  data: ICompany;
  isShowing: boolean;
  onConfirm: (data: ICompany) => void;
  onCancel: () => void;
}

export const CompanyForm: FC<CompanyModalProp> = function (
  props: CompanyModalProp,
) {
  const { t } = useTranslation(["common", "company", "modals"]);

  const [isNew] = useState(Company.isNew(props.data));

  return (
    <StandardForm
      showHeader={false}
      description={t("modals:descriptions.companies")}
      fields={[
        {
          id: "name",
          label: t("common:name"),
          input: (
            <TextInput
              id="name"
              name="name"
              placeholder={t("common:placeholder.company_name")}
              defaultValue={props.data.name ?? ""}
              type="text"
              required={true}
            />
          ),
        },
        {
          id: "organizationNumber",
          label: t("common:organization_number"),
          input: (
            <TextInput
              id="organizationNumber"
              name="organizationNumber"
              placeholder={t("common:placeholder.organization_number")}
              defaultValue={props.data.organizationNumber}
            />
          ),
        },
        {
          id: "contactEmail",
          label: t("common:email"),
          input: (
            <TextInput
              id="contactEmail"
              name="contactEmail"
              placeholder={t("common:placeholder.email")}
              defaultValue={props.data.contactEmail ?? ""}
            />
          ),
        },
        {
          id: "invoiceEmail",
          label: t("common:invoice_email"),
          input: (
            <TextInput
              id="invoiceEmail"
              name="invoiceEmail"
              placeholder={t("common:placeholder.email")}
              defaultValue={props.data.invoiceEmail ?? ""}
            />
          ),
        },
        {
          id: "primaryAddress.addressLine",
          label: t("common:address_line"),
          input: (
            <TextInput
              id="primaryAddress.addressLine"
              name="primaryAddress.addressLine"
              placeholder={t("common:placeholder.address_line")}
              defaultValue={props.data.primaryAddress?.addressLine ?? ""}
            />
          ),
        },
        {
          id: "primaryAddress.postCode",
          label: t("common:postcode"),
          input: (
            <TextInput
              id="primaryAddress.postCode"
              name="primaryAddress.postCode"
              placeholder={t("common:placeholder.postcode")}
              defaultValue={props.data.primaryAddress?.postCode ?? ""}
            />
          ),
        },
        {
          id: "primaryAddress.city",
          label: t("common:city"),
          input: (
            <TextInput
              id="primaryAddress.city"
              name="primaryAddress.city"
              placeholder={t("common:placeholder.city")}
              defaultValue={props.data.primaryAddress?.city ?? ""}
            />
          ),
        },
        {
          id: "primaryAddress.countryCode",
          label: t("common:country"),
          input: (
            <SelectCountry
              id="primaryAddress.countryCode"
              defaultValue={props.data.primaryAddress?.countryCode ?? "NO"}
            />
          ),
        },
      ]}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      buttonConfirmText={t("modals:button_texts.company")}
      buttonConfirmPrependIcon={
        isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null
      }
      buttonCloseText={t("common:close")}
    />
  );
};

export const CompanyModal: FC<CompanyModalProp> = function (
  props: CompanyModalProp,
) {
  const { t } = useTranslation(["common", "company"]);

  return (
    <Dialog
      title={
        Company.isNew(props.data)
          ? t("company:create_company")
          : t("company:update_company")
      }
      content={<CompanyForm {...props} />}
      show={props.isShowing}
      toggleModal={props.onCancel}
      hideButtons={true}
    />
  );
};

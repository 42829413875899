import type { FC } from "react";

interface CardProps {
  type?: undefined | "dashed" | "dashed-green";
  bgColorClass?: string;
  borderClass?: string;
  containerClass?: string;
  children?: React.ReactNode;
}

export const Card: FC<CardProps> = function (props: CardProps) {
  return (
    <div className={props.containerClass ?? ""}>
      <div
        className={
          (props.type && props.type === "dashed"
            ? "border-dashed border-2 border-gray-600 dark:border-lgb-grey-600 "
            : "") +
          (props.type && props.type === "dashed-green"
            ? "border-dashed border-2 border-green-600 dark:border-green-500 "
            : "") +
          (props.bgColorClass ? props.bgColorClass + " " : "bg-white ") +
          (props.borderClass ? props.borderClass + " " : "") +
          "p-6 rounded-lg h-full"
        }
      >
        {props.children}
      </div>
    </div>
  );
};

export default Card;

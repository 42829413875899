/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { Button, Label, TextInput } from "flowbite-react"; // assuming Card is not used
import { useTranslation } from "react-i18next";
import { ValidationError } from "../../../types/ValidationError";
import secondaryLogo from "../../../assets/logo/logibud_secondary_white.svg";

interface SignupFormProps {
  onClicksignup: (email: string, password: string) => any;
  validation: ValidationError;
  isProcessing?: boolean;
}

const SignUpPage: FC<SignupFormProps> = ({
  onClicksignup,
  validation,
  isProcessing = false,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation(["common", "user"]);

  function handleSignup(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onClicksignup(email, password);
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen lg:gap-y-12 bg-white p-0">
      <div className="grid lg:h-screen lg:grid-cols-2 w-full h-full md:w-full md:h-full">
        <div className="flex items-center justify-center px-4 py-6 lg:py-0 h-full md:w-full md:h-full">
          <form onSubmit={handleSignup} className="w-full max-w-xl">
            <p className="text-2xl font-medium mb-5">
              {t("user:login.signup")}
            </p>
            <div className="mb-4 flex flex-col gap-y-3 ">
              <Label htmlFor="email" style={{ color: "#878789" }}>
                {t("user:login.email.label")}
              </Label>
              <TextInput
                id="email"
                name="email"
                placeholder={t("user:login.email.placeholder")}
                type="email"
                value={email}
                style={{ fontSize: 20 }}
                onChange={(e) => setEmail(e.target.value)}
                color={
                  validation.errorFields &&
                  validation.errorFields.includes("email")
                    ? "failure"
                    : "grey"
                }
              />
            </div>
            <div className="mb-6 flex flex-col gap-y-3 ">
              <Label style={{ color: "#878789" }} htmlFor="password">
                {t("user:login.password.label")}
              </Label>
              <TextInput
                id="password"
                name="password"
                placeholder={t("user:login.password.placeholder")}
                type="password"
                value={password}
                style={{ fontSize: 20 }}
                onChange={(e) => setPassword(e.target.value)}
                color={
                  validation.errorFields &&
                  validation.errorFields.includes("password")
                    ? "failure"
                    : "grey"
                }
              />
            </div>
            {validation.isError && (
              <div className="mb-4 flex flex-col gap-y-3">
                <p className="mt-1 text-sm text-red-600 dark:text-red-500">
                  {validation.errorMessage}
                </p>
              </div>
            )}
            <div className="mb-4">
              <Button
                type="submit"
                className="w-full bg-lgb-primary rounded-full h-14 transition duration-300 ease-in-out transform hover:scale-105 hover:bg-lgb-primary-dark"
                isProcessing={isProcessing}
                style={{
                  boxShadow: "-5px 4px 24px -4px rgba(114,20,255,0.42)", // Adjusted shadow values
                }}
              >
                {t("user:login.signup")}
              </Button>
            </div>
            <p className="text-md mt-12 text-darkBlue">
              <a href="/login" className="text-darkBlue">
                {t("user:login.already_registered")}&nbsp;{" "}
                {t("user:login.go_to_login")}
              </a>
            </p>
          </form>
        </div>
        <div
          className="flex items-center justify-center px-4 py-6 lg:py-0 sm:px-6 "
          style={{
            backgroundImage:
              "linear-gradient(135deg, rgba(6,11,39,1) 0%, #0c164d 100%)",
          }}
        >
          <div className="max-w-md xl:max-w-xl w-full h-full md:w-full md:h-full justify-center flex-col content-center">
            <a
              href="#"
              className="flex items-center mb-4 text-2xl font-semibold text-darkBlue"
            >
              <img className="h-8 mr-2" src={secondaryLogo} alt="logo" />
              Flowbite
            </a>
            <h1 className="mb-4 text-3xl font-extrabold leading-none tracking-tight text-white xl:text-5xl">
              {t("user:login.signup_banner_title")}
            </h1>
            <p className="mb-4 font-light text-white lg:mb-8">
              {t("user:login.signup_banner_description")}
            </p>
            <div className="flex flex-col items-center divide-x divide-primary-500 sm:flex-row sm:items-center">
              <div className="flex pr-3 -space-x-4 sm:pr-5">
                <img
                  className="w-10 h-10 border-2 border-white rounded-full"
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                  alt="bonnie avatar"
                />
                <img
                  className="w-10 h-10 border-2 border-white rounded-full"
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png"
                  alt="jese avatar"
                />
                <img
                  className="w-10 h-10 border-2 border-white rounded-full"
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                  alt="roberta avatar"
                />
                <img
                  className="w-10 h-10 border-2 border-white rounded-full"
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/thomas-lean.png"
                  alt="thomas avatar"
                />
              </div>
              <a href="#" className="pl-3 text-white sm:pl-5 dark:text-white">
                <span className="text-sm text-primary-200">
                  {t("user:login.signup_banner_customers")}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;

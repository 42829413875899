import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Label, Textarea, TextInput } from "flowbite-react";
import { Card, Dialog, LGBDatepicker } from "../..";
import { IContactPerson, IRouteItem } from "../../../types";
import AddContactContent from "./AddContactContent";
import { HiOutlineX, HiOutlineCheckCircle } from "react-icons/hi";
import { getTimeIntervalsForDate } from "../../../static/timeIntervals";
import isSmallScreen from "../../../helpers/is-small-screen";

export interface RouteCardProps {
  routeItem: IRouteItem;
  routeLength: number;
  validationFailed: boolean;
  update: (routeItem: IRouteItem) => void;
  remove: () => void;
}

export const RouteCard: FC<RouteCardProps> = (props) => {
  const [contactDialogState, setContactDialogState] = useState(false);
  const [timeIntervals, setTimeIntervals] = useState(
    getTimeIntervalsForDate(new Date()),
  );
  const { t } = useTranslation(["orders", "validation"]);
  const errorStyle =
    "border-lgb-red-200 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-lgb-red-100 dark:focus:border-red-500 dark:focus:ring-red-500";

  const toggleDialog = () => {
    setContactDialogState(!contactDialogState);
  };

  const customTheme = {
    field: {
      input: {
        base: "dark:bg-lgb-dark-background dark:text-white w-full",
      },
    },
  };

  const removePerson = (person: IContactPerson) => {
    let item = { ...props.routeItem } as IRouteItem;
    if (item && item.contact) {
      if (item.contact.length === 1) {
        item.contact = undefined;
      } else {
        item.contact = item.contact.filter(
          (x) => x.phoneNumber !== person.phoneNumber,
        );
      }
    }
    props.update(item);
  };

  const updateRouteItem = (key: string, value: string | Date | Object) => {
    let item = { ...props.routeItem } as IRouteItem;
    if (
      key === "displayName" ||
      key === "addressLine" ||
      key === "description" ||
      key === "city" ||
      key === "postCode"
    ) {
      var location = { ...props.routeItem.location };
      item.location = { ...location, [key]: value as string };
      //Swap times
    } else if (key === "date") {
      setTimeIntervals(getTimeIntervalsForDate(value as Date));
    } else {
      item = { ...props.routeItem, [key]: value } as IRouteItem;
    }
    props.update(item);
  };

  const updateRouteTiming = (type: number, val1 = "", val2 = "") => {
    switch (type) {
      case 1:
        updateRouteItem("timing", {
          before: val1 ?? "",
        });

        break;
      case 2:
        updateRouteItem("timing", {
          before: val1 ?? "",
          after: val2 ?? "",
        });
        break;
      case 3:
        updateRouteItem("timing", {
          after: val1 ?? "",
        });
        break;
      case 4:
        updateRouteItem("timing", { earliest: true });
        break;
      case 5:
        updateRouteItem("timing", { latest: true });
        break;
    }
  };
  const getTimingType = () => {
    switch (true) {
      case "before" in props.routeItem.timing &&
        "after" in props.routeItem.timing:
        return 2;
      case "before" in props.routeItem.timing:
        return 1;
      case "after" in props.routeItem.timing:
        return 3;
      case "earliest" in props.routeItem.timing:
        return 4;
      case "latest" in props.routeItem.timing:
        return 5;
      default:
        return 1;
    }
  };

  return (
    <Card
      type="dashed"
      bgColorClass={
        props.routeItem.location.id
          ? "bg-lgb-green-100 dark:bg-lgb-on-dark-background"
          : "bg-lgb-yellow-100 dark:bg-lgb-on-dark-background"
      }
      borderClass={
        props.routeItem.location.id
          ? "dark:border-lgb-green-400 border-1"
          : "dark:border-lgb-yellow-400 border-1"
      }
    >
      <div className="grid grid-cols-2 gap-4 mb-4 items-center">
        <div className="flex justify-start items-center">
          <span className="border bg-lgb-grey-100 dark:bg-lgb-grey-200 dark:border-lgb-grey-600 border-lgb-grey-300 px-4 py-1 font-bold rounded text-sm">
            {t("create.route_selection.stop")} {props.routeItem.stopNumber + 1}
          </span>
          {!props.routeItem.location.id && (
            <p className="pl-4 text-lgb-grey-400 dark:text-lgb-grey-400">
              ({t("create.route_selection.not_saved")})
            </p>
          )}
        </div>
        <div>
          <Label
            className="dark:text-gray-900"
            htmlFor="date"
            value={t("create.route_selection.date")}
          />
          <LGBDatepicker
            showError={props.validationFailed && !props.routeItem.stopDate}
            selectedDate={props.routeItem.stopDate as Date}
            select={(e) => updateRouteItem("date", e)}
          />
        </div>
      </div>
      <div className="mb-4">
        {props.routeItem.location.id && (
          <div className="flex items-center">
            <p className="text-md font-bold lgb-light-text dark:text-lgb-grey-200">
              {props.routeItem.location.displayName}
            </p>
            <HiOutlineCheckCircle className="text-lgb-green-400 h-5 w-5 ml-4 mr-1 mt-1" />
            <p className="text-lgb-green-400">
              ({t("create.route_selection.saved")})
            </p>
          </div>
        )}
      </div>

      <div
        className={
          "grid gap-4 " + (isSmallScreen() ? "grid-cols-1" : "grid-cols-2")
        }
      >
        <div>
          <div className="mb-4">
            <div className="mb-2 block">
              <Label
                className="dark:text-lgb-grey-400"
                htmlFor="address"
                value={t("create.route_selection.address")}
              />
            </div>
            <TextInput
              theme={customTheme}
              id="address"
              name="address"
              disabled={props.routeItem.location.id ? true : false}
              autoComplete="off"
              value={props.routeItem.location.addressLine || ""}
              placeholder={t("create.route_selection.address_ph")}
              required
              type="text"
              onChange={(e) => updateRouteItem("addressLine", e.target.value)}
              color={
                props.validationFailed &&
                !props.routeItem.location?.addressLine?.length
                  ? "failure"
                  : undefined
              }
              helperText={
                props.validationFailed &&
                !props.routeItem.location?.addressLine?.length ? (
                  <p className=" text-sm text-red-600 dark:text-red-500">
                    {t("validation:route_info.address")}
                  </p>
                ) : (
                  ""
                )
              }
            />
          </div>

          <div className="mb-4 grid grid-cols-2 gap-4">
            <div>
              <div>
                <div className="mb-2 block">
                  <Label
                    className="dark:text-lgb-grey-400"
                    htmlFor="postCode"
                    value={t("create.route_selection.postal_code")}
                  />
                </div>
                <TextInput
                  id="postCode"
                  name="postCode"
                  autoComplete="off"
                  theme={customTheme}
                  disabled={props.routeItem.location.id ? true : false}
                  value={props.routeItem.location.postCode || ""}
                  placeholder={t("create.route_selection.postal_code_ph")}
                  required
                  type="text"
                  onChange={(e) => updateRouteItem("postCode", e.target.value)}
                  color={
                    props.validationFailed &&
                    !props.routeItem.location?.postCode?.length
                      ? "failure"
                      : undefined
                  }
                  helperText={
                    props.validationFailed &&
                    !props.routeItem.location?.postCode?.length ? (
                      <p className="absolute text-sm text-red-600 dark:text-red-500">
                        {t("validation:postal_code")}
                      </p>
                    ) : (
                      ""
                    )
                  }
                />
              </div>
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  className="text-lgb-dark-background dark:text-lgb-grey-400"
                  htmlFor="city"
                  value={t("create.route_selection.city")}
                />
              </div>
              <TextInput
                id="city"
                theme={customTheme}
                name="city"
                autoComplete="off"
                value={props.routeItem.location.city || ""}
                placeholder={t("create.route_selection.city_ph")}
                required
                type="text"
                disabled={props.routeItem.location.id ? true : false}
                onChange={(e) => updateRouteItem("city", e.target.value)}
                color={
                  props.validationFailed &&
                  !props.routeItem.location?.city?.length
                    ? "failure"
                    : undefined
                }
                helperText={
                  props.validationFailed &&
                  !props.routeItem.location?.city?.length ? (
                    <p className=" text-sm text-red-600 dark:text-red-500">
                      {t("validation:route_info.city")}
                    </p>
                  ) : (
                    ""
                  )
                }
              />
            </div>
          </div>

          <div
            className={
              getTimingType() === 2
                ? "grid grid-cols-4 gap-4"
                : "grid grid-cols-2 gap-4"
            }
          >
            <div
              className={
                "w-full" + (getTimingType() === 2 ? " col-span-2" : "")
              }
            >
              <Label
                className="dark:text-lgb-grey-400"
                htmlFor="timing"
                value={t("create.route_selection.timing")}
              />
              <form>
                <select
                  id="timing"
                  className="w-full border p-2.5 text-sm rounded-lg relative border-gray-300 dark:border-gray-700 dark:border-lgb-grey-600 dark:bg-lgb-dark-background dark:text-white"
                  defaultValue={getTimingType()}
                  required
                  onChange={(e) => updateRouteTiming(+e.target.value)}
                >
                  <option value={1}>
                    {t("create.route_selection.before")}
                  </option>
                  <option value={2}>
                    {t("create.route_selection.before_and_after")}
                  </option>
                  <option value={3}>{t("create.route_selection.after")}</option>
                  <option value={4}>{t("create.route_selection.asap")}</option>
                  <option value={5}>
                    {t("create.route_selection.latest")}
                  </option>
                </select>
              </form>
            </div>

            {getTimingType() !== 4 && getTimingType() !== 5 && (
              <div className="w-full cursor-pointer">
                <Label
                  className="dark:text-lgb-grey-400 "
                  htmlFor="time"
                  value={
                    [1, 2].includes(getTimingType())
                      ? t("create.route_selection.before")
                      : t("create.route_selection.after")
                  }
                />
                <form>
                  <select
                    id="time"
                    className={
                      (props.validationFailed &&
                      ([1, 2].includes(getTimingType())
                        ? !props.routeItem.timing?.before ||
                          props.routeItem.timing?.before === ""
                        : !props.routeItem.timing?.after ||
                          props.routeItem.timing?.after === "")
                        ? errorStyle
                        : "") +
                      " w-full border p-2.5 text-sm rounded-lg relative border-gray-300 dark:border-lgb-grey-600 dark:bg-lgb-dark-background dark:text-white cursor-pointer"
                    }
                    defaultValue={
                      [1, 2].includes(getTimingType())
                        ? (props.routeItem.timing?.before as string) || ""
                        : (props.routeItem.timing?.after as string) || ""
                    }
                    required
                    onChange={(e) =>
                      updateRouteTiming(
                        getTimingType(),
                        e.target.value,
                        getTimingType() === 2
                          ? (props.routeItem.timing?.after as string) || ""
                          : undefined,
                      )
                    }
                  >
                    <option disabled defaultValue=""></option>
                    {timeIntervals.map((x, idx) => {
                      return (
                        <option value={x} key={idx}>
                          {x}
                        </option>
                      );
                    })}
                  </select>
                </form>
              </div>
            )}

            {getTimingType() === 2 && (
              <div className="w-full">
                <Label
                  className="dark:text-gray-900"
                  htmlFor="latest"
                  value={t("create.route_selection.latest")}
                />
                <form>
                  <select
                    id="latest"
                    className={
                      (props.validationFailed &&
                      (!props.routeItem.timing?.after ||
                        props.routeItem.timing?.after === "")
                        ? errorStyle
                        : "") +
                      " w-full border p-2.5 text-sm rounded-lg relative border-gray-300 dark:border-gray-700 dark:border-lgb-grey-600 dark:bg-lgb-dark-background dark:text-white"
                    }
                    required
                    defaultValue={
                      (props.routeItem.timing?.after as string) || ""
                    }
                    onChange={(e) =>
                      updateRouteTiming(
                        getTimingType(),
                        props.routeItem.timing?.before as string,
                        e.target.value,
                      )
                    }
                  >
                    <option disabled defaultValue=""></option>
                    {timeIntervals.map((x, idx) => {
                      return (
                        <option value={x} key={idx}>
                          {x}
                        </option>
                      );
                    })}
                  </select>
                </form>
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="text-sm font-semibold text-gray-900 dark:text-lgb-grey-400 pb-3">
            {t("create.route_selection.description")}
          </p>
          <Textarea
            style={{ height: "calc(100% - 32px)" }}
            className="bg-white dark:bg-lgb-dark-background text-gray-900  box-border dark:text-white"
            id="description"
            name="description"
            placeholder={t("create.route_selection.description_ph")}
            required
            value={props.routeItem.location.description}
            onChange={(e) => updateRouteItem("description", e.target.value)}
          />
        </div>
      </div>
      <div>
        {props.routeItem.contact &&
          props.routeItem.contact.length &&
          props.routeItem.contact.map((person, idx) => {
            return (
              <div
                key={idx}
                className="flex justify-between pt-6 pb-2 w-full border-b dark:border-b-gray-700"
              >
                <div>
                  <p>{person.name}</p>
                </div>
                <div className="flex gap-4 items-center">
                  <p>{person.phoneNumber}</p>
                  <HiOutlineX
                    className="mr-2 h-5 w-5 cursor-pointer"
                    onClick={() => removePerson(person)}
                  />
                </div>
              </div>
            );
          })}
        <div
          className={
            "flex " +
            (props.routeLength > 2 ? "justify-between" : "justify-end")
          }
        >
          {props.routeLength > 2 && (
            <p
              className="pt-6 text-sm font-normal underline cursor-pointer hover:text-lgb-grey-500 dark:text-red-800 dark:hover:text-red-700"
              onClick={() => props.remove()}
            >
              {t("create.route_selection.remove")}
            </p>
          )}
          <p
            className="pt-6 text-sm text-lgb-primary hover:text-lgb-blue-400 dark:text-white dark:hover:text-lgb-grey-200 underline font-medium cursor-pointer"
            onClick={() => toggleDialog()}
          >
            {t("create.route_selection.add_contact_title")}
          </p>
        </div>
      </div>
      <Dialog
        title={t("create.contact_info.title")}
        show={contactDialogState}
        toggleModal={() => toggleDialog()}
        hideButtons
        content={
          <AddContactContent
            routeItem={props.routeItem}
            update={(e) => props.update(e)}
            close={() => toggleDialog()}
          />
        }
      />
    </Card>
  );
};

export default RouteCard;

import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { User } from "../models";
import { useCache } from "../context/CacheContext";

const useUsers = () => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData([User.collectionName, cacheKey], User.list(), {
    subscribe: false,
  });
};

export default useUsers;

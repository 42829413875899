import React, { useEffect, useState, useMemo, useRef, FC } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  OverlayView,
  DirectionsRenderer,
} from "@react-google-maps/api";
import axios from "axios";
import MarkerDriverSvg from "../../assets/icons/driverMap.svg";
import WhiteMarkerSvg from "../../assets/icons/WhiteMarker.svg";
import DatePicker from "tailwind-datepicker-react";
import { useDarkMode } from "../../context/DarkModeContext";
import { PageLayout } from "../../components";

const customStyles = [
  {
    featureType: "all",
    elementType: "geometry",
    stylers: [
      {
        color: "#1d2c4d",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8ec3b9",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1a3646",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#4b6878",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#4b6878",
      },
    ],
  },
  // Hiding all Points of Interest (Landmarks)
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off", // Hide labels for landmarks
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#334e87",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        color: "#023e58",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#304a7d",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#98a5be",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1d2c4d",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#2c6675",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#255763",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#b0d5ce",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#023e58",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#98a5be",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1d2c4d",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#283d6a",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#3a4762",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#0e1626",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#4e6d70",
      },
    ],
  },
];

interface Location {
  id: string;
  orderId: string;
  type: string;
  status: string;
  deadline: string;
  firstName: string;
  lastName: string;
  location?: {
    latitude: number;
    longitude: number;
  };
}

interface Option {
  id: number;
  label: string;
}

interface SelectedTypes {
  [key: number]: boolean;
}

const containerStyle = {
  width: "100%",
  height: "620px",
  // borderRadius: "10px",
};

const initialCenter = {
  lat: 62.75769482961424,
  lng: 16.89871699772572,
};

export const DriverMapPage: FC = function () {
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCId5nWuAcqhcHRic1cEXogmF5Q8Isxovo",
    libraries: ["marker", "places"],
  });
  const { isDarkMode } = useDarkMode();
  const [locationData, setLocationData] = useState<Location[]>([]);
  const [filteredLocationData, setFilteredLocationData] = useState<Location[]>(
    [],
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [isOpen, setIsOpen] = useState(false);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState<SelectedTypes>({});

  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const handleChange = (selectedDate: Date) => {
    console.log(selectedDate);
  };

  const handleClose = (state: boolean) => {
    setShow(state);
  };

  const optionsDate = {
    title: "Please Select Date",
    inputPlaceholderProp: "Today",
    inputNameProp: "date",
    inputIdProp: "date",
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    dateFormat: "dd/mm/yyyy",
  };

  useEffect(() => {
    getLocationData();
  }, [isDarkMode]);

  async function getLocationData() {
    try {
      //temporary data to fetch location
      const response = await axios.get<Location[]>(
        `https://demoddatabase-default-rtdb.europe-west1.firebasedatabase.app/mapLocation.json`,
      );
      setLocationData(response.data);
      setFilteredLocationData(response.data);
      const initialCheckedItems: { [key: string]: boolean } = {};
      response.data.forEach((driver) => {
        initialCheckedItems[driver.firstName] = false;
      });
      setCheckedItems(initialCheckedItems);
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  }

  const handleApplyFilter = () => {
    const selectedDriversList = Object.keys(checkedItems).filter(
      (driver) => checkedItems[driver],
    );
    const filtered = locationData.filter((driver) =>
      selectedDriversList.includes(driver.firstName),
    );
    setFilteredLocationData(filtered);
    setIsOpen(false); // Close the dropdown after applying the filter
  };

  const handleReset = () => {
    const resetCheckedItems: { [key: string]: boolean } = {};
    locationData.forEach((driver) => {
      resetCheckedItems[driver.firstName] = false;
    });
    setCheckedItems(resetCheckedItems);
    getLocationData();
    setIsOpen(false);
  };

  // Update filteredLocationData based on the searchQuery
  useEffect(() => {
    const filteredData = locationData.filter((location) =>
      location.firstName.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setFilteredLocationData(filteredData);
  }, [searchQuery, locationData]);

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  // const handleMarkerClick = (location: Location) => {
  //     setSelectedLocation(location);
  //     if (location.location && mapRef.current) {
  //         const { latitude, longitude } = location.location;
  //         mapRef.current.setCenter({ lat: latitude, lng: longitude });
  //         mapRef.current.setZoom(4); // Adjust zoom if needed
  //     }
  // };

  // Function to calculate directions from start to destination
  const calculateRoute = async (
    start: google.maps.LatLngLiteral,
    end: google.maps.LatLngLiteral,
  ) => {
    const directionsService = new google.maps.DirectionsService();
    try {
      const result = await directionsService.route({
        origin: start,
        destination: end,
        travelMode: google.maps.TravelMode.DRIVING,
      });
      setDirections(result);
    } catch (error) {
      console.error(`Error fetching directions: ${error}`);
    }
  };

  const handleMarkerClick = (location: Location) => {
    setSelectedLocation(location);
    if (location.location && mapRef.current) {
      const { latitude, longitude } = location.location;
      const destination = { lat: latitude, lng: longitude };
      const start = initialCenter; // Replace with the actual start point

      // Calculate route from start to destination
      calculateRoute(start, destination);

      mapRef.current.setCenter(destination);
      mapRef.current.setZoom(14); // Adjust zoom as needed
    }
  };

  const handleDropdownToggle = (): void => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { id, checked } = e.target;
    setCheckedItems((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const closeInfoBox = () => {
    setSelectedLocation(null);
  };

  const customIcon = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MarkerDriverSvg,
      scaledSize: new window.google.maps.Size(40, 40),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(15, 15),
      labelOrigin: new window.google.maps.Point(15, 15),
    };
  }, [isLoaded]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  // Store id and label in the options array
  const options: Option[] = [
    { id: 1, label: "Ongoing" },
    { id: 2, label: "Pending" },
    { id: 3, label: "Completed" },
  ];

  const handleDropdownToggle1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleCheckboxChange1 = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    setSelectedTypes((prevState) => ({
      ...prevState,
      [id]: e.target.checked,
    }));
  };

  const handleReset1 = () => {
    const resetSelectedTypes: { [key: number]: boolean } = {};
    options.forEach((option) => {
      resetSelectedTypes[option.id] = false;
    });
    setSelectedTypes(resetSelectedTypes);
    getLocationData();
    setIsOpen1(false);
  };

  return (
    <PageLayout>
      <div className="md:h-[600px] h-auto md:w-[50] w-full md:relative mt-[-40px] ">
        <div className="mx-auto mt-2 md:flex sm:flex-col justify-between gap-2 w-[100%] md:p-2">
          <div className="md:absolute md:z-10 md:w-[30%] rounded-md px-5">
            <div className="md:h-[600px] w-full">
              <div className="max-w-md mx-auto">
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} // Update searchQuery on input change
                    className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg   dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search for order ID, driver name or plate number"
                    required
                  />

                  {/* <input type="search" id="default-search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for order ID, driver name or plate number" required /> */}
                  {/* <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
                </div>
              </div>
              <div className="w-[100%] flex flex-wrap md:flex-nowrap mt-2">
                <div className="relative md:w-[100%] flex items-center justify-center px-1 md:px-0 md:py-4">
                  <button
                    id="dropdownDefault"
                    onClick={handleDropdownToggle}
                    className="text-black border dark:text-white border-gray-200 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                    type="button"
                  >
                    Driver
                    <svg
                      className="w-4 h-4 ml-2"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>

                  {isOpen && (
                    <div
                      id="dropdown"
                      className="absolute top-16 w-[300px] ml-20  z-10 p-3 bg-white rounded-lg shadow"
                    >
                      <ul className="flex flex-wrap space-y-2 text-sm">
                        {locationData.map((location, index) => (
                          <li
                            className=" w-[50%] flex items-center"
                            key={index}
                          >
                            <input
                              id={location.firstName}
                              type="checkbox"
                              checked={
                                checkedItems[location.firstName] || false
                              }
                              onChange={handleCheckboxChange}
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-[#7214FF] focus:ring-[#7214FF] "
                            />
                            <label
                              htmlFor={location.firstName}
                              className="ml-2 text-sm font-medium text-gray-900"
                            >
                              {location.firstName} {location.lastName}
                            </label>
                          </li>
                        ))}
                      </ul>
                      <div className="flex gap-4 m-4">
                        <button
                          className="p-1 bg-purple-600 text-white rounded-md px-3"
                          onClick={handleApplyFilter}
                        >
                          Apply
                        </button>
                        <button
                          className="p-1 border border-gray-500 rounded-md px-3"
                          onClick={handleReset}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="relative md:w-full flex items-center justify-center px-0 md:py-4">
                  <button
                    id="dropdownDefault"
                    onClick={handleDropdownToggle1}
                    className="text-black border border-gray-200 dark:text-white focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                    type="button"
                  >
                    Type
                    <svg
                      className="w-4 h-4 ml-2"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>

                  {isOpen1 && (
                    <div
                      id="dropdown"
                      className="absolute top-16 w-[200px] left-0 z-10 p-3 bg-white rounded-lg shadow"
                    >
                      <ul className="flex flex-wrap space-y-2 text-sm">
                        {options.map((option, index) => (
                          <li
                            className="w-[100%] flex items-center"
                            key={index}
                          >
                            <input
                              type="checkbox"
                              id={`option-${option.id}`}
                              name="type"
                              value={option.label}
                              onChange={(e) =>
                                handleCheckboxChange1(e, option.id)
                              }
                              checked={selectedTypes[option.id] || false}
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-[#7214FF] focus:ring-[#7214FF]"
                            />
                            <label
                              htmlFor={`option-${option.id}`}
                              className="ml-2 text-sm font-medium text-gray-900"
                            >
                              {option.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                      <div className="flex gap-4 m-4">
                        <button
                          className="p-1 bg-purple-600 text-white rounded-md px-3"
                          onClick={() => setIsOpen1(false)}
                        >
                          Apply
                        </button>
                        <button
                          className="p-1 border border-gray-500 rounded-md px-3"
                          onClick={handleReset1}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="relative md:w-full flex  items-center justify-center px-0 md:py-4">
                  <div className="rounded-lg p-2 md:w-[160px]">
                    {/* <DatePicker options={optionsDate} onChange={handleChange} show={show} setShow={handleClose} /> */}
                    <input
                      className="text-black border border-gray-200 dark:text-white bg-gray-50 dark:bg-[#060b27] focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                      type="date"
                      name=""
                      id=""
                    />
                  </div>
                </div>
              </div>

              <div className="h-full w-full">
                <div
                  className="overflow-y-auto  h-[500px] "
                  style={{ maxHeight: "600px", scrollbarWidth: "none" }}
                >
                  <table className="w-full border-collapse dark:text-white">
                    <thead className="sticky top-0 bg-[#f8fafc] dark:bg-gray-800">
                      <tr>
                        <th className="border-b p-2 text-[14px]">#</th>
                        <th className="border-b p-2 text-[14px]">Order Id</th>
                        <th className="border-b p-2 text-[14px] md:block hidden ">
                          Type
                        </th>
                        <th className="border-b p-2 text-[14px]">Status</th>
                        <th className="border-b p-2 text-[14px]">Deadline</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredLocationData.map((location) => (
                        <tr
                          key={location.id}
                          className="cursor-pointer hover:bg-purple-100 dark:hover:bg-lgb-primary-lighten"
                          onClick={() => handleMarkerClick(location)}
                        >
                          <td className="border-b p-2 text-[14px]">
                            {location.id}
                          </td>
                          <td className="border-b p-2 text-[14px]">
                            {location.orderId}
                          </td>
                          <td className="border-b p-2 text-[14px] md:block hidden">
                            {location.type}
                          </td>
                          <td className={`border-b p-2 text-[14px] `}>
                            <span
                              className={`${
                                location.status === "Ongoing"
                                  ? "bg-purple-100 px-2 py-1 rounded-md dark:text-black" // Style for 'Ongoing'
                                  : location.status === "Pending"
                                    ? "bg-yellow-100 px-2 py-1 rounded-md dark:text-black" // Style for 'Pending'
                                    : location.status === "Completed"
                                      ? "bg-green-100 px-2 py-1 rounded-md dark:text-black" // Style for 'Completed'
                                      : ""
                              }`}
                            >
                              {location.status}
                            </span>
                          </td>
                          <td className="border-b p-2 text-[14px]">
                            {location.deadline}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-full md:w-[70%] md:ml-[30%]">
            <div className="w-full flex justify-between gap-2 pb-3 md:mt-0 mt-4">
              <div className="w-2/3 p-2 bg-gray-100 ml-4 border border-gray-200 rounded-md ">
                <p>Driver</p>
                <h3 className="font-bold md:text-[16px] text-[14px] ">
                  Michael John
                </h3>
              </div>
              <div className="w-2/3 p-2 bg-gray-100 border border-gray-200 rounded-md">
                <p>Vehicle</p>
                <h3 className="font-bold md:text-[16px] text-[14px]">
                  SV65166
                </h3>
              </div>
              <div className="w-2/3 p-2 bg-gray-100 border border-gray-200 rounded-md">
                <p>Customer</p>
                <h3 className="font-bold md:text-[16px] text-[14px]">
                  Coca Cola AS
                </h3>
              </div>
            </div>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={initialCenter}
              zoom={4}
              onLoad={onLoad}
              options={{
                streetViewControl: true,
                mapTypeControl: true,
                styles: isDarkMode ? customStyles : null,
              }}
            >
              {locationData.map((location) => {
                if (location.location) {
                  const { latitude, longitude } = location.location;
                  return (
                    <Marker
                      key={location.id}
                      position={{ lat: latitude, lng: longitude }}
                      icon={customIcon}
                      onClick={() => handleMarkerClick(location)}
                    />
                  );
                }
                return null;
              })}

              {directions && <DirectionsRenderer directions={directions} />}

              {selectedLocation && selectedLocation.location && (
                <OverlayView
                  position={{
                    lat: selectedLocation.location.latitude,
                    lng: selectedLocation.location.longitude,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div
                    className="bg-white p-4 rounded-lg shadow-lg absolute left-1 top-3 "
                    style={{ minWidth: "150px" }}
                  >
                    <h5 className="font-bold ">
                      {selectedLocation.firstName} {selectedLocation.lastName}
                    </h5>
                    <p>Status:{selectedLocation.status}</p>
                    <p>Type: {selectedLocation.type}</p>
                    <button
                      onClick={closeInfoBox}
                      className="text-red-500 font-bold mt-2"
                    >
                      Close
                    </button>
                  </div>
                </OverlayView>
              )}
            </GoogleMap>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

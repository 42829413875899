import type { FC } from "react";
import { StyledSelect } from ".";
import { countries } from "countries-list"; 

interface SelectCountryProps {
    id: string;
    defaultValue?: string;
  }

export const SelectCountry: FC<SelectCountryProps> = function(props: SelectCountryProps) {
  return (
    <StyledSelect 
        id={props.id}
        defaultValue={props.defaultValue}
        options={
            Object.keys(countries).map((code) => ({ key: code, label: countries[code as keyof typeof countries].name }))
        }
    />
  );
};

export default SelectCountry;
import type { FC } from "react";

export const NewMessageIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
      <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
    </svg>
  );
};

export const NewFollowIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"></path>
    </svg>
  );
};

export const NewLoveIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const NewMentionIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const NewVideoIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
    </svg>
  );
};

export const ClipboardIcon = (
  <svg
    className="w-[24px] h-[24px]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    fill="none"
    viewBox="0 0 18 20"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="2"
      d="M12 2h4a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4m6 0v3H6V2m6 0a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1M5 5h8m-5 5h5m-8 0h.01M5 14h.01M8 14h5"
    />
  </svg>
);

export const UserIcon = (
  <svg
    className="w-[24px] h-[24px]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 18"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
    />
  </svg>
);

export const MapPinIcon = (
  <svg
    className="w-[24px] h-[24px]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 21"
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M8 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
      <path d="M13.8 12.938h-.01a7 7 0 1 0-11.465.144h-.016l.141.17c.1.128.2.252.3.372L8 20l5.13-6.248c.193-.209.373-.429.54-.66l.13-.154Z" />
    </g>
  </svg>
);

export const PhoneIcon = (
  <svg
    className="w-[24px] h-[24px]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z"
    />
  </svg>
);

export const TruckIcon = (
  <svg
    className="w-[24px] h-[24px]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15.5 10.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm0 0a2.225 2.225 0 0 0-1.666.75H12m3.5-.75a2.225 2.225 0 0 1 1.666.75H19V7m-7 4V3h5l2 4m-7 4H6.166a2.225 2.225 0 0 0-1.666-.75M12 11V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v9h1.834a2.225 2.225 0 0 1 1.666-.75M19 7h-6m-8.5 3.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
    />
  </svg>
);

export const UnderProgress: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1767_26498)">
        <path
          d="M18.1268 14.2695C16.4586 14.2695 15.1016 15.6266 15.1016 17.2947C15.1016 18.9629 16.4586 20.32 18.1268 20.32C19.7952 20.32 21.152 18.9629 21.152 17.2947C21.152 15.6266 19.7949 14.2695 18.1268 14.2695ZM18.1268 18.8074C17.2926 18.8074 16.6142 18.1289 16.6142 17.2947C16.6142 16.4606 17.2926 15.7821 18.1268 15.7821C18.961 15.7821 19.6394 16.4606 19.6394 17.2947C19.6394 18.129 18.961 18.8074 18.1268 18.8074Z"
          fill="#7214FF"
        />
        <path
          d="M7.79084 14.2695C6.1227 14.2695 4.76562 15.6266 4.76562 17.2947C4.76562 18.9629 6.1227 20.32 7.79084 20.32C9.45898 20.32 10.8161 18.9629 10.8161 17.2947C10.8161 15.6266 9.45898 14.2695 7.79084 14.2695ZM7.79084 18.8074C6.95666 18.8074 6.27823 18.1289 6.27823 17.2947C6.27823 16.4606 6.95666 15.7821 7.79084 15.7821C8.6248 15.7821 9.30345 16.4606 9.30345 17.2947C9.30345 18.129 8.62503 18.8074 7.79084 18.8074Z"
          fill="#7214FF"
        />
        <path
          d="M20.163 5.6118C20.0344 5.35642 19.773 5.19531 19.4871 5.19531H15.5039V6.70792H19.0207L21.0801 10.804L22.4319 10.1244L20.163 5.6118Z"
          fill="#7214FF"
        />
        <path
          d="M15.8555 16.5625H10.1328V18.0751H15.8555V16.5625Z"
          fill="#7214FF"
        />
        <path
          d="M5.52262 16.5625H2.90081C2.48306 16.5625 2.14453 16.9011 2.14453 17.3188C2.14453 17.7365 2.48311 18.0751 2.90081 18.0751H5.52267C5.94042 18.0751 6.27895 17.7365 6.27895 17.3188C6.27895 16.901 5.94038 16.5625 5.52262 16.5625Z"
          fill="#7214FF"
        />
        <path
          d="M23.8428 11.9385L22.3552 10.0225C22.2123 9.83798 21.9917 9.73008 21.758 9.73008H16.2622V4.43597C16.2622 4.01822 15.9236 3.67969 15.5059 3.67969H2.90081C2.48306 3.67969 2.14453 4.01827 2.14453 4.43597C2.14453 4.85367 2.48311 5.19225 2.90081 5.19225H14.7495V10.4864C14.7495 10.9041 15.0881 11.2426 15.5058 11.2426H21.3876L22.489 12.6615V16.562H20.3966C19.9788 16.562 19.6403 16.9005 19.6403 17.3182C19.6403 17.736 19.9789 18.0745 20.3966 18.0745H23.2453C23.6631 18.0745 24.0016 17.736 24.0016 17.3182V12.4024C24.0016 12.2345 23.9457 12.0711 23.8428 11.9385Z"
          fill="#7214FF"
        />
        <path
          d="M5.46967 12.7305H1.99066C1.57291 12.7305 1.23438 13.069 1.23438 13.4868C1.23438 13.9045 1.57295 14.243 1.99066 14.243H5.46962C5.88737 14.243 6.22591 13.9045 6.22591 13.4868C6.22595 13.069 5.88737 12.7305 5.46967 12.7305Z"
          fill="#7214FF"
        />
        <path
          d="M7.21008 9.75781H0.756281C0.338578 9.75781 0 10.0964 0 10.5141C0 10.9319 0.338578 11.2704 0.756281 11.2704H7.21008C7.62783 11.2704 7.96636 10.9318 7.96636 10.5141C7.96636 10.0964 7.62783 9.75781 7.21008 9.75781Z"
          fill="#7214FF"
        />
        <path
          d="M8.44445 6.78125H1.99066C1.57291 6.78125 1.23438 7.11983 1.23438 7.53753C1.23438 7.95528 1.57295 8.29381 1.99066 8.29381H8.44445C8.8622 8.29381 9.20073 7.95523 9.20073 7.53753C9.20078 7.11983 8.8622 6.78125 8.44445 6.78125Z"
          fill="#7214FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1767_26498">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const WaitingOrders: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1767_26478)">
        <path
          d="M14.4242 21.857C14.2263 21.9063 14.0245 21.9501 13.8242 21.987C13.3007 22.0843 12.9546 22.5878 13.0514 23.1118C13.0992 23.3696 13.2456 23.5844 13.4444 23.7245C13.6493 23.8687 13.91 23.9338 14.1757 23.8844C14.4142 23.8402 14.6546 23.7881 14.8905 23.7292C15.4074 23.6006 15.7224 23.0768 15.5934 22.5601C15.4648 22.0429 14.9415 21.7282 14.4242 21.857Z"
          fill="#FCBD24"
        />
        <path
          d="M21.5573 8.92779C21.6249 9.13147 21.754 9.29775 21.9169 9.41264C22.1586 9.58284 22.4748 9.6401 22.7765 9.54032C23.2824 9.37235 23.5565 8.82683 23.3892 8.32118C23.3129 8.09068 23.2283 7.85945 23.1382 7.63431C22.9403 7.13955 22.3791 6.89867 21.8841 7.09658C21.3896 7.29438 21.1486 7.85573 21.3467 8.35064C21.4224 8.53988 21.4933 8.73412 21.5573 8.92779Z"
          fill="#FCBD24"
        />
        <path
          d="M17.5604 20.4803C17.3902 20.5927 17.2145 20.7014 17.0375 20.8033C16.5759 21.0697 16.4177 21.6598 16.6839 22.1213C16.7562 22.2468 16.8525 22.3494 16.9634 22.4278C17.261 22.6372 17.6652 22.6686 18.0017 22.4747C18.2121 22.3534 18.4213 22.2243 18.624 22.0903C19.0684 21.7967 19.1907 21.1981 18.897 20.7535C18.6033 20.3087 18.0049 20.1865 17.5604 20.4803Z"
          fill="#FCBD24"
        />
        <path
          d="M23.9914 11.6223C23.9704 11.0898 23.5218 10.6753 22.9892 10.6961C22.4572 10.7171 22.0424 11.1657 22.0633 11.6981C22.0713 11.9016 22.0733 12.1082 22.0686 12.3115C22.0611 12.6454 22.2245 12.9431 22.4783 13.122C22.6295 13.2285 22.813 13.2929 23.012 13.2975C23.5445 13.3093 23.9858 12.8869 23.9977 12.3541C24.003 12.1112 24.001 11.8651 23.9914 11.6223Z"
          fill="#FCBD24"
        />
        <path
          d="M21.402 17.9433C20.975 17.623 20.3709 17.7099 20.0511 18.1362C19.9286 18.2994 19.7996 18.4606 19.6675 18.6159C19.3224 19.0214 19.3712 19.6305 19.7767 19.9759C19.7998 19.9955 19.8231 20.0135 19.8474 20.0304C20.2505 20.3146 20.8108 20.2493 21.1367 19.8668C21.2945 19.6816 21.4482 19.489 21.5946 19.294C21.9144 18.8678 21.8279 18.2633 21.402 17.9433Z"
          fill="#FCBD24"
        />
        <path
          d="M22.8218 14.4546C22.3134 14.2952 21.7719 14.5782 21.6126 15.0866C21.5516 15.2809 21.484 15.476 21.4111 15.6672C21.2509 16.088 21.4044 16.5509 21.7562 16.799C21.8208 16.8444 21.8919 16.8828 21.9691 16.912C22.4669 17.102 23.0243 16.8522 23.2141 16.3542C23.3007 16.127 23.3811 15.8948 23.4538 15.6638C23.6129 15.1553 23.3301 14.614 22.8218 14.4546Z"
          fill="#FCBD24"
        />
        <path
          d="M10.2149 21.9973C9.35229 21.8424 8.52483 21.5786 7.74019 21.2108C7.7309 21.206 7.72259 21.2004 7.71284 21.196C7.52794 21.109 7.34335 21.0157 7.16443 20.9183C7.16381 20.9176 7.16268 20.9172 7.1617 20.9167C6.83343 20.736 6.51305 20.5359 6.2018 20.3168C1.66326 17.1198 0.572018 10.8263 3.7693 6.28783C4.46454 5.30131 5.30588 4.47829 6.24385 3.82597C6.25541 3.81792 6.26696 3.80993 6.27841 3.80183C9.58368 1.52441 14.0717 1.37093 17.5814 3.71118L16.8276 4.80031C16.618 5.10346 16.7469 5.32437 17.1138 5.29135L20.3881 4.99822C20.7554 4.9652 20.9751 4.64745 20.8763 4.29271L19.9971 1.12473C19.8986 0.769584 19.6466 0.727073 19.4368 1.03017L18.6813 2.12188C16.1057 0.39292 13.0165 -0.266358 9.95028 0.265336C9.64146 0.318784 9.33697 0.384201 9.03666 0.460349C9.03434 0.460762 9.03248 0.46102 9.03063 0.461432C9.01902 0.46427 9.00725 0.468036 8.99596 0.471183C6.35193 1.15017 4.04505 2.69227 2.39895 4.89251C2.38507 4.90896 2.37078 4.92506 2.35768 4.94296C2.30294 5.01669 2.24861 5.09211 2.19542 5.16754C2.10844 5.29115 2.0227 5.41786 1.94067 5.54456C1.9304 5.55983 1.92256 5.57536 1.91358 5.59079C0.5552 7.69574 -0.100002 10.1293 0.0123622 12.6068C0.0126202 12.6149 0.0121558 12.6231 0.0123622 12.6315C0.0232478 12.8735 0.0424396 13.1189 0.0684929 13.3604C0.0698858 13.376 0.0733424 13.3907 0.0759736 13.4063C0.102904 13.6492 0.136696 13.8927 0.179052 14.1361C0.609525 16.6195 1.78105 18.8543 3.53652 20.5932C3.5406 20.5972 3.54483 20.6017 3.54896 20.6059C3.5504 20.6075 3.552 20.6083 3.55339 20.6098C4.02504 21.075 4.53805 21.5053 5.09044 21.8943C6.53601 22.9129 8.14569 23.5862 9.87439 23.8965C10.3989 23.9907 10.9 23.6416 10.9941 23.1173C11.0881 22.5927 10.7393 22.0912 10.2149 21.9973Z"
          fill="#FCBD24"
        />
        <path
          d="M11.4098 4.28906C10.9784 4.28906 10.6289 4.63885 10.6289 5.06968V12.8468L17.7417 16.5237C17.8562 16.583 17.9788 16.6109 18.0994 16.6109C18.3819 16.6109 18.6548 16.4571 18.7935 16.1887C18.9913 15.8056 18.8416 15.3349 18.4585 15.137L12.1899 11.8962V5.06968C12.1898 4.63885 11.8408 4.28906 11.4098 4.28906Z"
          fill="#FCBD24"
        />
      </g>
      <defs>
        <clipPath id="clip0_1767_26478">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CompletedOrders: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 0C5.38324 0 0 5.38316 0 12C0 18.6168 5.38324 24 12.0002 24C18.617 24 24.0002 18.6168 24.0002 12C24.0002 5.38316 18.617 0 12.0002 0ZM12.0002 22.0328C6.46796 22.0328 1.96721 17.5322 1.96721 12C1.96721 6.46788 6.46796 1.96721 12.0002 1.96721C17.5323 1.96721 22.0329 6.46788 22.0329 12C22.0329 17.5322 17.5323 22.0328 12.0002 22.0328Z"
        fill="#0AAF87"
      />
      <path
        d="M17.1905 7.39352L10.0656 14.5185L6.80802 11.261C6.42386 10.8769 5.80112 10.8769 5.41697 11.261C5.03289 11.6451 5.03289 12.2679 5.41697 12.652L9.37004 16.6051C9.56212 16.7971 9.81384 16.8932 10.0656 16.8932C10.3173 16.8932 10.569 16.7971 10.7611 16.6051L18.5816 8.78465C18.9656 8.4005 18.9656 7.77775 18.5816 7.3936C18.1974 7.00944 17.5746 7.00944 17.1905 7.39352Z"
        fill="#0AAF87"
      />
    </svg>
  );
};

export const FailedOrders: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9961 24C10.4201 24.0029 8.85916 23.6943 7.40282 23.0921C5.94648 22.4898 4.62355 21.6056 3.50997 20.4905C-1.16999 15.8105 -1.16999 8.1962 3.50997 3.51625C4.62186 2.39811 5.94452 1.5116 7.40135 0.908044C8.85813 0.304482 10.4202 -0.00414032 11.9971 4.19436e-05C15.2031 4.19436e-05 18.2172 1.24829 20.4832 3.51625C22.7502 5.78327 23.9994 8.79727 23.9994 12.0034C23.9994 15.2095 22.7512 18.2235 20.4832 20.4905C19.3695 21.6056 18.0463 22.4898 16.5899 23.0921C15.1334 23.6943 13.5722 24.0029 11.9961 24ZM11.9971 1.92042C10.6724 1.91666 9.36022 2.17577 8.13643 2.68276C6.91264 3.18975 5.80159 3.93453 4.86768 4.87394C2.96362 6.77804 1.91509 9.31002 1.91509 12.0034C1.91509 14.6967 2.96362 17.2278 4.86768 19.1328C8.79868 23.0638 15.1955 23.0648 19.1256 19.1328C21.0296 17.2287 22.0791 14.6967 22.0791 12.0034C22.0791 9.31002 21.0305 6.77896 19.1256 4.87394C18.1916 3.9348 17.0807 3.19022 15.8571 2.68325C14.6335 2.17628 13.3216 1.917 11.9971 1.92042Z"
        fill="#FE2F67"
      />
      <path
        d="M7.92229 17.0361C7.73221 17.0366 7.54626 16.9805 7.38811 16.8751C7.22996 16.7697 7.10667 16.6196 7.03395 16.444C6.96118 16.2684 6.94231 16.0751 6.97957 15.8887C7.01687 15.7023 7.10873 15.5312 7.24345 15.3971L15.3906 7.24992C15.4798 7.16079 15.5856 7.09008 15.7021 7.04179C15.8185 6.99355 15.9434 6.96875 16.0695 6.96875C16.1955 6.96875 16.3204 6.99355 16.4369 7.04179C16.5534 7.09008 16.6592 7.16079 16.7483 7.24992C16.8375 7.33906 16.9082 7.4449 16.9565 7.56139C17.0047 7.67788 17.0296 7.8027 17.0296 7.92877C17.0296 8.05483 17.0047 8.1797 16.9565 8.2962C16.9082 8.41264 16.8375 8.51847 16.7483 8.60761L8.60113 16.7549C8.51221 16.8443 8.40643 16.9152 8.28988 16.9635C8.17339 17.0118 8.04841 17.0365 7.92229 17.0361Z"
        fill="#FE2F67"
      />
      <path
        d="M16.0721 17.0361C15.946 17.0363 15.8211 17.0115 15.7046 16.9632C15.5881 16.915 15.4823 16.8441 15.3933 16.7549L7.24602 8.60761C7.15688 8.51847 7.08618 8.41264 7.03794 8.2962C6.9897 8.1797 6.96484 8.05483 6.96484 7.92877C6.96484 7.8027 6.9897 7.67788 7.03794 7.56139C7.08618 7.4449 7.15688 7.33906 7.24602 7.24992C7.33521 7.16079 7.44105 7.09008 7.55749 7.04179C7.67398 6.99355 7.79885 6.96875 7.92491 6.96875C8.05098 6.96875 8.1758 6.99355 8.29229 7.04179C8.40878 7.09008 8.51462 7.16079 8.60376 7.24992L16.751 15.3971C16.8857 15.5312 16.9775 15.7023 17.0148 15.8887C17.0521 16.0751 17.0332 16.2684 16.9604 16.444C16.8877 16.6196 16.7644 16.7697 16.6063 16.8751C16.4481 16.9805 16.2622 17.0366 16.0721 17.0361Z"
        fill="#FE2F67"
      />
    </svg>
  );
};

export const WorkSpaceIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1855_27091)">
        <path
          d="M13.0196 4.78305H7.24706L6.09882 3.41834C6.0602 3.37191 6.00231 3.34587 5.94196 3.34775H1.28471C0.573647 3.35293 0 3.93081 0 4.64187V13.0356C0.000862745 13.7491 0.579059 14.3273 1.29255 14.3281H13.0196C13.7331 14.3273 14.3113 13.7491 14.3122 13.0356V6.07559C14.3113 5.3621 13.7331 4.78391 13.0196 4.78305Z"
          fill="#0AAF87"
        />
        <path
          d="M14.7165 3.10726H8.94234L7.79411 1.74256C7.75548 1.69613 7.6976 1.67009 7.63724 1.67197H2.98156C2.33638 1.67303 1.79081 2.14966 1.70312 2.78883H5.9494C6.17207 2.78828 6.38348 2.88656 6.52665 3.05707L7.50705 4.22256H13.0208C14.0406 4.22428 14.8669 5.0506 14.8686 6.0704V12.6398C15.5152 12.5591 16.0007 12.0099 16.0012 11.3582V4.39824C15.9995 3.6884 15.4263 3.11244 14.7165 3.10726Z"
          fill="#0AAF87"
        />
      </g>
      <defs>
        <clipPath id="clip0_1855_27091">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const WorkSpaceIconCircle: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#7214FF" />
      <g clip-path="url(#clip0_6057_50)">
        <path
          d="M15.7647 9.58582H11.4353L10.5741 8.56229C10.5451 8.52747 10.5017 8.50794 10.4565 8.50935H6.96353C6.43024 8.51323 6 8.94664 6 9.47994V15.7752C6.00065 16.3103 6.43429 16.744 6.96941 16.7446H15.7647C16.2998 16.744 16.7335 16.3103 16.7341 15.7752V10.5552C16.7335 10.0201 16.2998 9.58647 15.7647 9.58582Z"
          fill="white"
        />
        <path
          d="M17.0373 8.33191H12.7068L11.8456 7.30838C11.8166 7.27356 11.7732 7.25403 11.7279 7.25544H8.23617C7.75228 7.25624 7.34311 7.61371 7.27734 8.09309H10.462C10.629 8.09268 10.7876 8.16638 10.895 8.29427L11.6303 9.16838H15.7656C16.5304 9.16968 17.1502 9.78941 17.1515 10.5543V15.4813C17.6364 15.4208 18.0005 15.0089 18.0009 14.5201V9.30015C17.9996 8.76777 17.5697 8.3358 17.0373 8.33191Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6057_50">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const UserProfile: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1859_26710)">
        <path
          d="M8 0C5.67378 0 3.78125 1.89253 3.78125 4.21875C3.78125 6.54497 5.67378 8.4375 8 8.4375C10.3262 8.4375 12.2188 6.54497 12.2188 4.21875C12.2188 1.89253 10.3262 0 8 0Z"
          fill="#6B7280"
        />
        <path
          d="M13.2489 11.1936C12.0939 10.0208 10.5628 9.375 8.9375 9.375H7.0625C5.43725 9.375 3.90606 10.0208 2.75106 11.1936C1.60172 12.3606 0.96875 13.901 0.96875 15.5312C0.96875 15.7901 1.17862 16 1.4375 16H14.5625C14.8214 16 15.0312 15.7901 15.0312 15.5312C15.0312 13.901 14.3983 12.3606 13.2489 11.1936Z"
          fill="#6B7280"
        />
      </g>
      <defs>
        <clipPath id="clip0_1859_26710">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CopyIcon: React.FC<
  React.SVGProps<SVGSVGElement> & { onClick?: () => void }
> = ({ onClick, ...props }) => {
  return (
    <svg
      height="512pt"
      viewBox="-40 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick} // Add onClick event
      {...props} // Spread other SVG props
    >
      <path d="m271 512h-191c-44.113281 0-80-35.886719-80-80v-271c0-44.113281 35.886719-80 80-80h191c44.113281 0 80 35.886719 80 80v271c0 44.113281-35.886719 80-80 80zm-191-391c-22.054688 0-40 17.945312-40 40v271c0 22.054688 17.945312 40 40 40h191c22.054688 0 40-17.945312 40-40v-271c0-22.054688-17.945312-40-40-40zm351 261v-302c0-44.113281-35.886719-80-80-80h-222c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h222c22.054688 0 40 17.945312 40 40v302c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm0 0" />
    </svg>
  );
};

export const PenIcon: React.FC<
  React.SVGProps<SVGSVGElement> & { className?: string }
> = ({ className, ...props }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.51176 3.00943C9.64091 2.87572 9.79539 2.76907 9.9662 2.69569C10.137 2.62232 10.3207 2.5837 10.5066 2.58208C10.6925 2.58047 10.8768 2.61589 11.0489 2.68628C11.221 2.75668 11.3773 2.86063 11.5087 2.99208C11.6402 3.12353 11.7441 3.27984 11.8145 3.4519C11.8849 3.62395 11.9203 3.80831 11.9187 3.9942C11.9171 4.18009 11.8785 4.3638 11.8051 4.5346C11.7317 4.70541 11.6251 4.85989 11.4914 4.98903L10.9363 5.54413L8.95666 3.56453L9.51176 3.00943V3.00943ZM7.96686 4.55433L2.10156 10.4196V12.3992H4.08116L9.94716 6.53393L7.96616 4.55433H7.96686Z"
        fill="white"
      />
    </svg>
  );
};

export const UserAvatar: React.FC<
  React.SVGProps<SVGSVGElement> & { className?: string }
> = ({ className, ...props }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className} // Add the className prop
      {...props} // Spread other SVG props
    >
      <path
        d="M40 78.75C61.401 78.75 78.75 61.401 78.75 40C78.75 18.599 61.401 1.25 40 1.25C18.599 1.25 1.25 18.599 1.25 40C1.25 61.401 18.599 78.75 40 78.75Z"
        fill="#E5D4FF"
      />
      <path
        d="M71.097 63.0935C67.483 58.2551 62.7903 54.3263 57.3918 51.6193C51.9933 48.9124 46.0378 47.5019 39.9987 47.5C33.9595 47.4981 28.0032 48.9048 22.603 51.6084C17.2028 54.312 12.5076 58.2378 8.89062 63.074C12.4892 67.9347 17.1767 71.8846 22.5773 74.6069C27.9778 77.3292 33.941 78.7481 39.9889 78.75C46.0368 78.7519 52.0009 77.3367 57.4031 74.6178C62.8054 71.8989 67.4953 67.952 71.097 63.0935Z"
        fill="#7214FF"
      />
      <path
        d="M40 42.5C48.2843 42.5 55 35.7843 55 27.5C55 19.2157 48.2843 12.5 40 12.5C31.7157 12.5 25 19.2157 25 27.5C25 35.7843 31.7157 42.5 40 42.5Z"
        fill="#7214FF"
      />
    </svg>
  );
};

export const VerifiedBadge: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1254 6.67117C13.9088 6.5453 13.7143 6.38497 13.5494 6.1965C13.566 5.93411 13.6285 5.67667 13.734 5.43584C13.928 4.8885 14.1474 4.2685 13.7954 3.7865C13.4434 3.3045 12.7787 3.31984 12.1954 3.33317C11.9376 3.35969 11.6772 3.34211 11.4254 3.28117C11.2912 3.06284 11.1954 2.82316 11.142 2.5725C10.9767 2.00917 10.788 1.3725 10.2087 1.18184C9.65003 1.00184 9.1327 1.39784 8.67537 1.74584C8.47803 1.92605 8.24937 2.06862 8.0007 2.1665C7.74943 2.06942 7.51823 1.92681 7.3187 1.74584C6.8627 1.39984 6.34737 0.999837 5.78603 1.1825C5.20803 1.3705 5.01936 2.00917 4.8527 2.5725C4.79945 2.82235 4.70457 3.06146 4.57203 3.27984C4.31971 3.34061 4.05898 3.35864 3.8007 3.33317C3.21536 3.31717 2.55603 3.29984 2.2007 3.7865C1.84536 4.27317 2.06736 4.8885 2.26203 5.43517C2.36899 5.67564 2.43242 5.93319 2.44936 6.19584C2.28475 6.38455 2.09041 6.54511 1.87403 6.67117C1.38603 7.0045 0.832031 7.38384 0.832031 7.99984C0.832031 8.61584 1.38603 8.99384 1.87403 9.3285C2.09036 9.45436 2.2847 9.61469 2.44936 9.80317C2.4342 10.0657 2.37258 10.3235 2.26736 10.5645C2.07403 11.1112 1.85536 11.7312 2.2067 12.2132C2.55803 12.6952 3.2207 12.6798 3.8067 12.6665C4.06468 12.64 4.32529 12.6575 4.57736 12.7185C4.71095 12.9371 4.80653 13.1767 4.86003 13.4272C5.02536 13.9905 5.21403 14.6272 5.79336 14.8178C5.88625 14.8476 5.98316 14.8629 6.0807 14.8632C6.5495 14.7959 6.98522 14.5827 7.32603 14.2538C7.52337 14.0736 7.75203 13.9311 8.0007 13.8332C8.25197 13.9303 8.48317 14.0729 8.6827 14.2538C9.13936 14.6025 9.6567 15.0005 10.216 14.8172C10.794 14.6292 10.9827 13.9905 11.1494 13.4278C11.2028 13.1775 11.2984 12.9381 11.432 12.7198C11.6834 12.6586 11.9433 12.6406 12.2007 12.6665C12.786 12.6805 13.4454 12.6998 13.8007 12.2132C14.156 11.7265 13.934 11.1112 13.7394 10.5638C13.6331 10.3236 13.5697 10.0666 13.552 9.8045C13.7167 9.61562 13.9113 9.45504 14.128 9.32917C14.616 8.99584 15.17 8.61584 15.17 7.99984C15.17 7.38384 14.614 7.00517 14.1254 6.67117Z"
        fill="#7214FF"
      />
      <path
        d="M7.33258 9.83203C7.26691 9.83216 7.20187 9.81926 7.14123 9.79408C7.08058 9.76891 7.02552 9.73196 6.97925 9.68537L5.64591 8.35203C5.55759 8.25725 5.50951 8.13189 5.5118 8.00235C5.51408 7.87282 5.56656 7.74923 5.65817 7.65762C5.74977 7.56601 5.87336 7.51354 6.0029 7.51125C6.13243 7.50896 6.2578 7.55705 6.35258 7.64537L7.37925 8.67203L9.69925 6.93203C9.80533 6.85247 9.93868 6.8183 10.07 6.83706C10.2012 6.85581 10.3197 6.92595 10.3992 7.03203C10.4788 7.13812 10.513 7.27147 10.4942 7.40274C10.4755 7.53402 10.4053 7.65247 10.2992 7.73203L7.63258 9.73203C7.54601 9.7969 7.44076 9.83199 7.33258 9.83203Z"
        fill="white"
      />
    </svg>
  );
};

export const NotVerifiedBadge: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1849_26645)">
        <path
          d="M15.5897 12.9806L3.05051 0.441403C2.50364 -0.105468 1.61703 -0.105468 1.07101 0.441403L0.410153 1.10124C-0.136718 1.64828 -0.136718 2.53489 0.410153 3.08091L12.9494 15.6201C13.4964 16.167 14.383 16.167 14.929 15.6201L15.5889 14.9603C16.1368 14.4143 16.1368 13.5275 15.5897 12.9806Z"
          fill="#FE2F67"
        />
        <path
          d="M12.9494 0.441275L0.410153 12.9805C-0.136718 13.5274 -0.136718 14.4142 0.410153 14.9602L1.06999 15.62C1.61703 16.1669 2.50364 16.1669 3.04966 15.62L15.5897 3.08163C16.1368 2.53476 16.1368 1.64815 15.5897 1.10213L14.9299 0.4423C14.383 -0.105596 13.4964 -0.105596 12.9494 0.441275Z"
          fill="#FE2F67"
        />
      </g>
      <defs>
        <clipPath id="clip0_1849_26645">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const dashboardIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1688_24612)">
        <path
          d="M14.7086 0H9.96891C9.25473 0 8.67578 0.578952 8.67578 1.29313V6.03281C8.67578 6.74699 9.25473 7.32594 9.96891 7.32594H14.7086C15.4228 7.32594 16.0017 6.74699 16.0017 6.03281V1.29313C16.0017 0.578952 15.4228 0 14.7086 0Z"
          fill="#7214FF"
        />
        <path
          d="M6.03281 0H1.29313C0.578952 0 0 0.578952 0 1.29313V6.03281C0 6.74699 0.578952 7.32594 1.29313 7.32594H6.03281C6.74699 7.32594 7.32594 6.74699 7.32594 6.03281V1.29313C7.32594 0.578952 6.74699 0 6.03281 0Z"
          fill="#7214FF"
        />
        <path
          d="M14.7086 8.67578H9.96891C9.25473 8.67578 8.67578 9.25473 8.67578 9.96891V14.7086C8.67578 15.4228 9.25473 16.0017 9.96891 16.0017H14.7086C15.4228 16.0017 16.0017 15.4228 16.0017 14.7086V9.96891C16.0017 9.25473 15.4228 8.67578 14.7086 8.67578Z"
          fill="#7214FF"
        />
        <path
          d="M6.03281 8.67578H1.29313C0.578952 8.67578 0 9.25473 0 9.96891V14.7086C0 15.4228 0.578952 16.0017 1.29313 16.0017H6.03281C6.74699 16.0017 7.32594 15.4228 7.32594 14.7086V9.96891C7.32594 9.25473 6.74699 8.67578 6.03281 8.67578Z"
          fill="#7214FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1688_24612">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const orderIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49877 4V0.104C5.09215 0.211983 4.72117 0.41829 4.4213 0.7032L2.0647 2.9656C1.76852 3.25391 1.55372 3.60991 1.44056 4H5.49877Z"
        fill="#DF2DB1"
      />
      <path
        d="M13.0535 0H7.16539V4C7.16539 4.42435 6.9898 4.83131 6.67725 5.13137C6.3647 5.43143 5.94079 5.6 5.49877 5.6H1.33223V14.4C1.32554 14.8172 1.49143 15.2199 1.79354 15.5198C2.09565 15.8197 2.50932 15.9924 2.94385 16H13.0535C13.4881 15.9924 13.9017 15.8197 14.2039 15.5198C14.506 15.2199 14.6719 14.8172 14.6652 14.4V1.6C14.6719 1.18282 14.506 0.780122 14.2039 0.48019C13.9017 0.180259 13.4881 0.00757502 13.0535 0Z"
        fill="#DF2DB1"
      />
    </svg>
  );
};

export const administrationIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1688_24642)">
        <path
          d="M9.93437 15.3032C9.88125 15.2719 9.825 15.2407 9.77188 15.2094L9.44375 15.35C8.8 15.625 8.05312 15.375 7.70312 14.7688L7.14062 13.7938C6.79063 13.1875 6.95 12.4157 7.50938 11.9938L7.79375 11.7813C7.79375 11.75 7.79375 11.7188 7.79375 11.6875C7.79375 11.6563 7.79375 11.625 7.79375 11.5938L7.50938 11.3813C6.95 10.9594 6.79375 10.1875 7.14062 9.58129L7.70312 8.60629C7.87188 8.31566 8.1375 8.09691 8.45312 7.99066C7.675 7.63441 6.82813 7.45004 5.97188 7.45316C2.675 7.45004 0 10.125 0 13.4219V15.1532C0 15.6219 0.378125 16 0.846875 16H10.0625C10.0188 15.8907 9.99063 15.775 9.975 15.6563L9.93437 15.3032Z"
          fill="#41E88D"
        />
        <path
          d="M5.97344 6.55625C7.7839 6.55625 9.25156 5.08858 9.25156 3.27813C9.25156 1.46767 7.7839 0 5.97344 0C4.16298 0 2.69531 1.46767 2.69531 3.27813C2.69531 5.08858 4.16298 6.55625 5.97344 6.55625Z"
          fill="#41E88D"
        />
        <path
          d="M15.798 12.6945L15.1261 12.1914C15.1792 11.8602 15.1792 11.5195 15.1261 11.1883L15.798 10.6852C16.0042 10.532 16.0605 10.2477 15.9324 10.0227L15.3699 9.04766C15.2417 8.82578 14.9667 8.73203 14.7292 8.83516L13.9574 9.16328C13.6949 8.95078 13.4011 8.78203 13.0886 8.66016L12.9886 7.82578C12.9574 7.56953 12.7417 7.37891 12.4824 7.37891H11.3542C11.098 7.37891 10.8792 7.57266 10.848 7.82578L10.748 8.66016C10.4324 8.78203 10.1417 8.95078 9.87923 9.16328L9.10736 8.83516C8.86986 8.73516 8.59486 8.82578 8.46673 9.04766L7.90423 10.0227C7.77611 10.2445 7.83236 10.5289 8.03861 10.6852L8.71048 11.1883C8.65736 11.5195 8.65736 11.8602 8.71048 12.1914L8.03861 12.6945C7.83236 12.8508 7.77611 13.1352 7.90423 13.357L8.46673 14.332C8.59486 14.5539 8.86986 14.6477 9.10736 14.5477L9.87923 14.2164C10.1417 14.4289 10.4355 14.5977 10.748 14.7195L10.848 15.5539C10.8792 15.8102 11.0949 16.0008 11.3542 16.0008H12.4824C12.7386 16.0008 12.9574 15.807 12.9886 15.5539L13.0886 14.7195C13.4011 14.5977 13.6949 14.4289 13.9574 14.2164L14.7292 14.5445C14.9667 14.6445 15.2417 14.5539 15.3699 14.3289L15.9324 13.3539C16.0605 13.1352 16.0042 12.8508 15.798 12.6945ZM11.9167 12.907C11.2449 12.907 10.698 12.3602 10.698 11.6883C10.698 11.0164 11.2449 10.4695 11.9167 10.4695C12.5886 10.4695 13.1355 11.0164 13.1355 11.6883C13.1355 12.3602 12.5886 12.907 11.9167 12.907Z"
          fill="#41E88D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1688_24642">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const inventoryIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1698_24658)">
        <path
          d="M7.40438 7.40982L0.216875 4.38351C0.113906 4.34013 0 4.41576 0 4.52751V12.6181C0 12.8225 0.124469 13.0064 0.314312 13.0823L7.28572 15.8709C7.38834 15.9119 7.5 15.8364 7.5 15.7258V7.55382C7.5 7.49095 7.46231 7.43423 7.40438 7.40982Z"
          fill="#FE2F67"
        />
        <path
          d="M8.05874 6.54912L15.0061 3.62393C15.131 3.57137 15.1343 3.39562 15.0115 3.33834L8.20956 0.164086C8.07552 0.101555 7.92071 0.101555 7.78668 0.164086L0.984713 3.33834C0.861932 3.39562 0.865275 3.57137 0.99015 3.62393L7.93749 6.54912C7.97624 6.56546 8.01999 6.56546 8.05874 6.54912Z"
          fill="#FE2F67"
        />
        <path
          d="M8.5 7.55382V15.7258C8.5 15.8364 8.61166 15.9119 8.71428 15.8709L15.6857 13.0823C15.8755 13.0064 16 12.8225 16 12.6181V4.52751C16 4.41576 15.8861 4.34013 15.7831 4.38351L8.59562 7.40982C8.53769 7.43423 8.5 7.49095 8.5 7.55382Z"
          fill="#FE2F67"
        />
      </g>
      <defs>
        <clipPath id="clip0_1698_24658">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const mapIcon: FC = function () {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10_1101)">
        <path
          d="M4.00781 9.46069H3.29028C3.10205 9.46069 2.93213 9.57275 2.85767 9.74536L0.871338 14.3705L5.58862 11.9612C5.02393 11.1798 4.46521 10.318 4.00781 9.46069Z"
          fill="#06765F"
        />
        <path
          d="M13.1342 9.74536C13.0598 9.57275 12.8898 9.46069 12.7017 9.46069H11.9841C11.1204 11.0793 9.86194 12.7521 9.0614 13.6724C8.49841 14.3181 7.4928 14.3174 6.93066 13.6724C6.87317 13.6063 6.573 13.2588 6.15955 12.7244L5.30908 13.1589L8.52612 16.3698L14.6218 13.2096L13.1342 9.74536Z"
          fill="#06765F"
        />
        <path
          d="M0.291014 15.722L0.0344222 16.3196C-0.0983903 16.6288 0.129149 16.9741 0.466917 16.9741H7.37476C7.41797 16.942 7.41846 16.944 7.65039 16.8237L4.42859 13.6086L0.291014 15.722Z"
          fill="#06765F"
        />
        <path
          d="M15.9575 16.3196L14.9937 14.0752L9.40186 16.9741H15.525C15.8621 16.9741 16.0906 16.6294 15.9575 16.3196Z"
          fill="#06765F"
        />
        <path
          d="M8.46655 5.203C8.46655 4.94397 8.25537 4.7334 7.99597 4.7334C7.73645 4.7334 7.52539 4.94397 7.52539 5.203C7.52539 5.46191 7.73645 5.67261 7.99597 5.67261C8.25537 5.67261 8.46655 5.46191 8.46655 5.203Z"
          fill="#06765F"
        />
        <path
          d="M8.35107 13.0247C8.50952 12.8428 12.2311 8.53785 12.2311 5.57863C12.2311 -0.523426 3.76086 -0.597889 3.76086 5.57863C3.76086 8.53785 7.48242 12.8428 7.64087 13.0247C7.82849 13.2399 8.16382 13.2396 8.35107 13.0247ZM6.58423 5.20302C6.58423 4.42616 7.21753 3.7942 7.99597 3.7942C8.77429 3.7942 9.40759 4.42616 9.40759 5.20302C9.40759 5.97975 8.77429 6.61171 7.99597 6.61171C7.21753 6.61171 6.58423 5.97975 6.58423 5.20302Z"
          fill="#06765F"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_1101">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(-0.00402832 0.974121)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const customersIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1698_24654)">
        <path
          d="M5.2 7.57895C7.18823 7.57895 8.8 5.88234 8.8 3.78947C8.8 1.69661 7.18823 0 5.2 0C3.21177 0 1.6 1.69661 1.6 3.78947C1.6 5.88234 3.21177 7.57895 5.2 7.57895Z"
          fill="#FCBD24"
        />
        <path
          d="M6.4 8.42105H4C2.93952 8.42239 1.92285 8.86643 1.17298 9.65576C0.423106 10.4451 0.00127029 11.5153 0 12.6316V15.1579C0 15.3812 0.0842854 15.5954 0.234315 15.7534C0.384344 15.9113 0.587827 16 0.8 16H9.6C9.81217 16 10.0157 15.9113 10.1657 15.7534C10.3157 15.5954 10.4 15.3812 10.4 15.1579V12.6316C10.3987 11.5153 9.97689 10.4451 9.22702 9.65576C8.47715 8.86643 7.46048 8.42239 6.4 8.42105Z"
          fill="#FCBD24"
        />
        <path
          d="M8.1352 5.96884C8.56668 5.33281 8.79906 4.57102 8.8 3.78947C8.79828 3.51199 8.7677 3.23553 8.7088 2.96505C8.38299 3.37578 8.15987 3.8657 8.05969 4.39028C7.95952 4.91487 7.98547 5.4575 8.1352 5.96884Z"
          fill="#FCBD24"
        />
        <path
          d="M11.2 1.68421C10.8168 1.6873 10.4374 1.76348 10.08 1.90905C10.4091 2.84136 10.4881 3.85099 10.3085 4.82742C10.1289 5.80385 9.69749 6.70944 9.0616 7.44505C9.42044 7.65444 9.75655 7.90433 10.064 8.19032C10.426 8.34 10.8111 8.41821 11.2 8.42105C12.0487 8.42105 12.8626 8.06617 13.4627 7.43447C14.0629 6.80276 14.4 5.94599 14.4 5.05263C14.4 4.15927 14.0629 3.3025 13.4627 2.6708C12.8626 2.0391 12.0487 1.68421 11.2 1.68421Z"
          fill="#FCBD24"
        />
        <path
          d="M12 9.26316H10.9784C11.6412 10.248 11.9981 11.4247 12 12.6316V15.1579C11.9974 15.4454 11.9476 15.7303 11.8528 16H15.2C15.4122 16 15.6157 15.9113 15.7657 15.7534C15.9157 15.5954 16 15.3812 16 15.1579V13.4737C15.9987 12.3574 15.5769 11.2872 14.827 10.4979C14.0772 9.70853 13.0605 9.2645 12 9.26316Z"
          fill="#FCBD24"
        />
      </g>
      <defs>
        <clipPath id="clip0_1698_24654">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PickUpArrow: FC = function () {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.5" r="12" fill="#F1E8FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0093 6.125L7.06013 10.9736C6.64662 11.3787 6.64662 12.0415 7.06013 12.4466C7.47364 12.8517 8.15017 12.8517 8.56368 12.4466L13.5128 7.59799L12.0093 6.125Z"
        fill="#4629D1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0114 6.125L16.9605 10.9736C17.374 11.3787 17.374 12.0415 16.9605 12.4466C16.547 12.8517 15.8705 12.8517 15.4569 12.4466L10.5078 7.59799L12.0114 6.125Z"
        fill="#4629D1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9453 8.21484V17.0831C10.9453 17.656 11.4237 18.1247 12.0085 18.1247C12.5933 18.1247 13.0716 17.656 13.0716 17.0831V8.21484H10.9453Z"
        fill="#4629D1"
      />
    </svg>
  );
};

export const DeliveryArrow: FC = function () {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.5" r="12" fill="#E7F7F3" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0103 18.125L16.9594 13.2764C17.3729 12.8713 17.3729 12.2085 16.9594 11.8034C16.5459 11.3983 15.8694 11.3983 15.4559 11.8034L10.5067 16.652L12.0103 18.125Z"
        fill="#0AAF87"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0082 18.125L7.05905 13.2764C6.64554 12.8713 6.64554 12.2085 7.05905 11.8034C7.47256 11.3983 8.14908 11.3983 8.56259 11.8034L13.5117 16.652L12.0082 18.125Z"
        fill="#0AAF87"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0742 16.0352V7.16687C13.0742 6.59396 12.5958 6.12531 12.0111 6.12531C11.4263 6.12531 10.9479 6.59396 10.9479 7.16687V16.0352H13.0742Z"
        fill="#0AAF87"
      />
    </svg>
  );
};

export const DeliveryCompleted: FC = function () {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5002 0.5C5.88324 0.5 0.5 5.88316 0.5 12.5C0.5 19.1168 5.88324 24.5 12.5002 24.5C19.117 24.5 24.5002 19.1168 24.5002 12.5C24.5002 5.88316 19.117 0.5 12.5002 0.5ZM12.5002 22.5328C6.96796 22.5328 2.46721 18.0322 2.46721 12.5C2.46721 6.96788 6.96796 2.46721 12.5002 2.46721C18.0323 2.46721 22.5329 6.96788 22.5329 12.5C22.5329 18.0322 18.0323 22.5328 12.5002 22.5328Z"
        fill="#0AAF87"
      />
      <path
        d="M17.6905 7.89352L10.5656 15.0185L7.30802 11.761C6.92386 11.3769 6.30112 11.3769 5.91697 11.761C5.53289 12.1451 5.53289 12.7679 5.91697 13.152L9.87004 17.1051C10.0621 17.2971 10.3138 17.3932 10.5656 17.3932C10.8173 17.3932 11.069 17.2971 11.2611 17.1051L19.0816 9.28465C19.4656 8.9005 19.4656 8.27775 19.0816 7.8936C18.6974 7.50944 18.0746 7.50944 17.6905 7.89352Z"
        fill="#0AAF87"
      />
    </svg>
  );
};

export const SortArrows: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.49989 6.50002H12.4999C12.5988 6.5 12.6954 6.47066 12.7776 6.41572C12.8598 6.36078 12.9239 6.28269 12.9617 6.19134C12.9996 6.09999 13.0095 5.99948 12.9902 5.9025C12.9709 5.80553 12.9233 5.71644 12.8534 5.64652L8.35339 1.14652C8.30699 1.10007 8.25188 1.06322 8.19122 1.03808C8.13057 1.01294 8.06555 1 7.99989 1C7.93423 1 7.86922 1.01294 7.80856 1.03808C7.74791 1.06322 7.6928 1.10007 7.64639 1.14652L3.14639 5.64652C3.07649 5.71644 3.02889 5.80553 3.0096 5.9025C2.99032 5.99948 3.00022 6.09999 3.03805 6.19134C3.07589 6.28269 3.13996 6.36078 3.22216 6.41572C3.30437 6.47066 3.40102 6.5 3.49989 6.50002Z"
        fill="#BFC1CB"
      />
      <path
        d="M12.4999 9.5H3.49989C3.40102 9.50002 3.30437 9.52936 3.22216 9.5843C3.13996 9.63924 3.07589 9.71732 3.03805 9.80867C3.00022 9.90002 2.99032 10.0005 3.0096 10.0975C3.02889 10.1945 3.07649 10.2836 3.14639 10.3535L7.64639 14.8535C7.6928 14.8999 7.74791 14.9368 7.80857 14.9619C7.86922 14.9871 7.93424 15 7.99989 15C8.06555 15 8.13057 14.9871 8.19122 14.9619C8.25188 14.9368 8.30698 14.8999 8.35339 14.8535L12.8534 10.3535C12.9233 10.2836 12.9709 10.1945 12.9902 10.0975C13.0095 10.0005 12.9996 9.90002 12.9617 9.80867C12.9239 9.71732 12.8598 9.63924 12.7776 9.5843C12.6954 9.52936 12.5988 9.50002 12.4999 9.5Z"
        fill="#BFC1CB"
      />
    </svg>
  );
};

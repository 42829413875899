/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo, type FC, type PropsWithRef } from "react";
import {
  DarkThemeToggle,
  Label,
  Navbar,
  TextInput,
  Dropdown,
  Spinner,
} from "flowbite-react";
import { HiMenuAlt1, HiSearch, HiX, HiChevronDown } from "react-icons/hi";
import { useSidebarContext } from "../../../context/SidebarContext";
import { useDarkMode } from "../../../context/DarkModeContext";
import { useWorkspace } from "../../../context/WorkspaceContext";
import isSmallScreen from "../../../helpers/is-small-screen";
import { UserDropdown } from "../../molecules/UserDropdown";
import { AppDrawerDropdown } from "../../molecules/AppDrawerDropdown";
import { NotificationBellDropdown } from "../../molecules/NotificationBellDropdown";
import { IUser } from "../../../types";
import primaryLogo from "../../../assets/logo/logibud_primary_darkblue.svg";
import secondaryLogo from "../../../assets/logo/logibud_secondary_white.svg";
import { WorkSpaceIconCircle } from "../../atoms/Icons/Icons";
import { useUserWorkspaces } from "../../../hooks"; // Adjust the import as necessary
import { useTranslation } from "react-i18next";
import { useUserSession } from "../../../context/UserContext";

export const NavigationBar: FC<PropsWithRef<IUser>> = function (user) {
  const { t } = useTranslation("workspace");
  const { isDarkMode, setDarkMode } = useDarkMode();
  const { isOpenOnSmallScreens, isPageWithSidebar, setOpenOnSmallScreens } =
    useSidebarContext();
  const { activeWorkspace, setActiveWorkspace } = useWorkspace();

  const { userSession } = useUserSession();
  const query = useUserWorkspaces(userSession?.user.email ?? "");

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  const queryData = useMemo(() => query.data ?? [], [query]);

  // Handle workspace selection
  const handleWorkspaceChange = (workspaceId: string) => {
    const selectedWorkspace = queryData?.find(
      (w) => w.workspaceId === workspaceId,
    );
    if (selectedWorkspace) {
      setActiveWorkspace(selectedWorkspace);
    }
  };

  return (
    <Navbar
      fluid
      className="fixed z-30 w-full border-b border-gray-200 dark:bg-backgroundBlue dark:border-gray-700"
    >
      <div className="w-full p-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {isPageWithSidebar && (
              <button
                onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
                className="mr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline"
              >
                <span className="sr-only">Toggle sidebar</span>
                {isOpenOnSmallScreens && isSmallScreen() ? (
                  <HiX className="h-6 w-6" />
                ) : (
                  <HiMenuAlt1 className="h-6 w-6" />
                )}
              </button>
            )}
            <Navbar.Brand href="/">
              <img
                alt="Logibud"
                src={isDarkMode ? secondaryLogo : primaryLogo}
                className="mr-3 h-6 sm:h-8"
              />
            </Navbar.Brand>
          </div>
          <div className="flex items-center lg:gap-3">
            <div className="flex items-center">
              {!isSmallScreen() ? (
                isQueryLoading ? (
                  <Spinner />
                ) : query.isError ? (
                  <div className="text-red-500">
                    {t("error_loading_workspace")}
                  </div>
                ) : (
                  <Dropdown
                    inline
                    arrowIcon={false}
                    label={
                      <div className=" hidden md:flex mr-4  items-center gap-4 bg-white shadow-sm rounded-lg p-3 dark:bg-darkBlue h-full hover:border-gray-200 dark:hover:bg-backgroundBlue cursor-pointer border-gray-100 border-solid border-2 dark:border-lgb-grey-600 dark:border-solid dark:border-2">
                        <WorkSpaceIconCircle />
                        <span className="text-gray-600 dark:text-gray-400 flex-1">
                          {activeWorkspace?.workspaceName ||
                            t("select_workspace")}
                        </span>
                        <HiChevronDown className="text-gray-600 dark:text-gray-400" />
                      </div>
                    }
                  >
                    {queryData?.map((workspace) => (
                      <Dropdown.Item
                        key={workspace.workspaceId}
                        onClick={() =>
                          handleWorkspaceChange(workspace.workspaceId)
                        }
                      >
                        {workspace.workspaceName}
                      </Dropdown.Item>
                    )) || (
                      <Dropdown.Item disabled>
                        {t("no_workspace_available")}
                      </Dropdown.Item>
                    )}
                  </Dropdown>
                )
              ) : null}

              <div className="hidden">
                <AppDrawerDropdown />
              </div>
              <DarkThemeToggle onClick={() => setDarkMode(!isDarkMode)} />
              <div className="ml-2">
                <UserDropdown {...user} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { PageLayout, UserProfile } from "../components";
import { useUserSession } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { DefaultSkeleton } from "../components";

const ProfilePage: FC = function () {
  const { userSession } = useUserSession();
  const { t } = useTranslation(["common", "user"]);

  if (userSession == null)
    return (
      <PageLayout>
        <DefaultSkeleton />:
      </PageLayout>
    );

  return (
    <PageLayout>
      <div className="relative pb-20 bg-none">
        <div className=" bg-none grid grid-cols-1 gap-y-6 px-4 pt-6  xl:grid-cols-3 xl:gap-4">
          <div className="col-span-full">
            <div className="grid grid-cols-1 gap-y-4">
              <UserProfile {...userSession?.user} />
              {userSession?.user.isAdmin ? (
                <Button
                  className="w-full lg:w-auto bg-lgb-main-green-lighter text-lgb-main-green border-lgb-main-green-lighten transition-transform duration-200 ease-in-out active:scale-95 hover:brightness-95"
                  style={{ outline: "none", boxShadow: "none" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      userSession?.token.accessToken ?? "",
                    );
                  }}
                >
                  {t("copy_token")}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ProfilePage;

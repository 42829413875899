/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { ILocation, IRouteItem } from "../../../types";
import { DropdownSearch, DropdownSearchListItem } from "../Search";
import { Button } from "flowbite-react";
import { HiOutlinePlus, HiOutlineX } from "react-icons/hi";

export interface SavedLocationsProp {
  locations: ILocation[];
  currentRoute: IRouteItem[];
  addLocation: (id: string) => void;
  close: () => void;
}

export const SavedLocations: FC<SavedLocationsProp> = (props) => {
  const { t } = useTranslation(["orders", "validation"]);
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState(
    props.locations as ILocation[],
  );

  useEffect(() => {
    if (props.locations.length) {
      setSearchResults(props.locations);
    }
  }, [props.locations]);

  useEffect(() => {
    if (searchText.length) {
      let res =
        props.locations?.filter(
          (x) =>
            !props.currentRoute.map((x) => x.location.id).includes(x.id) &&
            (x.addressLine + " " + x.displayName)
              ?.toLowerCase()
              .includes(searchText.toLowerCase()),
        ) || [];
      setSearchResults(res.slice(0, 3));
    } else {
      setSearchResults(props.locations);
    }
  }, [searchText, props.locations, props.currentRoute]);

  function handleSearch(val: string) {
    setSearchText(val);
  }

  function addLocation() {
    props.addLocation(selectedLocation);
    setSelectedLocation("");
    setSearchText(searchText);
  }

  return (
    <div>
      <div className="relative mb-14">
        <DropdownSearch
          placeholder={t("create.route_selection.search_ph")}
          inputChanged={(val) => handleSearch(val)}
          showResults
          plain
        >
          <div className="pt-2">
            {searchResults.map((location: ILocation) => {
              return (
                <DropdownSearchListItem
                  key={location.id}
                  id={location.id}
                  title={location.displayName}
                  subtitle={location.addressLine || ""}
                  selected={
                    selectedLocation ? location.id === selectedLocation : false
                  }
                  buttonClickEvent={(id) => setSelectedLocation(id)}
                ></DropdownSearchListItem>
              );
            })}
          </div>
        </DropdownSearch>
      </div>
      <div
        className="absolute bottom-0 pb-6 bg-white rounded-lg"
        style={{ width: "calc(100% - 24px)" }}
      >
        <div className={"flex bg-white gap-4 justify-start mt-4"}>
          <Button
            disabled={!selectedLocation}
            color="primary"
            onClick={() => addLocation()}
          >
            <HiOutlinePlus className="mr-2 h-5 w-5" />
            {t("create.route_selection.add_saved_location_confirm")}
          </Button>
          <Button color="gray" onClick={props.close}>
            <HiOutlineX className="mr-2 h-5 w-5" />
            {t("common:close")}
          </Button>
        </div>
      </div>
    </div>
  );
};

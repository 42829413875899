/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useUserSession } from "../../context/UserContext";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useSignup } from "../../hooks";
import { HashLoader } from "react-spinners";
import TextLoader from "../auth/TextLoader";

export const WorkspaceLoader: FC = function () {
  const { userSession, setUserSession, persistUserSession } = useUserSession();
  const { setActiveWorkspace } = useWorkspace();
  const query = useSignup(
    userSession?.token.accessToken ?? "",
    userSession?.user.id ?? "",
  );

  useEffect(() => {
    if (userSession && query && !query.isLoading && query.isFetched) {
      userSession.user.isAdmin = query.data.user?.isAdmin ?? false;
      // Persist user session and sets first workspace as active workspace
      setUserSession(userSession);
      persistUserSession(userSession);
      setActiveWorkspace(query.data.workspaces[0]);
    }
  }, [
    userSession,
    query,
    setUserSession,
    persistUserSession,
    setActiveWorkspace,
  ]);

  // User not logged in are redirected to the login page
  if (!userSession) return <Navigate to="/login" />;

  if (query.isLoading)
    return (
      <div className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12 h-screen">
          <HashLoader
            color="#7214FF"
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <TextLoader />
        </div>
      </div>
    );

  // Redirect user to the dashboad once state is loaded
  return <Navigate to="/" />;
};

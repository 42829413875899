import { useQuery } from "react-query";
import { signup, getMyUser } from "../api";

export const useSignup = (token: string, userId: string) => {
  return useQuery("signup" + { userId }, () => signup(token));
};

export const useGetMyUser = (token: string, userId: string) => {
  return useQuery("getMyUser" + { userId }, () => getMyUser(token));
};

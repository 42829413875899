/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Label, Select, TextInput } from "flowbite-react";
import { useEffect, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePhone, HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";
import { IContactPerson, IRouteItem } from "../../../../types";

export class ContactRow {
  rowNumber: number;
  name: string;
  location: string;
  phoneNumber: string;

  constructor(
    rowNumber: number,
    name: string,
    location: string,
    phoneNumber: string,
  ) {
    this.rowNumber = rowNumber;
    this.name = name;
    this.location = location;
    this.phoneNumber = phoneNumber;
  }
}
export interface ContactInfoProps {
  contactPersons: IContactPerson[];
  route: IRouteItem[];
  validationFailed: boolean;
  addRow: (row: ContactRow) => void;
  removeRow: (row: ContactRow) => void;
  update: (route: ContactRow) => void;
}

const ContactInfo: FC<ContactInfoProps> = (props) => {
  const { t } = useTranslation(["orders", "common", "validation"]);
  const [contactRows, setContactRows] = useState<ContactRow[]>([]);

  useEffect(() => {
    setContactRows(
      props.contactPersons.map(
        (p, i) =>
          new ContactRow(i + 1, p.name, p.location || "", p.phoneNumber),
      ),
    );
  }, [props.contactPersons]);

  function addRow() {
    props.addRow(new ContactRow(contactRows.length + 1, "", "", ""));
  }

  function removeRow(id: number) {
    let row = contactRows.find((x) => x.rowNumber === id);
    if (row) {
      props.removeRow(row);
    }
  }

  function updateContactPerson(
    key: string,
    contactRow: ContactRow,
    val: string,
  ) {
    props.update({ ...contactRow, [key]: val });
  }

  let rows = [];
  for (let i = 0; i < contactRows.length; i++) {
    rows.push(
      <div
        key={contactRows[i].rowNumber}
        className="flex items-center gap-4 pb-12"
      >
        <div className="w-full">
          <Label value={t("create.contact_info.name")} />
          <TextInput
            defaultValue={contactRows[i].name}
            placeholder="John Doe"
            required
            size={32}
            type="text"
            onChange={(e) =>
              updateContactPerson("name", contactRows[i], e.target.value)
            }
            color={
              props.validationFailed && !(contactRows[i].name.length > 3)
                ? "failure"
                : undefined
            }
            helperText={
              props.validationFailed && !(contactRows[i].name.length > 3) ? (
                <p className="absolute text-sm text-red-600 dark:text-red-500">
                  {t("validation:contact_info.name")}
                </p>
              ) : (
                ""
              )
            }
          />
        </div>
        <div className="w-full">
          <Label value={t("create.contact_info.location")} />
          <Select
            value={contactRows[i].location || t("common:select")}
            onChange={(e) =>
              updateContactPerson("location", contactRows[i], e.target.value)
            }
            color={
              props.validationFailed && !contactRows[i].location
                ? "failure"
                : undefined
            }
            helperText={
              props.validationFailed && !contactRows[i].location ? (
                <p className="text-sm text-red-600 dark:text-red-500">
                  {t("validation:contact_info.location")}
                </p>
              ) : (
                ""
              )
            }
          >
            <option value={t("common:select")} disabled>
              {t("common:select")}
            </option>
            {props.route.map((route) => {
              return (
                <option
                  key={route.stopNumber}
                  value={route.location.displayName}
                >
                  {route.location.displayName}
                </option>
              );
            })}
          </Select>
        </div>
        <div className="w-full">
          <Label value={t("create.contact_info.phone_number")} />
          <TextInput
            defaultValue={contactRows[i].phoneNumber}
            icon={HiOutlinePhone}
            placeholder="000 00 000"
            required
            size={32}
            type="tel"
            onChange={(e) =>
              updateContactPerson("phoneNumber", contactRows[i], e.target.value)
            }
            color={
              props.validationFailed &&
              !(contactRows[i].phoneNumber.length === 8)
                ? "failure"
                : undefined
            }
            helperText={
              props.validationFailed &&
              !(contactRows[i].phoneNumber.length === 8) ? (
                <p className="absolute text-sm text-red-600 dark:text-red-500">
                  {t("validation:contact_info.phone")}
                </p>
              ) : (
                ""
              )
            }
          />
        </div>
        <div className="mt-6">
          <Button
            outline
            onClick={() => removeRow(contactRows[i].rowNumber)}
            type="submit"
          >
            <HiOutlineTrash color="red" className="h-5 w-5" />
          </Button>
        </div>
      </div>,
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center align-center pb-8">
        <p className="lgb-title text-xl">{t("create.contact_info.title")}</p>
        <Button color="light" onClick={() => addRow()} type="submit">
          <HiOutlinePlus className="mr-2 h-5 w-5" />
          Add row
        </Button>
      </div>
      {rows}
    </div>
  );
};

export default ContactInfo;

import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebaseConfig";
import { PasswordResetForm } from "../../components";
import { useNavigate } from "react-router-dom";
import { ValidationError } from "../../types/ValidationError";

const authSuccess: ValidationError = { isError: false };

export const PasswordResetPage: FC = () => {

  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(firebaseAuth);

  const [errors, setErrors] = useState(authSuccess);
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (error) {
      var authResult: ValidationError = {
        isError: true,
        errorFields: [],
        errorMessage: t("errors.password_reset_failure"),
      };
      if (
        error.message === "auth/invalid-email" ||
        error.message === "auth/missing-email"
      ) {
        authResult.errorFields = ["email"];
        authResult.errorMessage = t("errors.email_invalid");
      }
      setErrors(authResult);
    }
  }, [error, t]);

  const actionCodeSettings = {
    url: window.location.protocol + "//" + window.location.hostname + "/login",
  };

  return (
    <div className="bg-gray-50 dark:bg-gray-900 w-screen h-screen">
      <PasswordResetForm
        onClickPasswordReset={async (email) => {
          const success = await sendPasswordResetEmail(
            email,
            actionCodeSettings,
          );
          if (success) navigate("/login");
        }}
        validation={errors}
        isProcessing={sending}
      />
    </div>
  );
};

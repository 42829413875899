import type { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { Navbar, Sidebar } from "..";
import { SidebarProvider } from "../../context/SidebarContext";
import { useUserSession } from "../../context/UserContext";
import { PageContent, PageContentProps } from "./PageContent";

export const PageLayout: FC<PropsWithChildren<PageContentProps>> = function ({
  children,
  isFooter = true,
}) {
  const { userSession, isAuthenticated } = useUserSession();

  // Check for session expiration and redirect to login if needed
  if (userSession && isAuthenticated()) {
    return (
      <SidebarProvider>
        <Navbar {...userSession.user} />
        <div
          className="flex items-start h-screen"
          style={{ paddingTop: "85px" }}
        >
          <Sidebar />
          <PageContent isFooter={isFooter}>{children}</PageContent>
        </div>
      </SidebarProvider>
    );
  }

  // Redirect to login page if user is not logged in
  return <Navigate to="/logout" />;
};

import {
  IOrderProduct,
  IOrderProductItinerary,
  IProductDeliveryInformationValidated,
} from "../types";
import { IProductStatus } from "../types/OrderProduct";
import Product from "./Product";

class OrderProduct extends Product {
  itinerary?: IOrderProductItinerary;
  productId?: string;
  quantity: number;
  deliveredAt: Date | null;
  deliveredQuantity: number;
  pickedUpAt: Date | null;
  pickedUpQuantity: number;
  price: number | null;
  status: IProductStatus;
  lastUpdated: Date | null;
  constructor({
    id,
    descriptionShort,
    description,
    imgUrls,
    weight,
    length,
    height,
    width,
    pricing,
    createdAt,
    workspaceId,
    itinerary,
    productId,
    quantity,
    deliveredAt,
    deliveredQuantity,
    pickedUpAt,
    pickedUpQuantity,
    price,
    status,
    lastUpdated,
  }: IOrderProduct) {
    super({
      id,
      descriptionShort,
      description,
      imgUrls,
      weight,
      length,
      height,
      width,
      pricing,
      createdAt,
      workspaceId,
    });
    this.itinerary = itinerary;
    this.productId = productId;
    this.quantity = quantity;
    this.deliveredAt = deliveredAt;
    this.deliveredQuantity = deliveredQuantity;
    this.pickedUpAt = pickedUpAt;
    this.pickedUpQuantity = pickedUpQuantity;
    this.price = price;
    this.status = status;
    this.lastUpdated = lastUpdated;
  }

  static defaultItinerary = (): IOrderProductItinerary => {
    return {
      pickupStopNumber: 0,
      deliveryStopNumber: 0,
    };
  };

  static defaultProduct = (): IOrderProduct => {
    return {
      id: "",
      deliveredAt: null,
      deliveredQuantity: 0,
      pickedUpAt: null,
      pickedUpQuantity: 0,
      price: null,
      lastUpdated: null,
      descriptionShort: "",
      description: "",
      imgUrls: [],
      weight: 0,
      length: 0,
      height: 0,
      width: 0,
      productType: undefined,
      pricing: undefined,
      createdAt: "",
      workspaceId: "",
      itinerary: undefined,
      status: IProductStatus.New,
      productId: undefined,
      quantity: 1,
    };
  };

  static validateDeliveryInformation(product: IOrderProduct) {
    const validateDeliveryInfo = () => {
      const info = product.itinerary;
      if (info) {
        switch (true) {
          case info.deliveryStopNumber === null:
          case info.pickupStopNumber === null:
          case info.deliveryStopNumber === info.pickupStopNumber:
          default:
            return true;
        }
      }
    };

    let validationValues = {
      deliveryInformation: validateDeliveryInfo(),
      quantity: product.quantity > 0,
    } as IProductDeliveryInformationValidated;

    let invalid = Object.entries(validationValues);
    return {
      isValidProduct: invalid.filter(([key, value]) => !value).length === 0,
      invalidFields: Object.fromEntries(invalid),
    };
  }
}

export default OrderProduct;

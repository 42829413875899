/* eslint-disable jsx-a11y/anchor-is-valid */
import { type FC } from "react";
import { IOrderProduct } from "../../../types";
import cargoItem from "../../../assets/icons/cargo_icon.svg";
import cargoItemRed from "../../../assets/icons/cargo_icon_red.svg";
import { StatusBadge } from "../../atoms/Badges";
import { useTranslation } from "react-i18next";

export interface GoodsItemProp {
  item: IOrderProduct;
  productType: string;
  isCancelled: boolean;
}

export const GoodsItem: FC<GoodsItemProp> = (props) => {
  const { t } = useTranslation(["common"]);

  const dot = (
    <div
      className={
        "rounded " + (props.isCancelled ? "bg-lgb-red-400" : "bg-lgb-grey-300")
      }
      style={{ width: "8px", height: "8px" }}
    ></div>
  );
  return (
    <div className="flex items-center justify-between py-4">
      <div className="flex items-center h-full flex-wrap">
        <div
          className="dark:bg-white rounded-full relative mr-4 hidden sm:block"
          style={{ width: "40px", height: "40px" }}
        >
          <img
            src={props.isCancelled ? cargoItemRed : cargoItem}
            width={28}
            height={28}
            className="dark:text-lgb-grey-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            alt="Cargo icon"
          />
        </div>

        <div>
          <div className="flex items-center gap-4">
            <p
              className={
                "font-bold " +
                (props.isCancelled
                  ? "text-lgb-red-400 dark:text-lgb-red-200"
                  : "dark:text-lgb-grey-100")
              }
            >
              {props.item.descriptionShort}
            </p>
            {props.isCancelled && (
              <div className="block min-[1170px]:hidden">
                <StatusBadge
                  status={t("common:cancelled")}
                  bgStyle="bg-lgb-red-100 dark:bg-lgb-red-400 text-lgb-red-400 dark:text-lgb-red-100 "
                  rounded
                />
              </div>
            )}
          </div>
          <div
            className={
              "flex gap-4 items-center " +
              (props.isCancelled
                ? "text-lgb-red-400 dark:text-lgb-red-200"
                : "text-lgb-grey-400 dark:text-lgb-grey-100 dark:opacity-50")
            }
          >
            <p>{props.productType}</p>
            {dot}
            <p>{props.item.quantity} stk</p>
            {dot}
            <p>{props.item.weight * props.item.quantity}kg</p>
            {dot}
            <p>
              {(
                (props.item.length / 100) *
                (props.item.height / 100) *
                (props.item.width / 100) *
                props.item.quantity
              ).toFixed(2)}
              m<sup>3</sup>
            </p>
          </div>
        </div>
      </div>
      {props.isCancelled && (
        <div className="hidden min-[1170px]:block">
          <StatusBadge
            status={t("common:cancelled")}
            bgStyle="bg-lgb-red-100 dark:bg-lgb-red-400 text-lgb-red-400 dark:text-lgb-red-100 "
            rounded
          />
        </div>
      )}
    </div>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { Avatar } from "flowbite-react";

export interface DropdownSearchListItemProp {
  title: string;
  subtitle?: string;
  id: string;
  selected?: boolean;
  inlineSubtitle?: boolean;
  buttonClickEvent?: (id: string) => void;
}

export const DropdownSearchListItem: FC<DropdownSearchListItemProp> = (
  props,
) => {
  const handleButtonClick = () => {
    if (props.buttonClickEvent) {
      props.buttonClickEvent(props.id);
    }
  };

  return (
    <li
      className={
        "py-2 cursor-pointer hover:text-lgb-primary" +
        (props.selected ? " text-lgb-primary" : "")
      }
      onClick={handleButtonClick}
    >
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <Avatar
            img={""}
            placeholderInitials={
              props.title.includes(" ")
                ? props.title.split(" ")[0][0] + props.title.split(" ")[1][0]
                : props.title[0] + props.title[1]
            }
            size={"sm"}
            rounded
          />
        </div>
        {props.inlineSubtitle && (
          <div className="flex gap-2">
            <p className={"text-md font-medium"}>
              {props.title + " "}
              <span className={"text-md font-normal opacity-70"}>
                ({props.subtitle || ""})
              </span>
            </p>
          </div>
        )}
        {!props.inlineSubtitle && (
          <p className={"text-md font-medium"}>
            {props.title + " "}
            <p className={"text-md font-normal opacity-70"}>{props.subtitle}</p>
          </p>
        )}
      </div>
    </li>
  );
};

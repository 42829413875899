import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { useIdToken } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebaseConfig";
import { LoginForm } from "../../components";
import { useUserSession } from "../../context/UserContext";
import { ServerErrorPage } from "../utils";
import { ValidationError } from "../../types";

const authSuccess: ValidationError = { isError: false };

export const LoginPage = () => {
  const [errors, setErrors] = useState(authSuccess);
  const [user, loading, error] = useIdToken(firebaseAuth);

  const { t } = useTranslation("common");
  const { createUserSession, setUserSession, persistUserSession } =
    useUserSession();

  useEffect(() => {
    if (user) {
      var userSession = createUserSession(user);
      setUserSession(userSession);
      if (userSession) persistUserSession(userSession);
    }
  }, [user, createUserSession, setUserSession, persistUserSession]);

  if (error) {
    return <ServerErrorPage />;
  }

  if (user) {
    return <Navigate to="/load_workspace" />;
  }

  return (
    <div className="w-screen h-screen">
      <LoginForm
        onClicklogin={(email: string, password: string) => {
          setPersistence(firebaseAuth, browserLocalPersistence).then(() => {
            signInWithEmailAndPassword(firebaseAuth, email, password).catch(
              (error) => {
                var authResult: ValidationError = {
                  isError: true,
                  errorFields: [],
                  errorMessage: t("errors.login_failure"),
                };
                if (error.code === "auth/invalid-email") {
                  authResult.errorFields = ["email"];
                  authResult.errorMessage = t("errors.email_invalid");
                }
                if (error.code === "auth/wrong-password") {
                  authResult.errorFields = ["password"];
                  authResult.errorMessage = t("errors.wrong_password");
                }

                setErrors(authResult);
              },
            );
          });
        }}
        validation={errors}
        isProcessing={loading}
      />
    </div>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import classNames from "classnames";
import { Sidebar, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { HiSearch } from "react-icons/hi";
import { BottomMenu } from "../../molecules/BottomMenu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useSidebarContext } from "../../../context/SidebarContext";
import isSmallScreen from "../../../helpers/is-small-screen";
import { useUserSession } from "../../../context/UserContext";
import {
  administrationIcon,
  customersIcon,
  dashboardIcon,
  inventoryIcon,
  orderIcon,
  WorkSpaceIcon,
  UserProfile,
  mapIcon,
} from "../../atoms/Icons/Icons";
import { Button } from "flowbite-react";
import PlusIcon from "../../../assets/icons/plus_icon.svg";

export const SidebarMenu: FC = function () {
  const {
    isOpenOnSmallScreens: isSidebarOpenOnSmallScreens,
    openSidebarDropdowns,
    setOpenSidebarDropdowns,
  } = useSidebarContext();

  const [currentPage, setCurrentPage] = useState("");
  const { userSession } = useUserSession();
  const navigate = useNavigate();

  const { t } = useTranslation("navigation");

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, [setCurrentPage]);

  const setDropdowns = (route: string) => {
    if (openSidebarDropdowns.includes(route)) {
      setOpenSidebarDropdowns(openSidebarDropdowns.filter((x) => x !== route));
    } else {
      setOpenSidebarDropdowns(openSidebarDropdowns.concat([route]));
    }
  };

  const handleNewOrderClick = () => {
    navigate("/orders/new");
  };

  return (
    <div
      className={classNames("lg:!block", {
        hidden: !isSidebarOpenOnSmallScreens,
      })}
    >
      <Sidebar
        style={{ paddingTop: `${isSmallScreen() ? "85" : "86"}px` }}
        className="flex fixed top-0 left-0 z-20 flex-col rounded-none flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-backgroundBlue"
        aria-label="Sidebar with multi-level dropdown example"
        collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
      >
        <div className="flex h-full flex-col justify-between py-2">
          <div>
            <form className="pb-3 md:hidden">
              <TextInput
                icon={HiSearch}
                type="search"
                placeholder="Search"
                required
                size={32}
              />
            </form>
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <Sidebar.Item
                  onClick={() => navigate("/")}
                  icon={dashboardIcon}
                  className={
                    "/" === currentPage
                      ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                      : "hover:cursor-pointer dark:hover:bg-backgroundBlue "
                  }
                >
                  {t("sidebar.dashboard")}
                </Sidebar.Item>
                <Sidebar.Collapse
                  icon={orderIcon}
                  onClick={() => setDropdowns("orders")}
                  label={t("sidebar.orders")}
                  open={openSidebarDropdowns.includes("orders")}
                  className="dark:hover:bg-backgroundBlue"
                >
                  <Sidebar.Item
                    onClick={() => navigate("/orders/new")}
                    className={
                      "/orders/new" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.new_order")}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/orders/all")}
                    className={
                      "/orders/all" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.all_orders")}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/orders/scheduled")}
                    className={
                      "/orders/scheduled" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.scheduled_order")}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/orders/ongoing")}
                    className={
                      "/orders/ongoing" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.ongoing_orders")}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/orders/completed")}
                    className={
                      "/orders/completed" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.completed_orders")}
                  </Sidebar.Item>
                </Sidebar.Collapse>
                <Sidebar.Item
                  onClick={() => navigate("/map")}
                  icon={mapIcon}
                  className={
                    "/map" === currentPage
                      ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                      : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                  }
                >
                  {t("sidebar.map")}
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/customers")}
                  icon={customersIcon}
                  className={
                    "/customers" === currentPage
                      ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                      : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                  }
                >
                  {t("sidebar.customers")}
                </Sidebar.Item>
                <Sidebar.Collapse
                  icon={inventoryIcon}
                  label={t("sidebar.resources")}
                  onClick={() => setDropdowns("resources")}
                  open={openSidebarDropdowns.includes("resources")}
                  className="dark:hover:bg-backgroundBlue"
                >
                  <Sidebar.Item
                    onClick={() => navigate("/resources/drivers")}
                    className={
                      "/resources/drivers" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.drivers")}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/resources/vehicles")}
                    className={
                      "/resources/vehicles" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.vehicles")}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/workspace/companies")}
                    className={
                      "/workspace/companies" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.companies")}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/resources/products")}
                    className={
                      "/resources/products" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.products")}
                  </Sidebar.Item>
                </Sidebar.Collapse>
                <Sidebar.Collapse
                  icon={WorkSpaceIcon}
                  label={t("sidebar.workspace")}
                  open={openSidebarDropdowns.includes("workspace")}
                  onClick={() => setDropdowns("workspace")}
                  className="dark:hover:bg-backgroundBlue"
                >
                  <Sidebar.Item
                    onClick={() => navigate("/workspace/resource-types")}
                    className={
                      "/workspace/resource-types" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.parameters")}
                  </Sidebar.Item>

                  <Sidebar.Item
                    onClick={() => navigate("/workspace/users")}
                    className={
                      "/workspace/users" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.users")}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/workspace/coupons")}
                    className={
                      "/workspace/coupons" === currentPage
                        ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                        : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                    }
                  >
                    {t("sidebar.coupons")}
                  </Sidebar.Item>
                </Sidebar.Collapse>
                {userSession?.user.isAdmin ? (
                  <Sidebar.Collapse
                    icon={administrationIcon}
                    label={t("sidebar.administration")}
                    onClick={() => setDropdowns("administration")}
                    open={openSidebarDropdowns.includes("administration")}
                    className="dark:hover:bg-backgroundBlue"
                  >
                    <Sidebar.Item
                      onClick={() => navigate("/administration/users")}
                      className={
                        "/administration/users" === currentPage
                          ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                          : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                      }
                    >
                      {t("sidebar.all_users")}
                    </Sidebar.Item>
                    <Sidebar.Item
                      onClick={() => navigate("/administration/workspaces")}
                      className={
                        "/administration/workspaces" === currentPage
                          ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                          : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                      }
                    >
                      {t("sidebar.all_workspaces")}
                    </Sidebar.Item>
                  </Sidebar.Collapse>
                ) : null}
                <Sidebar.Item
                  onClick={() => navigate("/profile")}
                  icon={UserProfile}
                  className={
                    "/profile" === currentPage
                      ? "bg-gray-100 dark:bg-backgroundBlue hover:cursor-pointer dark:hover:bg-backgroundBlue"
                      : "hover:cursor-pointer dark:hover:bg-backgroundBlue"
                  }
                >
                  {t("sidebar.profile")}
                </Sidebar.Item>
              </Sidebar.ItemGroup>

              {!isSidebarOpenOnSmallScreens && ( // Check if the sidebar is collapsed
                <div className="w-full flex justify-center items-center">
                  <div className="w-full flex justify-center items-center">
                    <Button
                      onClick={handleNewOrderClick}
                      style={{
                        boxShadow: "rgba(114, 20, 255, 0.42) 4px 4px 20px 0px",
                      }}
                      className="my-12 bg-primaryBlue hover:bg-hoverBlue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center me-2 dark:bg-primaryBlue dark:hover:bg-hoverBlue dark:focus:ring-blue-800"
                    >
                      <img
                        src={PlusIcon}
                        className="w-3.5 h-3.5 me-2"
                        alt="New Order Icon"
                      />
                      {t("sidebar.new_order")}
                    </Button>
                  </div>
                </div>
              )}
            </Sidebar.Items>
          </div>
          <BottomMenu />
        </div>
      </Sidebar>
    </div>
  );
};

import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import {
  Timestamp,
  IWorkspace,
  WorkspaceRole,
  IWorkspaceAccess,
} from "../types";
import { query } from "firebase/firestore";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class WorkspaceAccess implements IWorkspaceAccess {
  workspaceId: string;
  workspaceName: string | null;
  email: string;
  role: WorkspaceRole;
  createdBy: string | null;
  createdAt?: Timestamp;

  static primaryCollectionName = "workspaces";
  static collectionName = "workspaceAccesses";

  static converter = {
    toFirestore(workspace: WorkspaceAccess) {
      return workspace.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IWorkspaceAccess;
      return new WorkspaceAccess({ ...data, email: snapshot.id });
    },
  };

  constructor({
    workspaceId,
    workspaceName,
    email,
    role,
    createdBy,
    createdAt,
  }: IWorkspaceAccess) {
    this.workspaceId = workspaceId;
    this.workspaceName = workspaceName || null;
    this.email = email;
    this.role = role;
    this.createdBy = createdBy || null;
    this.createdAt = createdAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IWorkspace>) {
    return Object.assign(this, updates);
  }

  static default() {
    return new WorkspaceAccess({
      workspaceId: "",
      workspaceName: "",
      email: "",
      role: WorkspaceRole.ReadOnly,
      createdBy: ""
    });
  }

  static isNew(data: IWorkspaceAccess): boolean {
    return data.email.length === 0;
  }
  
  data() {
    return {
      workspaceId: this.workspaceId,
      workspaceName: this.workspaceName,
      email: this.email,
      role: this.role,
      createdBy: this.createdBy || "",
      createdAt: this.createdAt || null,
    };
  }

  static getUserWorkspaces = (email: string) => {
    return query(
      firestore
        .collectionGroup(WorkspaceAccess.collectionName)
        .where("email", "==", email)
        .withConverter(WorkspaceAccess.converter),
    );
  };

  static getWorkspaceUser = (workspaceId: string) => {
    return query(
      firestore
        .collection(WorkspaceAccess.primaryCollectionName)
        .doc(workspaceId)
        .collection(WorkspaceAccess.collectionName)
        .withConverter(WorkspaceAccess.converter),
    );
  };

  static updateWorkspaceUser = async (
    workspaceUser : WorkspaceAccess,
    updates: Partial<IWorkspaceAccess>
  ) => {
    const updatedUser =
      (workspaceUser.clone && workspaceUser.clone()) || new WorkspaceAccess({ ...workspaceUser });

      updatedUser.setData({
      ...updates
    });

    await firestore
      .collection(WorkspaceAccess.primaryCollectionName)
      .doc(workspaceUser.workspaceId)
      .collection(WorkspaceAccess.collectionName)
      .doc(workspaceUser.email)
      .withConverter(WorkspaceAccess.converter)
      .set(updatedUser, { merge: true });

    return updatedUser;
  };

  static deleteWorkspaceUser = async (workspaceUser : WorkspaceAccess) => {
    return await firestore
        .collection(WorkspaceAccess.primaryCollectionName)
        .doc(workspaceUser.workspaceId)
        .collection(WorkspaceAccess.collectionName)
        .doc(workspaceUser.email)
        .delete();
  };
}

export default WorkspaceAccess;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { type FC } from "react";
import RouteStopCard from "../Cards/RouteStopCard";
import { IOrderProduct, IRouteItem } from "../../../types";
import { HiArrowNarrowRight, HiArrowNarrowDown } from "react-icons/hi";

export interface OrderDetailsProps {
  route: IRouteItem[];
  cargo: IOrderProduct[];
}

export const RouteTimeline: FC<OrderDetailsProps> = (props) => {
  const stopType = (
    stopNumber: number,
  ): "Pickup" | "Delivery" | "DeliveryAndPickup" => {
    const deliveryStops = props.cargo.map(
      (x) => x.itinerary?.deliveryStopNumber,
    );
    const pickupStops = props.cargo.map((x) => x.itinerary?.pickupStopNumber);

    switch (true) {
      case pickupStops.includes(stopNumber) &&
        deliveryStops.includes(stopNumber):
        return "DeliveryAndPickup";
      case pickupStops.includes(stopNumber):
        return "Pickup";
      case deliveryStops.includes(stopNumber):
        return "Delivery";
      default:
        return "Pickup";
    }
  };

  return (
    <div className="min-[1170px]:grid min-[1170px]:grid-cols-2 min-[1600px]:grid-cols-3 gap-x-4 gap-y-8 items-center items-stretch flex-wrap my-8">
      {props.route.map((routeItem, index) => {
        return (
          <div
            key={routeItem.stopNumber}
            className="min-[1170px]:flex min-[1170px]:w-full flex-row items-center gap-4"
          >
            <RouteStopCard
              className="h-full w-full"
              stopType={stopType(routeItem.stopNumber)}
              routeStop={routeItem}
              hasImages={index % 2 === 0} //TODO
              hasSignature={index === 0} //TODO
            />
            <HiArrowNarrowRight
              size={24}
              className={
                "text-lgb-grey-200 dark:text-lgb-grey-100 min-[1170px]:block hidden" +
                (routeItem.stopNumber ===
                props.route[props.route.length - 1].stopNumber
                  ? " invisible"
                  : "")
              }
            />
            <HiArrowNarrowDown
              size={24}
              className={
                "flex justify-center w-full my-1 text-lgb-grey-200 dark:text-lgb-grey-100 block min-[1170px]:hidden" +
                (routeItem.stopNumber ===
                props.route[props.route.length - 1].stopNumber
                  ? " invisible"
                  : "")
              }
            />
          </div>
        );
      })}
    </div>
  );
};

function getTimeIntervalsForDate(date: Date): string[] {
  const timeIntervals: string[] = [];
  const currentDate = new Date();

  // Check if the provided date is today
  const isToday = date.toDateString() === currentDate.toDateString();

  // If it's a future date, return the entire list from 00:00 to 23:45
  if (!isToday) {
    let tempDate = new Date(date);
    tempDate.setHours(0, 0, 0, 0); // Set time to 00:00
    while (tempDate.getHours() < 24) {
      const hours = tempDate.getHours();
      const minutes = tempDate.getMinutes();
      const timeString = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
      timeIntervals.push(timeString);

      // Stop at 23:45
      if (hours === 23 && minutes === 45) {
        break;
      }

      // Add 15 minutes for the next interval
      tempDate.setMinutes(tempDate.getMinutes() + 15);
    }
  } else {
    // If it's today, round up to the nearest 15 minute interval
    const minutes = currentDate.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;
    if (roundedMinutes === 60) {
      currentDate.setHours(currentDate.getHours() + 1);
      currentDate.setMinutes(0);
    } else {
      currentDate.setMinutes(roundedMinutes);
    }
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    // Generate the list of time intervals until 23:45
    while (currentDate.getHours() < 24) {
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const timeString = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
      timeIntervals.push(timeString);

      // Stop at 23:45
      if (hours === 23 && minutes === 45) {
        break;
      }

      // Add 15 minutes for the next interval
      currentDate.setMinutes(currentDate.getMinutes() + 15);
    }
  }

  return timeIntervals;
}

export { getTimeIntervalsForDate };

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo, type FC } from "react";
import { IOrderProduct, ParameterType } from "../../../types";
import { GoodsItem } from "./GoodsItem";
import { useParameters } from "../../../hooks";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { IProductStatus } from "../../../types/OrderProduct";

export interface GoodsOverviewProp {
  cargo: IOrderProduct[];
}

export const GoodsOverview: FC<GoodsOverviewProp> = (props) => {
  const { activeWorkspace } = useWorkspace();
  const query = useParameters(
    activeWorkspace?.workspaceId ?? "",
    ParameterType.ProductType,
  );
  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );
  const productTypes = useMemo(() => query.data ?? [], [query]);

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700">
      {!isQueryLoading &&
        props.cargo.map((item, index) => {
          return (
            <GoodsItem
              key={item.id}
              item={item}
              isCancelled={item.status === IProductStatus.Cancelled}
              productType={
                productTypes.find((x) => x.id === item.productType?.toString())
                  ?.code ?? ""
              }
            />
          );
        })}
    </div>
  );
};

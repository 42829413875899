import React, { useState } from "react";
import { Dialog } from "../Dialog";
import { useTranslation } from "react-i18next";

interface CouponProps {
  id: number;
  image: string;
  label: string;
  title: string;
  description: string;
  expireDate: Date;
  address: {
    internet?: string; // Will be used in the dialog popup as the main CTA
    physical?: {
      region?: string;
      country?: string;
      zip: string;
      city: string;
      address: string;
    };
  };
  ctaText: string;
  howToUse?: string;
}

const Coupon: React.FC<CouponProps> = ({
  id,
  image,
  label,
  title,
  description,
  expireDate,
  address,
  ctaText,
  howToUse,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation(["common"]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCTAClick = () => {
    if (address.internet) {
      window.open(address.internet, "_blank");
    }
  };

  return (
    <div className="rounded overflow-hidden shadow-md bg-white dark:bg-darkBlue   dark:border-lgb-grey-600 dark:border-solid dark:border-2">
      <div className="relative h-48 ">
        <img
          className="absolute h-full w-full object-cover"
          src={image}
          alt={label}
        />
      </div>
      <div className="px-6 py-4 flex flex-col justify-between h-auto">
        <div>
          <div className="font-bold text-xl mb-2 dark:text-white">{title}</div>
          <p className="min-h-32 text-gray-700 dark:text-gray-400 text-base">
            {description}
          </p>
        </div>
        <div className="flex justify-between items-center mt-6 flex-wrap gap-6">
          {address.internet && (
            <button
              className="bg-lgb-green-300 hover:bg-lgb-green-400  text-white font-bold py-2 px-4 rounded"
              onClick={handleOpenDialog}
            >
              {ctaText}
            </button>
          )}
          <p className="text-gray-400 dark:text-gray-900 text-sm">{`Expires on: ${expireDate.toDateString()}`}</p>
        </div>
      </div>
      <Dialog
        show={dialogOpen}
        hideButtons={false}
        buttonCloseText={t("close")}
        buttonConfirmText={t("visit_website")}
        title={title}
        content={
          <div>
            <p>{howToUse}</p>
            {/* Add more details if needed */}
          </div>
        }
        toggleModal={handleCloseDialog}
        confirmButton={() => {
          window.open(`${address.internet}`, "_blank");
        }}
      />
    </div>
  );
};

export default Coupon;

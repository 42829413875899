import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { Vehicle } from "../models";
import { useCache } from "../context/CacheContext";

export const useVehicles = (workspaceId: string) => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData(
    [Vehicle.collectionName, cacheKey, workspaceId],
    Vehicle.list(workspaceId),
    {
      subscribe: false,
    },
  );
};

import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import {
  Timestamp,
  IOrder,
  IContactPerson,
  IRouteItem,
  ILocation,
  ICustomer,
  IOrderProduct,
} from "../types";
import { query, doc } from "firebase/firestore";
import { Customer, OrderProduct } from ".";
import { OrderStatus } from "../types/order/IOrder";
import { IOrderValidated } from "../types/order";
import { CreateOrderStepsEnum } from "../pages/orders/create-order";
import {
  validateMobile,
  validateStringLength,
} from "../helpers/validationHelper";
import { IRouteTiming } from "../types/order/IRouteItem";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Order implements IOrder {
  id: string;
  customer: ICustomer;
  customerId: string;
  route: IRouteItem[];
  cargo: IOrderProduct[];
  driverId?: string;
  comment: string;
  status: OrderStatus;
  createdAt: Timestamp | null;
  createdBy: string | null;
  lastUpdated: string | null;
  workspaceId: string;

  static collectionName = "orders";

  static converter = {
    toFirestore(order: Order) {
      return order.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      let data = snapshot.data(options) as IOrder;
      return new Order({
        ...data,
        id: snapshot.id,
      });
    },
  };

  static createId() {
    return firestore.collection(Order.collectionName).doc().id;
  }

  constructor({
    id,
    customer,
    customerId,
    cargo,
    driverId,
    comment,
    status,
    workspaceId,
    createdAt,
    createdBy,
    lastUpdated,
    route,
  }: IOrder) {
    this.id = id || "";
    this.customer = customer || Customer.default();
    this.customerId = customerId || "";
    this.cargo = cargo || [];
    this.driverId = driverId || undefined;
    this.comment = comment || "";
    this.status = status || OrderStatus.Draft;
    this.workspaceId = workspaceId || "";
    this.createdAt = createdAt || null;
    this.createdBy = createdBy || null;
    this.lastUpdated = lastUpdated || null;
    this.route =
      route ||
      ([
        {
          location: {} as ILocation,
          stopNumber: 0,
          stopDate: new Date(),
          timing: { earliest: true } as IRouteTiming,
        },
        {
          location: {} as ILocation,
          stopNumber: 1,
          stopDate: new Date(),
          timing: { earliest: true } as IRouteTiming,
        },
      ] as IRouteItem[]);
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IOrder>) {
    return Object.assign(this, updates);
  }
  toPlainObj(): Order {
    return Object.assign({}, this);
  }

  data() {
    return {
      id: this.id,
      customer: this.customer || Customer.default(),
      customerId: this.customerId || "",
      cargo: this.cargo || [],
      driverId: this.driverId || undefined,
      comment: this.comment || "",
      status: this.status || OrderStatus.Draft,
      workspaceId: this.workspaceId || "",
      createdAt: this.createdAt || null,
      createdBy: this.createdBy || null,
      lastUpdated: this.lastUpdated || null,
      route:
        this.route ||
        ([
          {
            location: {} as ILocation,
            stopNumber: 0,
            stopDate: new Date(),
            timing: { earliest: true } as IRouteTiming,
          },
          {
            location: {} as ILocation,
            stopNumber: 1,
            stopDate: new Date(),
            timing: { earliest: true } as IRouteTiming,
          },
        ] as IRouteItem[]),
    };
  }

  static default = (): IOrder => {
    return {
      id: "",
      customer: Customer.default() as Customer,
      customerId: "",
      cargo: [OrderProduct.defaultProduct()],
      driverId: undefined,
      comment: "",
      status: OrderStatus.Draft,
      workspaceId: "",
      createdAt: null,
      createdBy: null,
      lastUpdated: null,
      route: [
        {
          location: {} as ILocation,
          stopNumber: 0,
          stopDate: new Date(),
          timing: { earliest: true } as IRouteTiming,
        },
        {
          location: {} as ILocation,
          stopNumber: 1,
          stopDate: new Date(),
          timing: { earliest: true } as IRouteTiming,
        },
      ] as IRouteItem[],
    };
  };

  static get = (id: string) => {
    return doc(
      firestore.collection(Order.collectionName).withConverter(Order.converter),
      id,
    );
  };

  static list = (workspaceId: string, statuses: OrderStatus[] = []) => {
    let request = firestore
      .collection(Order.collectionName)
      .where("workspaceId", "==", workspaceId);

    if (statuses.length > 0) {
      request = request.where("status", "in", statuses);
    }

    return query(request.withConverter(Order.converter));
  };

  static validateCustomer = (customer: ICustomer) => {
    return customer && customer.id !== "";
  };
  static validateRoute = (routes: IRouteItem[]) => {
    let valid = true;
    for (const route of routes) {
      if (
        !(
          route.stopDate &&
          route.location &&
          route.location.addressLine &&
          route.location.postCode &&
          route.location.city
        )
      ) {
        valid = false;
      }
    }
    return valid;
  };
  static validateCargo = (cargo: IOrderProduct[]) => {
    let valid = true;
    for (const item of cargo) {
      if (
        !(
          item.itinerary !== undefined &&
          item.itinerary?.pickupStopNumber <
            item.itinerary?.deliveryStopNumber &&
          item.quantity > 0 &&
          item.descriptionShort.length &&
          item.height >= 0 &&
          item.weight >= 0 &&
          item.length >= 0 &&
          item.weight >= 0 &&
          item.pricing &&
          item.pricing.priceModel &&
          item.pricing.priceModel.length > 0 &&
          item.productType
        )
      ) {
        valid = false;
      }
    }
    return cargo.length ? valid : false;
  };
  static validateContacts = (contacts: IContactPerson[]) => {
    let valid = true;
    for (const person of contacts) {
      if (
        !(
          person.location &&
          validateStringLength(person.name, 3) &&
          validateMobile(person.phoneNumber)
        )
      ) {
        valid = false;
      }
    }
    return contacts.length ? valid : false;
  };

  static validateDriver = (driverId: string | undefined) => {
    return driverId !== undefined && driverId !== "";
  };

  static validateStep = (order: IOrder, step: number) => {
    let validation = false;
    switch (step) {
      case CreateOrderStepsEnum.CUSTOMER_SELECTION:
        validation = Order.validateCustomer(order.customer);
        break;
      case CreateOrderStepsEnum.ROUTE_SELECTION:
        validation = Order.validateRoute(order.route);
        break;
      case CreateOrderStepsEnum.PRODUCT_MANAGEMENT:
        validation = Order.validateCargo(order.cargo);
        break;
      case CreateOrderStepsEnum.DRIVER_SELECTION:
        validation = Order.validateDriver(order.driverId);
        break;
      default:
        validation = false;
        break;
    }
    return validation;
  };

  static validateOrder(order: IOrder) {
    let validationValues = {
      customer:
        order.customerId &&
        order.customerId !== "" &&
        order.customer &&
        order.customer.id !== "",
      route: order.route && order.route.length > 0,
      cargo: order.cargo && order.cargo.length > 0,
    } as IOrderValidated;

    let invalid = Object.entries(validationValues);
    return {
      isValidOrder: invalid.filter(([key, value]) => !value).length === 0,
      invalidFields: Object.fromEntries(invalid),
    };
  }

  static create = async (
    isDraft: boolean,
    data: Omit<IOrder, "id | createdAt">,
  ) => {
    data = structuredClone(data);
    const newOrder = new Order({
      ...data,
      id: uuidv4().toString(),
      status: isDraft ? OrderStatus.Draft : OrderStatus.New,
      createdAt: Timestamp.now(),
      // createdBy: 'get user and set value here'
    });
    try {
      await firestore
        .collection(Order.collectionName)
        .doc(newOrder.id)
        .withConverter(Order.converter)
        .set(newOrder, { merge: true });
    } catch (e) {
      console.warn("Create order failed with error: ", e);
      return false;
    }
    return true;
  };
}

export default Order;

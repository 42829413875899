/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, type FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CreateLocation,
  Dialog,
  DropdownSearch,
  DropdownSearchListItem,
  SavedLocations,
} from "../../../../components";
import { Button, CustomFlowbiteTheme, Tooltip } from "flowbite-react";

import { HiOutlinePlus } from "react-icons/hi";
import { ILocation, IRouteItem, IRouteTiming } from "../../../../types";
import isSmallScreen from "../../../../helpers/is-small-screen";
import { useLocations } from "../../../../hooks";
import { useWorkspace } from "../../../../context/WorkspaceContext";
import RouteCard from "../../../../components/molecules/Cards/RouteCard";
import { TableSkeletonsByAmount } from "../../../../components/atoms/Skeleton/TableSkeleton";

export interface RouteSelectionProps {
  route: IRouteItem[];
  validationFailed: boolean;
  update: (routes: IRouteItem[]) => void;
}
const RouteSelection: FC<RouteSelectionProps> = (props) => {
  const { t } = useTranslation("orders");
  const [showDialog, setShowDialog] = useState(false);
  const [showAllLocations, setShowAllLocations] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(props.route);
  const { activeWorkspace } = useWorkspace();

  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState([] as ILocation[]);

  const query = useLocations(activeWorkspace?.workspaceId ?? "");
  const locations = query.data;

  useEffect(() => {
    setCurrentRoute(props.route);
  }, [props.route]);

  useEffect(() => {
    if (searchText.length) {
      let res =
        locations?.filter(
          (x) =>
            !currentRoute.map((x) => x.location.id).includes(x.id) &&
            (x.addressLine + " " + x.displayName)
              ?.toLowerCase()
              .includes(searchText.toLowerCase()),
        ) || [];
      setSearchResults(res.slice(0, 3));
    } else {
      setSearchResults([]);
    }
  }, [searchText, locations, currentRoute]);

  function toggleDialog() {
    setShowDialog(!showDialog);
  }
  function toggleShowAllDialog() {
    setSearchText("");
    setShowAllLocations(!showAllLocations);
  }

  function handleSearch(val: string) {
    setSearchText(val);
  }

  const removeRouteItem = (routeIdx: number) => {
    let stopToRemove = currentRoute.at(routeIdx);
    props.update(
      currentRoute.filter((x) => x.stopNumber !== stopToRemove?.stopNumber),
    );
  };

  const updateRouteItem = (routeIdx: number, val: IRouteItem) => {
    let routeItems = [...props.route] as IRouteItem[];
    routeItems[routeIdx] = val;
    props.update(routeItems);
  };

  const CreateDialog = (
    <Dialog
      title={t("create.route_selection.create_location_dialog.title")}
      content={<CreateLocation close={() => toggleDialog()} />}
      show={showDialog}
      toggleModal={() => toggleDialog()}
      hideButtons
    />
  );

  const showAllLocationsDialog = (
    <Dialog
      title={t("create.route_selection.add_saved_location_title")}
      show={showAllLocations}
      toggleModal={() => toggleShowAllDialog()}
      hideButtons
      content={
        <SavedLocations
          locations={
            locations?.filter(
              (x) => !props.route.map((x) => x.location.id).includes(x.id),
            ) || []
          }
          currentRoute={props.route}
          addLocation={addSavedLocation}
          close={toggleShowAllDialog}
        />
      }
    />
  );

  function stopIsEmpty(stop: IRouteItem) {
    let comparisonStop = JSON.stringify({
      ...stop,
      stopNumber: 0,
      stopDate: undefined,
    });
    const emptyStop = JSON.stringify({
      stopDate: undefined,
      location: {},
      stopNumber: 0,
      timing: { earliest: true } as IRouteTiming,
    });
    return comparisonStop === emptyStop;
  }

  function addSavedLocation(id: string) {
    let location = locations?.find((x) => x.id === id);
    let emptyStop = currentRoute.find((x) => stopIsEmpty(x));
    let newRoute = [...currentRoute];
    /**
     * Replace empty stop if it exists
     */
    if (emptyStop) {
      let index = newRoute.findIndex(
        (x) => x.stopNumber === emptyStop?.stopNumber,
      );

      newRoute[index] = {
        location: location as ILocation,
        stopNumber: emptyStop.stopNumber,
        stopDate: new Date(),
        timing: { earliest: true } as IRouteTiming,
      };
    } else {
      newRoute = newRoute.concat([
        {
          location: location as ILocation,
          stopNumber: currentRoute.length + 1,
          stopDate: new Date(),
          timing: { earliest: true } as IRouteTiming,
        },
      ]);
    }

    setSearchText("");
    if (showAllLocations) {
      toggleShowAllDialog();
    }
    props.update(newRoute);
  }

  function addRow() {
    let routeItems = [...props.route] as IRouteItem[];
    routeItems.push({
      location: {} as ILocation,
      stopNumber: 0,
      stopDate: new Date(),
      timing: { earliest: true } as IRouteTiming,
    });
    props.update(routeItems);
  }

  let rows = [];
  for (let i = 0; i < currentRoute.length; i++) {
    rows.push(
      <RouteCard
        key={i}
        validationFailed={props.validationFailed}
        routeItem={currentRoute[i]}
        routeLength={currentRoute.length}
        update={(e) => updateRouteItem(i, e)}
        remove={() => removeRouteItem(i)}
      />,
    );
  }

  return (
    <div>
      <div className="pb-4">
        <DropdownSearch
          placeholder={t("create.route_selection.search_ph")}
          inputChanged={(val) => handleSearch(val)}
          showResults={searchResults.length > 0}
          childrenAbsolute
          buttonClick={toggleDialog}
          buttonText={
            isSmallScreen()
              ? t("create.route_selection.create_location_button_sm")
              : t("create.route_selection.create_location_button")
          }
        >
          {query.isLoading && <TableSkeletonsByAmount quantity={3} />}
          {!query.isLoading && searchResults.length > 0 && (
            <div>
              {searchResults.map((location: ILocation) => {
                return (
                  <DropdownSearchListItem
                    key={location.id}
                    id={location.id}
                    title={location.displayName}
                    subtitle={location.addressLine || ""}
                    buttonClickEvent={addSavedLocation}
                    inlineSubtitle
                  ></DropdownSearchListItem>
                );
              })}
              {searchResults.length === 3 && (
                <p
                  className="pt-4 text-md underline font-normal cursor-pointer hover:text-lgb-primary"
                  onClick={() => toggleShowAllDialog()}
                >
                  {t("create.route_selection.see_all")}
                </p>
              )}
            </div>
          )}
        </DropdownSearch>
      </div>

      <div
        className={
          "w-full" +
          (isSmallScreen() ? " grid grid-row gap-4" : " grid grid-cols-2 gap-4")
        }
      >
        {rows}
      </div>
      <Tooltip
        className="ml-11"
        theme={selectedTheme}
        style="light"
        content={
          <div className="w-full divide-y divide-gray-200 dark:divide-gray-700 z-10">
            <div
              className="py-2 cursor-pointer hover:text-lgb-primary dark:hover:text-white"
              onClick={() => addRow()}
            >
              <div className="flex items-center space-x-4">
                <div className="flex gap-2">
                  <p className={"text-md font-medium"}>
                    {t("create.route_selection.add_empty_stop")}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="py-2 cursor-pointer hover:text-lgb-primary dark:hover:text-white"
              onClick={() => toggleShowAllDialog()}
            >
              <div className="flex items-center space-x-4">
                <div className="flex gap-2">
                  <p className={"text-md font-medium"}>
                    {t("create.route_selection.add_saved_location_title")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <Button
          fullSized={isSmallScreen()}
          className="relative mt-4"
          color="light"
          type="submit"
        >
          <HiOutlinePlus className="h-5 w-5" />
        </Button>
      </Tooltip>
      {CreateDialog}
      {showAllLocationsDialog}
    </div>
  );
};
const selectedTheme: CustomFlowbiteTheme["tooltip"] = {
  base: "absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-lg",
  arrow: {
    base: "hidden",
  },
  style: {
    light:
      "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white",
  },
};
export default RouteSelection;

import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  StandardForm,
  StyledSelect,
  CustomStyledSelect,
  StyledTextArea,
  StyledTextInput,
} from "../..";
import { OrderProduct } from "../../../models";
import { IOrderProductItinerary, IParameter, IRouteItem, IProductPricing } from "../../../types";
import { IPriceModel } from "../../../types/Product";
import {
  validateHasValue,
  validateNumberBetween,
} from "../../../helpers/validationHelper";

export interface ProductCardProps {
  item: OrderProduct;
  route: IRouteItem[];
  removable?: boolean;
  validationFailed?: boolean;
  productTypes: IParameter[];
  update: (product: OrderProduct) => void;
  remove: () => void;
}

export const ProductCard: FC<ProductCardProps> = (props) => {
  const { t } = useTranslation(["orders", "validation"]);

  const getDefaultItem = () => {
    let item = { ...props.item } as OrderProduct;
    if (item.itinerary === undefined) {
      item.itinerary = {} as IOrderProductItinerary;
      item.itinerary.pickupStopNumber = props.route.at(0)?.stopNumber ?? 0;
      item.itinerary.deliveryStopNumber = props.route.at(-1)?.stopNumber ?? 0;
    }
    return item;
  };

  const [currentItem, setCurrentItem] =
    useState<OrderProduct>(getDefaultItem());

  useEffect(() => {
    props.update(currentItem);
  }, [currentItem]);

  const updateProduct = (key: string, value: any) => {
    let item = { ...props.item } as OrderProduct;
    item = { ...props.item, [key]: value } as OrderProduct;
    props.update(item);
  };

  const updateItinerary = (key: string, value: any) => {
    let itinerary = { ...props.item.itinerary } as IOrderProductItinerary;
    itinerary = {
      ...props.item.itinerary,
      [key]: value,
    } as IOrderProductItinerary;
    props.item.itinerary = itinerary;
    props.update(props.item);
  };

  return (
    <Card
      type="dashed"
      bgColorClass={
        props.item.productId ? "bg-lgb-green-100" : "bg-lgb-yellow-100"
      }
    >
      <div>
        <div className="flex justify-between mb-4 items-center">
          <div className="flex justify-start items-center">
            <span className="border bg-lgb-grey-100 border-lgb-grey-300 px-4 py-1 font-bold rounded text-sm">
              {t("create.product_management.product")} {props.item.productId}
            </span>
            {props.item.productId === undefined && (
              <p className="pl-4 text-lgb-grey-400">
                ({t("create.product_management.not_saved")})
              </p>
            )}
          </div>
          <div className="flex items-center gap-4">
            <span className="font-medium">{t("common:quantity")}</span>
            <StyledTextInput
              id="quantity"
              type="number"
              defaultValue={props.item.quantity}
              onInputChanged={(e) => updateProduct("quantity", e)}
              validation={{
                id: "quantity",
                show: props.validationFailed || false,
                isInvalid: !props.item.quantity || props.item.quantity <= 0,
                errorMessage: t("common:errors.fields.number"),
              }}
            />
          </div>
        </div>
        <StandardForm
          hideButtons
          showHeader={false}
          grid_style="grid grid-cols-4 grid-rows-4 gap-4"
          fields={[
            {
              id: "descriptionShort",
              label: t("common:title"),
              grid_style: "col-span-2",
              input: (
                <StyledTextInput
                  id="descriptionShort"
                  disabled={props.item.productId !== undefined}
                  placeholder={t("common:placeholder.title")}
                  defaultValue={props.item.descriptionShort ?? ""}
                  onInputChanged={(e) => updateProduct("descriptionShort", e)}
                  validation={{
                    id: "descriptionShort",
                    show: props.validationFailed || false,
                    isInvalid: !(props.item.descriptionShort.length > 3),
                    errorMessage: t("validation:minimum_length").replace(
                      "_",
                      "3",
                    ),
                  }}
                />
              ),
            },
            {
              id: "weight",
              label: t("common:weight"),
              grid_style: "col-start-1 row-start-2",
              input: (
                <StyledTextInput
                  id="weight"
                  disabled={props.item.productId !== undefined}
                  placeholder={t("common:placeholder.weight")}
                  defaultValue={
                    props.item.weight === 0 ? undefined : props.item.weight
                  }
                  type="number"
                  onInputChanged={(e) => updateProduct("weight", +e)}
                  validation={{
                    id: "weight",
                    show: props.validationFailed || false,
                    isInvalid: !validateNumberBetween(
                      props.item.weight,
                      0,
                      10000,
                    ),
                    errorMessage: t("validation:product_weight"),
                  }}
                />
              ),
            },
            {
              id: "length",
              label: t("common:length"),
              grid_style: "col-start-2 row-start-2",
              input: (
                <StyledTextInput
                  id="length"
                  disabled={props.item.productId !== undefined}
                  placeholder={t("common:placeholder.length")}
                  defaultValue={
                    props.item.length === 0 ? undefined : props.item.length
                  }
                  type="number"
                  onInputChanged={(e) => updateProduct("length", +e)}
                  validation={{
                    id: "length",
                    show: props.validationFailed || false,
                    isInvalid:
                      validateHasValue(props.item.length.toString()) &&
                      !validateNumberBetween(props.item.length, 0, 10000),
                    errorMessage: t("validation:product_length"),
                  }}
                />
              ),
            },
            {
              id: "width",
              label: t("common:width"),
              grid_style: "col-start-1 row-start-3",
              input: (
                <StyledTextInput
                  id="width"
                  disabled={props.item.productId !== undefined}
                  placeholder={t("common:placeholder.width")}
                  defaultValue={
                    props.item.width === 0 ? undefined : props.item.width
                  }
                  type="number"
                  onInputChanged={(e) => updateProduct("width", +e)}
                  validation={{
                    id: "width",
                    show: props.validationFailed || false,
                    isInvalid:
                      validateHasValue(props.item.width.toString()) &&
                      !validateNumberBetween(props.item.width, 0, 10000),
                    errorMessage: t("common:errors.fields.number"),
                  }}
                />
              ),
            },
            {
              id: "height",
              label: t("common:height"),
              grid_style: "col-start-2 row-start-3",
              input: (
                <StyledTextInput
                  id="height"
                  disabled={props.item.productId !== undefined}
                  placeholder={t("common:placeholder.height")}
                  defaultValue={
                    props.item.height === 0 ? undefined : props.item.height
                  }
                  type="number"
                  onInputChanged={(e) => updateProduct("height", +e)}
                  validation={{
                    id: "height",
                    show: props.validationFailed || false,
                    isInvalid:
                      validateHasValue(props.item.height.toString()) &&
                      !validateNumberBetween(props.item.height, 0, 10000),
                    errorMessage: t("common:errors.fields.number"),
                  }}
                />
              ),
            },
            {
              id: "productType",
              label: t("common:productType_label"),
              tooltip: t("common:productType_tooltip"),
              input: (
                <CustomStyledSelect
                  id="productType"
                  disabled={props.item.productId !== undefined}
                  defaultValue={props.item.productType ?? ""}
                  emptyOption
                  options={props.productTypes.map((type) => ({
                    key: type.id,
                    label: type.code,
                  }))}
                  onSelectChanged={(e) => updateProduct("productType", e)}
                  validation={{
                    id: "productType",
                    show: props.validationFailed || false,
                    isInvalid:
                      props.item.productType === undefined ||
                      props.item.productType === "",
                    errorMessage: t("common:errors.fields.not_empty"),
                  }}
                />
              ),
            },
            {
              id: "pricing.priceModel",
              label: t("common:priceModel_label"),
              tooltip: t("common:priceModel_tooltip"),
              input: (
                <CustomStyledSelect
                  id="pricing.priceModel"
                  disabled={props.item.productId !== undefined}
                  defaultValue={props.item.pricing?.priceModel ?? []}
                  isMultiple
                  options={[
                    {
                      key: IPriceModel.FIXED,
                      label: t("common:price_model.fixed.title"),
                      description: t("common:price_model.fixed.description"),
                    },
                    {
                      key: IPriceModel.VOLUME,
                      label: t("common:price_model.volume.title"),
                      description: t("common:price_model.volume.description"),
                    },
                    {
                      key: IPriceModel.WEIGHT,
                      label: t("common:price_model.weight.title"),
                      description: t("common:price_model.weight.description"),
                    },
                    {
                      key: IPriceModel.DISTANCE,
                      label: t("common:price_model.distance.title"),
                      description: t("common:price_model.distance.description"),
                    },
                  ]}
                  onSelectChanged={(e) => {
                    let pricing = props.item.pricing ?? {} as IProductPricing;
                    pricing.priceModel = e as IPriceModel[];
                    updateProduct("pricing", pricing)
                  }}
                  validation={{
                    id: "pricing.priceModel",
                    show: props.validationFailed || false,
                    isInvalid:
                      props.item.pricing === undefined ||
                      props.item.pricing.priceModel === undefined ||
                      props.item.pricing.priceModel.length === 0,
                    errorMessage: t("common:errors.fields.not_empty"),
                  }}
                />
              ),
            },
            {
              id: "pickupStopNumber",
              label: t("common:pickupLocation"),
              tooltip: t("common:pickupLocation_tooltip"),
              grid_style: "col-start-3 row-start-4",
              input: (
                <StyledSelect
                  id="pickupStopNumber"
                  defaultValue={props.route.at(0)?.stopNumber.toString() ?? ""}
                  options={props.route.map((item) => ({
                    key: String(item.stopNumber),
                    label: item.location?.addressLine ?? "",
                    value: item.stopNumber.toString(),
                  }))}
                  onSelectChanged={(e) =>
                    updateItinerary("pickupStopNumber", parseInt(e))
                  }
                  validation={{
                    id: "pickupStopNumber",
                    show: props.validationFailed || false,
                    isInvalid:
                      props.item.itinerary !== undefined &&
                      props.item.itinerary.deliveryStopNumber <=
                        props.item.itinerary.pickupStopNumber,
                    errorMessage: t("common:errors.fields.itinerary_order"),
                  }}
                />
              ),
            },
            {
              id: "deliveryStopNumber",
              label: t("common:deliveryLocation"),
              tooltip: t("common:deliveryLocation_tooltip"),
              grid_style: "col-start-4 row-start-4",
              input: (
                <StyledSelect
                  id="deliveryStopNumber"
                  defaultValue={props.route.at(-1)?.stopNumber.toString() ?? ""}
                  options={props.route.map((item, idx) => ({
                    key: idx.toString(),
                    label: item.location?.addressLine ?? "",
                    value: idx.toString(),
                  }))}
                  onSelectChanged={(e) =>
                    updateItinerary("deliveryStopNumber", parseInt(e))
                  }
                  validation={{
                    id: "pickupStopNumber",
                    show: props.validationFailed || false,
                    isInvalid:
                      props.item.itinerary !== undefined &&
                      props.item.itinerary.deliveryStopNumber <=
                        props.item.itinerary.pickupStopNumber,
                    errorMessage: t("common:errors.fields.itinerary_order"),
                  }}
                />
              ),
            },
            {
              id: "pricing.pricePerCargoUnit",
              label: t("common:price_model.fixed.title"),
              hidden: !props.item.pricing?.priceModel?.includes(IPriceModel.FIXED),
              input: (
                <StyledTextInput
                  id="pricing.pricePerCargoUnit"
                  disabled={props.item.productId !== undefined}
                  placeholder={t("common:price_model.fixed.placeholder")}
                  defaultValue={
                    props.item.pricing?.pricePerCargoUnit === 0 ? undefined : props.item.pricing?.pricePerCargoUnit
                  }
                  type="number"
                  onInputChanged={(e) => {
                    let pricing = props.item.pricing ?? {} as IProductPricing;
                    pricing.pricePerCargoUnit = +e;
                    updateProduct("pricing", pricing)
                  }}
                  validation={{
                    id: "pricing.pricePerCargoUnit",
                    show: props.validationFailed || false,
                    isInvalid:
                      (props.item.pricing !== undefined &&
                        props.item.pricing.priceModel !== undefined &&
                        props.item.pricing.priceModel.includes(IPriceModel.FIXED) && 
                      validateHasValue(props.item.pricing.pricePerCargoUnit?.toString()) &&
                      !validateNumberBetween(props.item.pricing.pricePerCargoUnit ?? 0, 1, 10000)),
                    errorMessage: t("common:errors.fields.number"),
                  }}
                />
              )
            },
            {
              id: "pricing.pricePerVolumeUnit",
              label: t("common:price_model.volume.title"),
              hidden: !props.item.pricing?.priceModel?.includes(IPriceModel.VOLUME),
              input: (
                <StyledTextInput
                  id="pricing.pricePerVolumeUnit"
                  disabled={props.item.productId !== undefined}
                  placeholder={t("common:price_model.volume.placeholder")}
                  defaultValue={
                    props.item.pricing?.pricePerVolumeUnit === 0 ? undefined : props.item.pricing?.pricePerVolumeUnit
                  }
                  type="number"
                  onInputChanged={(e) => {
                    let pricing = props.item.pricing ?? {} as IProductPricing;
                    pricing.pricePerVolumeUnit = +e;
                    updateProduct("pricing", pricing)
                  }}
                  validation={{
                    id: "pricing.pricePerVolumeUnit",
                    show: props.validationFailed || false,
                    isInvalid:
                      (props.item.pricing !== undefined &&
                        props.item.pricing.priceModel !== undefined &&
                        props.item.pricing.priceModel.includes(IPriceModel.VOLUME) && 
                      validateHasValue(props.item.pricing.pricePerVolumeUnit?.toString()) &&
                      !validateNumberBetween(props.item.pricing.pricePerVolumeUnit ?? 0, 1, 10000)),
                    errorMessage: t("common:errors.fields.number"),
                  }}
                />
              )
            },
            {
              id: "pricing.pricePerWeightUnit",
              label: t("common:price_model.weight.title"),
              hidden: !props.item.pricing?.priceModel?.includes(IPriceModel.WEIGHT),
              input: (
                <StyledTextInput
                  id="pricing.pricePerWeightUnit"
                  disabled={props.item.productId !== undefined}
                  placeholder={t("common:price_model.weight.placeholder")}
                  defaultValue={
                    props.item.pricing?.pricePerWeightUnit === 0 ? undefined : props.item.pricing?.pricePerWeightUnit
                  }
                  type="number"
                  onInputChanged={(e) => {
                    let pricing = props.item.pricing ?? {} as IProductPricing;
                    pricing.pricePerWeightUnit = +e;
                    updateProduct("pricing", pricing)
                  }}
                  validation={{
                    id: "pricing.pricePerWeightUnit",
                    show: props.validationFailed || false,
                    isInvalid:
                      (props.item.pricing !== undefined &&
                        props.item.pricing.priceModel !== undefined &&
                        props.item.pricing.priceModel.includes(IPriceModel.WEIGHT) && 
                      validateHasValue(props.item.pricing.pricePerWeightUnit?.toString()) &&
                      !validateNumberBetween(props.item.pricing.pricePerWeightUnit ?? 0, 1, 10000)),
                    errorMessage: t("common:errors.fields.number"),
                  }}
                />
              )
            },
            {
              id: "pricing.pricePerDistanceUnit",
              label: t("common:price_model.distance.title"),
              hidden: !props.item.pricing?.priceModel?.includes(IPriceModel.DISTANCE),
              input: (
                <StyledTextInput
                  id="pricing.pricePerDistanceUnit"
                  disabled={props.item.productId !== undefined}
                  placeholder={t("common:price_model.distance.placeholder")}
                  defaultValue={
                    props.item.pricing?.pricePerDistanceUnit === 0 ? undefined : props.item.pricing?.pricePerDistanceUnit
                  }
                  type="number"
                  onInputChanged={(e) => {
                    let pricing = props.item.pricing ?? {} as IProductPricing;
                    pricing.pricePerDistanceUnit = +e;
                    updateProduct("pricing", pricing)
                  }}
                  validation={{
                    id: "pricing.pricePerDistanceUnit",
                    show: props.validationFailed || false,
                    isInvalid:
                      (props.item.pricing !== undefined &&
                        props.item.pricing.priceModel !== undefined &&
                        props.item.pricing.priceModel.includes(IPriceModel.DISTANCE) && 
                      validateHasValue(props.item.pricing.pricePerDistanceUnit?.toString()) &&
                      !validateNumberBetween(props.item.pricing.pricePerDistanceUnit ?? 0, 1, 10000)),
                    errorMessage: t("common:errors.fields.number"),
                  }}
                />
              )
            },
            {
              id: "description",
              label: t("inventory:product_description"),
              grid_style: "col-span-2 row-span-3 col-start-3 row-start-1",
              input: (
                <StyledTextArea
                  id="description"
                  defaultValue={props.item.description ?? ""}
                  onInputChanged={(e) => updateProduct("description", e)}
                />
              ),
            },
          ]}
        />
        {props.removable && (
          <p
            className="pt-6 text-sm font-normal underline cursor-pointer"
            onClick={props.remove}
          >
            {t("create.product_management.remove")}
          </p>
        )}
      </div>
    </Card>
  );
};

export default ProductCard;

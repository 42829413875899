import type { FC } from "react";
import { Table, Checkbox, Button } from "flowbite-react";
import { ITableData } from "../../../types";
import isSmallScreen from "../../../helpers/is-small-screen";

export const StandardTable: FC<ITableData> = function (props: ITableData) {
  return (
    <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600 ">
      <Table.Head>
        {props.canMultiSelect && !isSmallScreen() ? (
          <Table.HeadCell className=" dark:bg-slate-700 bg-gray-200 ">
            <Checkbox
              className="hover:cursor-pointer checked:bg-lgb-primary focus:ring-lgb-primary dark:checked:bg-lgb-primary dark:focus:ring-lgb-primary"
              id="select-all"
              name="select-all"
            />
          </Table.HeadCell>
        ) : null}
        {props.header.map((header) => (
          <Table.HeadCell
            className=" dark:bg-slate-700 bg-gray-200 text-darkBlue dark:text-slate-300"
            key={header.id}
            hidden={header.hidden || (isSmallScreen() && !header.showOnSmallScreen)}
          >
            {header.children}
          </Table.HeadCell>
        ))}
        {props.hasActions ? <Table.HeadCell
          className=" dark:bg-slate-700 bg-gray-200 text-darkBlue dark:text-slate-300"
        >
        </Table.HeadCell> : null}
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-darkBlue ">
        {props.rows.map((row) => (
          <Table.Row
            key={row.id}
            className={
              row.isActive
                ? "bg-gray-200 dark:bg-gray-600"
                : props.onRowClick
                  ? "hover:bg-gray-100 dark:hover:bg-slate-700 hover:cursor-pointer"
                  : ""
            }
            onClick={() => props.onRowClick?.(row.onRowClickData)}
          >
            {props.canMultiSelect && !isSmallScreen() ? (
              <Table.Cell className="w-4 p-4">
                <div className="flex items-center">
                  <Checkbox
                    className="hover:cursor-pointer  checked:bg-lgb-primary focus:ring-lgb-primary dark:checked:bg-lgb-primary dark:focus:ring-lgb-primary"
                    aria-describedby="checkbox-1"
                    id="checkbox-1"
                  />
                </div>
              </Table.Cell>
            ) : null}
            {row.cells.map((cell) => (
              <Table.Cell
                key={cell.id}
                hidden={cell.hidden || (isSmallScreen() && !cell.showOnSmallScreen)}
                className={
                  cell.classOverride
                    ? cell.classOverride
                    : "whitespace-nowrap p-4 text-base font-normal text-darkBlue dark:text-white"
                }
              >
                {cell.children}
              </Table.Cell>
            ))}
            {props.hasActions && row.actions ? (
              <Table.Cell className="w-4 text-right p-4 text-base font-medium text-gray-900 dark:text-white">
                <div className="flex items-right">
                  {row.actions.map((action) => (
                    <Button
                      key={action.id}
                      className="w-12 mr-2 ml-2"
                      color={action.color ? action.color : "primary"}
                      onClick={action.onActionClick}
                    >
                      {action.icon}
                    </Button>
                  ))}
                </div>
              </Table.Cell>
            ) : null}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

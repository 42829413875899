import { Timestamp } from ".";

export interface IDriver {
  id: string;
  workspaceId: string;
  companyId:  string;
  firstName: string;
  lastName: string;
  email: string;
  licenses: LicensesEnum[];
  vehicleId?: string;
  phoneNumber?: string;
  createdAt?: Timestamp | null;
}

export interface IDriverPropertyValidated {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
  licenses?: boolean;
  phoneNumber?: boolean;
}

export enum LicensesEnum {
  CLASS_AM = "AM", //Moped
  CLASS_A1 = "A1", //Motorcycle class A1
  CLASS_A2 = "A2", //Motorcyzle class A2
  CLASS_A = "A", //Motoycycle class A
  CLASS_B = "B", //Car
  CLASS_BE = "BE", //Car with trailer (tilhenger)
  CLASS_C1 = "C1", //Light lorry
  CLASS_C1E = "C1E", //Light lorry with trailer (tilhenger)
  CLASS_C = "C", //Lorry
  CLASS_CE = "CE", //Lorry with trailer (tilhenger)
  CLASS_D1 = "D1", //Minibus
  CLASS_D1E = "D1E", //Minibus with trailer (tilhenger)
  CLASS_D = "D", //Bus
  CLASS_DE = "DE", //Bus with trailer (tilhenger)
  CLASS_T = "T", //Tractor
  CLASS_S = "S", //Snowscooter
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderDetails, SplitTable, StatusBadge } from "../../../components";
import { Order } from "../../../models";
import { ITableRow } from "../../../types";
import moment from "moment";
import { HiChevronRight } from "react-icons/hi";
import pdfIcon from "../../../assets/icons/pdfIcon.svg";
import { Button } from "flowbite-react";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import { HiOutlineChevronDown } from "react-icons/hi";
import { OrderStatus } from "../../../types/order/IOrder";

interface OrderListProp {
  orders: Order[];
  isLoading: boolean;
  isOpen: (val: boolean) => void;
}

export const OrderList: FC<OrderListProp> = function (props: OrderListProp) {
  const { t } = useTranslation(["common", "orders", "validation"]);
  const [openedOrder, setOpenedOrder] = useState<Order | undefined>(undefined);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [hideColumns, setHideColumns] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Order[]>();

  const updateOpenedOrder = (order: Order | undefined) => {
    setOpenedOrder(order);
    if (order !== undefined) {
      setHideColumns(true);
      props.isOpen(true);
    } else {
      setHideColumns(false);
      props.isOpen(false);
    }
  };

  useEffect(() => {
    if (!props.isLoading && props.orders) {
      if (searchText.length) {
        let companies =
          props.orders?.filter(
            (x) =>
              x.customer.isCompany &&
              x.customer.name?.toLowerCase().includes(searchText.toLowerCase()),
          ) || [];
        let privatePersons =
          props.orders?.filter(
            (x) =>
              !x.customer.isCompany &&
              (x.customer.firstName + " " + x.customer.lastName)
                ?.toLowerCase()
                .includes(searchText.toLowerCase()),
          ) || [];
        setSearchResults(companies.concat(privatePersons));
      } else {
        setSearchResults(props.orders || []);
      }
    }
  }, [searchText, props.orders, props.isLoading]);

  useEffect(() => {
    if (!props.isLoading && searchResults) {
      setTableRows(
        searchResults.map((order) => {
          return {
            id: order.id,
            isActive: order.id === openedOrder?.id,
            onRowClickData: order,
            cells: [
              {
                id: "customer",
                children: order.customer.isCompany
                  ? order.customer.name
                  : order.customer.firstName + " " + order.customer.lastName,
                showOnSmallScreen: true,
              },
              {
                id: "status",
                children: <StatusBadge status={order.status} />,
                showOnSmallScreen: true,
              },
              {
                id: "date",
                children:
                  moment(order.route.at(0)?.stopDate).format("DD.MM.YYYY") ??
                  "",
              },
              {
                id: "from",
                children: order?.route[0].location.addressLine || "",
                hidden: hideColumns,
              },
              {
                id: "to",
                children:
                  order?.route[order.route.length - 1].location.addressLine ||
                  "",
                hidden: hideColumns,
              },
              {
                id: "open",
                children: <HiChevronRight size={20} />,
                hidden: hideColumns,
              },
            ],
          };
        }),
      );
    }
  }, [props.isLoading, searchResults, t, openedOrder, hideColumns]);

  const startOrder = () => {};
  const changeDriver = () => {};
  const deleteOrder = () => {};
  const editCargo = () => {};
  const editRoute = () => {};
  const updateStatus = () => {};
  const cancelOrder = () => {};
  const downloadPDF = () => {};
  const sendDeliveryLetter = () => {};

  return (
    <SplitTable
      searchable
      isLoading={props.isLoading}
      tableRows={tableRows}
      onRowClick={(order: Order | undefined) => updateOpenedOrder(order)}
      updateTable={(val) => setSearchText(val)}
      content={<OrderDetails order={openedOrder} />}
      topRightContent={
        <div className="flex items-center">
          <Button size="sm" color="light">
            <img src={pdfIcon} alt="PDF icon" className="mr-2" />
            {t("common:download_pdf")}
          </Button>
          {openedOrder && (
            <DropdownButton
              placement="bottom-end"
              buttonText={t("orders:actions")}
              buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
              items={[
                ...([
                  OrderStatus.Draft,
                  OrderStatus.New,
                  OrderStatus.Scheduled,
                ].includes(openedOrder?.status)
                  ? [
                      {
                        text: (
                          <p className="p-4 w-48">
                            {t("orders:scheduled_orders.start")}
                          </p>
                        ),
                        onClick: () => {
                          startOrder();
                        },
                      },
                      {
                        text: (
                          <p className="p-4 w-48">
                            {t("orders:scheduled_orders.change_driver")}
                          </p>
                        ),
                        onClick: () => {
                          changeDriver();
                        },
                      },
                      {
                        text: (
                          <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                            {t("orders:scheduled_orders.delete")}
                          </p>
                        ),
                        onClick: () => {
                          deleteOrder();
                        },
                      },
                    ]
                  : []),
                ...(openedOrder?.status === OrderStatus.InProgress
                  ? [
                      {
                        text: (
                          <p className="p-4 w-48">
                            {t("orders:ongoing_orders.edit_cargo")}
                          </p>
                        ),
                        onClick: () => {
                          editCargo();
                        },
                      },
                      {
                        text: (
                          <p className="p-4 w-48">
                            {t("orders:ongoing_orders.edit_route")}
                          </p>
                        ),
                        onClick: () => {
                          editRoute();
                        },
                      },
                      {
                        text: (
                          <p className="p-4 w-48">
                            {t("orders:ongoing_orders.update_status")}
                          </p>
                        ),
                        onClick: () => {
                          updateStatus();
                        },
                      },
                      {
                        text: (
                          <p className="p-4 w-48 text-lgb-red-600 dark:text-lgb-red-200 hover:text-lgb-red-300 hover:dark:text-lgb-red-300">
                            {t("orders:ongoing_orders.cancel")}
                          </p>
                        ),
                        onClick: () => {
                          cancelOrder();
                        },
                      },
                    ]
                  : []),

                ...(openedOrder?.status === OrderStatus.Completed
                  ? [
                      {
                        text: (
                          <p className="p-4 w-48">
                            {t("orders:completed_orders.download_pdf")}
                          </p>
                        ),
                        onClick: () => {
                          downloadPDF();
                        },
                      },
                      {
                        text: (
                          <p className="p-4 w-48">
                            {t("orders:completed_orders.send_delivery_letter")}
                          </p>
                        ),
                        onClick: () => {
                          sendDeliveryLetter();
                        },
                      },
                    ]
                  : []),
              ]}
            />
          )}
        </div>
      }
      tableHeaders={[
        {
          id: "customer",
          children: t("common:customer"),
          showOnSmallScreen: true,
        },
        {
          id: "status",
          children: t("common:status"),
          showOnSmallScreen: true,
        },
        {
          id: "date",
          children: t("common:date"),
        },
        {
          id: "from",
          children: t("common:from"),
          hidden: hideColumns,
        },
        {
          id: "to",
          children: t("common:to"),
          hidden: hideColumns,
        },
        {
          id: "open",
          children: <></>,
          hidden: hideColumns,
        },
      ]}
    />
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useEffect } from "react";
import { ToggleSwitch } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { StandardForm, Dialog, SelectCountry, StyledTextInput } from "../..";
import { ICustomer, InputValidationResult } from "../../../types";
import { useTranslation } from "react-i18next";
import { Customer } from "../../../models";
import {
  validateHasValue,
  validateEmail,
  validateOrganizationNumber,
  validatePostCode,
  validateStringLength,
  validateMobile,
  hasValidationError,
} from "../../../helpers/validationHelper";

export interface CustomerModalProp {
  data: ICustomer;
  isShowing: boolean;
  onConfirm: (data: ICustomer) => void;
  onCancel: () => void;
}

export const CustomerForm: FC<CustomerModalProp> = function (
  props: CustomerModalProp,
) {
  const isNew = Customer.isNew(props.data);
  const [isCompany, setIsCompany] = useState<boolean>(
    isNew ? true : (props.data?.isCompany ?? false),
  );

  const { t } = useTranslation(["common", "customer", "modals"]);

  const [isValidated, setIsValidated] = useState(false);
  const [validations, setValidations] = useState([] as InputValidationResult[]);
  useEffect(() => {
    if (!isNew) {
      setIsCompany(props.data?.isCompany ?? false);
    }
  }, [props.data?.isCompany, isNew]);

  const getValidationResults = (customer: ICustomer) => {
    return [
      {
        id: "name",
        show: isValidated,
        isInvalid:
          isCompany &&
          (!validateHasValue(customer.name) ||
            !validateStringLength(customer.name ?? "", 1)),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "firstName",
        show: isValidated,
        isInvalid:
          !isCompany &&
          (!validateHasValue(customer.firstName) ||
            !validateStringLength(customer.firstName ?? "", 2)),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "lastName",
        show: isValidated,
        isInvalid:
          !isCompany &&
          (!validateHasValue(customer.lastName) ||
            !validateStringLength(customer.lastName ?? "", 2)),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "organizationNumber",
        show: isValidated,
        isInvalid:
          isCompany &&
          (!validateHasValue(customer.organizationNumber) ||
            !validateOrganizationNumber(customer.organizationNumber ?? "")),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "invoiceEmail",
        show: isValidated,
        isInvalid:
          validateHasValue(customer.invoiceEmail) &&
          !validateEmail(customer.invoiceEmail ?? ""),
        errorMessage: t("common:errors.fields.email"),
      },
      {
        id: "contactEmail",
        show: isValidated,
        isInvalid:
          validateHasValue(customer.contactEmail) &&
          !validateEmail(customer.contactEmail ?? ""),
        errorMessage: t("common:errors.fields.email"),
      },
      {
        id: "phoneNumber",
        show: isValidated,
        isInvalid:
          validateHasValue(customer.phoneNumber) &&
          !validateMobile(customer.phoneNumber ?? ""),
        errorMessage: t("common:errors.fields.phone"),
      },
      {
        id: "primaryAddress.postCode",
        show: isValidated,
        isInvalid:
          validateHasValue(customer.primaryAddress?.postCode) &&
          !validatePostCode(customer.primaryAddress?.postCode ?? ""),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "primaryAddress.city",
        show: isValidated,
        isInvalid:
          validateHasValue(customer.primaryAddress?.city) &&
          !validateStringLength(customer.primaryAddress?.city ?? "", 2),
        errorMessage: t("common:errors.fields.email"),
      },
    ];
  };

  const validateAndConfirm = (customer: ICustomer) => {
    // Trigger validation
    const results = getValidationResults(customer);
    setValidations(results);
    // If valid form, we trigger the onConfirm
    if (!hasValidationError(results)) props.onConfirm(customer);
  };

  useEffect(() => {
    setIsValidated(true);
  }, [setValidations, validations]);

  return (
    <StandardForm
      showHeader={false}
      description={t("modals:descriptions.new_customer")}
      fields={[
        {
          id: "isCompany",
          grid_style: "col-span-2",
          isToggle: true,
          input: (
            <ToggleSwitch
              id="isCompany"
              name="isCompany"
              checked={isCompany}
              label={t("common:is_company")}
              onChange={setIsCompany}
              color="green"
            />
          ),
        },
        {
          id: "name",
          label: t("common:company_name"),
          hidden: !isCompany,
          input: (
            <StyledTextInput
              id="name"
              placeholder={t("common:placeholder.company_name")}
              defaultValue={props.data.name ?? ""}
              validation={validations.find(
                (validation) => validation.id === "name",
              )}
            />
          ),
        },
        {
          id: "firstName",
          label: t("common:firstname"),
          hidden: isCompany,
          input: (
            <StyledTextInput
              id="firstName"
              placeholder={t("common:placeholder.firstname")}
              defaultValue={props.data.firstName ?? ""}
              validation={validations.find(
                (validation) => validation.id === "firstName",
              )}
            />
          ),
        },
        {
          id: "lastName",
          label: t("common:lastname"),
          hidden: isCompany,
          input: (
            <StyledTextInput
              id="lastName"
              placeholder={t("common:placeholder.lastname")}
              defaultValue={props.data.lastName ?? ""}
              validation={validations.find(
                (validation) => validation.id === "lastName",
              )}
            />
          ),
        },
        {
          id: "organizationNumber",
          label: t("common:organization_number"),
          hidden: !isCompany,
          input: (
            <StyledTextInput
              id="organizationNumber"
              placeholder={t("common:placeholder.organization_number")}
              defaultValue={props.data.organizationNumber ?? ""}
              validation={validations.find(
                (validation) => validation.id === "organizationNumber",
              )}
            />
          ),
        },
        {
          id: "contactEmail",
          label: t("common:email"),
          input: (
            <StyledTextInput
              id="contactEmail"
              placeholder={t("common:placeholder.email")}
              defaultValue={props.data.contactEmail ?? ""}
              validation={validations.find(
                (validation) => validation.id === "contactEmail",
              )}
            />
          ),
        },
        {
          id: "invoiceEmail",
          label: t("common:invoice_email"),
          input: (
            <StyledTextInput
              id="invoiceEmail"
              placeholder={t("common:placeholder.email")}
              defaultValue={props.data.invoiceEmail ?? ""}
              validation={validations.find(
                (validation) => validation.id === "invoiceEmail",
              )}
            />
          ),
        },
        {
          id: "phoneNumber",
          label: t("common:phone"),
          input: (
            <StyledTextInput
              id="phoneNumber"
              placeholder={t("common:placeholder.phone")}
              defaultValue={props.data.phoneNumber ?? ""}
              validation={validations.find(
                (validation) => validation.id === "phoneNumber",
              )}
            />
          ),
        },
        {
          id: "primaryAddress.careOf",
          label: t("common:care_of"),
          input: (
            <StyledTextInput
              id="primaryAddress.careOf"
              placeholder={t("common:placeholder.care_of")}
              defaultValue={props.data.primaryAddress?.careOf ?? ""}
            />
          ),
        },
        {
          id: "primaryAddress.addressLine",
          label: t("common:address_line"),
          grid_style: "col-span-2",
          input: (
            <StyledTextInput
              id="primaryAddress.addressLine"
              placeholder={t("common:placeholder.address_line")}
              defaultValue={props.data.primaryAddress?.addressLine ?? ""}
              validation={validations.find(
                (validation) => validation.id === "primaryAddress.addressLine",
              )}
            />
          ),
        },
        {
          id: "primaryAddress.postCode",
          label: t("common:postcode"),
          input: (
            <StyledTextInput
              id="primaryAddress.postCode"
              placeholder={t("common:placeholder.postcode")}
              defaultValue={props.data.primaryAddress?.postCode ?? ""}
              validation={validations.find(
                (validation) => validation.id === "primaryAddress.postCode",
              )}
            />
          ),
        },
        {
          id: "primaryAddress.city",
          label: t("common:city"),
          input: (
            <StyledTextInput
              id="primaryAddress.city"
              placeholder={t("common:placeholder.city")}
              defaultValue={props.data.primaryAddress?.city ?? ""}
              validation={validations.find(
                (validation) => validation.id === "primaryAddress.city",
              )}
            />
          ),
        },
        {
          id: "primaryAddress.countryCode",
          label: t("common:country"),
          input: (
            <SelectCountry
              id="primaryAddress.countryCode"
              defaultValue={props.data.primaryAddress?.countryCode ?? "NO"}
            />
          ),
        },
      ]}
      onConfirm={validateAndConfirm}
      onCancel={props.onCancel}
      buttonConfirmText={t("modals:button_texts.customer")}
      buttonConfirmPrependIcon={
        isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null
      }
      buttonCloseText={t("common:close")}
    />
  );
};

export const CustomerModal: FC<CustomerModalProp> = function (
  props: CustomerModalProp,
) {
  const { t } = useTranslation(["common", "customer"]);

  return (
    <Dialog
      title={
        Customer.isNew(props.data)
          ? t("customer:new_customer")
          : t("customer:update_customer")
      }
      content={<CustomerForm {...props} />}
      show={props.isShowing}
      toggleModal={props.onCancel}
      hideButtons={true}
    />
  );
};

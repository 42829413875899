import type { FC } from "react";
import { useState, useEffect } from "react";
import { Checkbox, Radio, Label, TextInput } from "flowbite-react";
import { HiChevronDown } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { InputValidationResult } from "../../../types";

interface SelectProps {
  id: string;
  defaultValue?: string | Array<string>;
  emptyOption?: boolean | false;
  options: Array<SelectOpionProp>;
  isMultiple?: boolean;
  validation?: InputValidationResult;
  placeholder?: string;
  disabled?: boolean;
  onSelectChanged?: (values: Array<string>) => void;
}

interface SelectOpionProp {
  key: string;
  value?: string;
  label: string;
  description?: string;
}

export const CustomStyledSelect: FC<SelectProps> = function (
  props: SelectProps,
) {
  const { t } = useTranslation(["common"]);

  const getDefaultValue = (defaultValue: any) => {
    if (defaultValue === undefined) return [];
    if (Array.isArray(defaultValue)) {
      return defaultValue;
    }
    return [defaultValue];
  };

  const getDisplayValue = (values: Array<string>) => {
    if (values.length === 0) {
      return "";
    }
    if (values.length === 1) {
      return props.options.filter((x) => x.key === values[0])[0]?.label;
    } else {
      return values.length + " " + t("common:selected");
    }
  };

  const [show, setShow] = useState(false);
  const [value, setValue] = useState(getDefaultValue(props.defaultValue));
  const [displayValue, setDisplayValue] = useState(
    getDisplayValue(getDefaultValue(props.defaultValue)),
  );

  const getValidationMessage = () => {
    if (props.validation?.isMissing) {
      return props.validation?.fieldName + " " + t("common:errors.required");
    }
    if (props.validation?.isInvalid) {
      if (props.validation?.errorMessage !== undefined) {
        return props.validation?.errorMessage;
      }
      return props.validation?.fieldName + " " + t("common:errors.invalid");
    }
    return "";
  };

  const onSelectOption = (option: SelectOpionProp) => {
    var items = value;
    if (props.isMultiple) {
      if (items.includes(option.key)) {
        // Remove value if it already exists
        items = items.filter((x) => x !== option.key);
      } else {
        // Add value if it does not
        items = [...items, option.key];
      }
    } else {
      items = [option.key];
      setShow(false);
    }

    if (props.onSelectChanged) {
      props.onSelectChanged(items);
    }
    setValue(items);
  };

  useEffect(() => {
    setDisplayValue(getDisplayValue(value));
  }, [value, setValue]);

  return (
    <div className="relative">
      <select
        id={props.id}
        name={props.id}
        value={value}
        multiple={props.isMultiple}
        hidden
      >
        {props.emptyOption ? <option value=""> </option> : null}
        {props.options.map((option) => (
          <option key={option.key} value={option.value ?? option.key}>
            {option.label}
          </option>
        ))}
      </select>
      <TextInput
        id="custom-input"
        placeholder={props.placeholder}
        value={displayValue}
        type={"text"}
        rightIcon={HiChevronDown}
        disabled={props.disabled ?? false}
        readOnly
        color={
          props.validation?.show &&
          (props.validation?.isMissing || props.validation?.isInvalid)
            ? "failure"
            : ""
        }
        helperText={props.validation?.show ? getValidationMessage() : ""}
        onBlur={(e) => {
          //Keep input focus if clicking inside popup.
          if (e.relatedTarget && e.relatedTarget?.id !== "custom-input") {
            e.target.focus();
          } else if (show) {
            //Close popup if clicking outside
            setShow(false);
          }
        }}
        onClick={() => setShow(!show)}
      />
      {show && (
        <div className="overflow-scroll max-h-96 w-full bottom-12 origin-top-right absolute shadow-sm border border-gray-300 bg-white dark:bg-slate-800 dark:text-white dark:border-slate-600 rounded-lg z-10">
          {props.options.map((option) => {
            return (
              <div
                key={option.key}
                tabIndex={0}
                className="flex gap-2 px-2 py-2 hover:bg-gray-100 dark:hover:bg-slate-600 hover:cursor-pointer rounded-tl-lg"
              >
                {props.isMultiple && (
                  <Checkbox
                    className="mt-1 text-lgb-primary dark:text-lgb-primary-lighten"
                    id={option.label}
                    defaultChecked={value.includes(option.key)}
                    onClick={(e) => onSelectOption(option)}
                  />
                )}
                {!props.isMultiple && (
                  <Radio
                    className="mt-1 text-lgb-primary dark:text-lgb-primary-lighten"
                    id={option.label}
                    defaultChecked={value.includes(option.key)}
                    onClick={(e) => onSelectOption(option)}
                  ></Radio>
                )}
                <Label
                  htmlFor={option.label}
                  className="w-full hover:cursor-pointer"
                >
                  <span>
                    {option.label}
                    {option.description !== undefined &&
                      option.description !== "" && (
                        <span className="opacity-60">
                          <br />
                          {option.description}
                        </span>
                      )}
                  </span>
                </Label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";

export interface SimpleListItem {
  title: string;
  value: string | number | React.ReactNode;
  redirectPath?: string;
}

export interface SimpleListProp {
  items: SimpleListItem[];
}

export const SimpleList: FC<SimpleListProp> = (props) => {
  return (
    <ul className="w-full divide-y divide-gray-200 dark:divide-gray-700 dark:text-lgb-grey-100">
      {props.items.map((item) => {
        return (
          <div key={item.title} className="py-4">
            <div className="grid grid-cols-2 justify-between ">
              <p>{item.title}</p>
              {item.redirectPath && (
                <div className="text-end">
                  <a
                    href={item.redirectPath}
                    className="underline text-lgb-blue-300 hover:text-lgb-blue-400 visited:text-purple-600 dark:text-lgb-blue-300 dark:hover:text-lgb-blue-200 dark:visited:text-purple-200"
                  >
                    {item.value}
                  </a>
                </div>
              )}
              {!item.redirectPath && <p className="text-end">{item.value}</p>}
            </div>
          </div>
        );
      })}
    </ul>
  );
};

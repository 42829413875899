import type { FC, PropsWithRef } from "react";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../types";
import CardWithTooltipCopy from "../../atoms/Utilities/CardWithTooltipCopyProps";
import {
  UserAvatar,
  VerifiedBadge,
  NotVerifiedBadge,
} from "../../atoms/Icons/Icons";

export const UserProfile: FC<PropsWithRef<IUser>> = function (user: IUser) {
  const { t } = useTranslation("user");
  return (
    <>
      <div className="bg-none">
        <UserAvatar className="h-24" />
        <div className="flex flex-row items-center gap-4 flex-wrap">
          <h2 className="text-3xl font-semibold dark:text-white">
            {user.displayName ?? user.email}
          </h2>
          {user.emailVerified ? (
            <div className="flex flex-row gap-2 items-center">
              <VerifiedBadge />
              <p className="dark:text-white">{t("profile.verified")}</p>
            </div>
          ) : (
            <div className="flex flex-row gap-2 items-center">
              <NotVerifiedBadge />
              <p className="dark:text-white">{t("profile.not_verified")}</p>
            </div>
          )}
        </div>

        <div className="flex md:flex-row gap-4 w-full flex-wrap  ">

          <div id="left" className="gap-4 flex-grow flex flex-col">
            <h3 className="uppercase text-lg dark:text-slate-400">
              {t("profile.user_details")}
            </h3>
            <CardWithTooltipCopy title={t("users.name")} data={user.displayName ?? user.email}  />
            <CardWithTooltipCopy title={t("users.email")} data={user.email} />
          </div> 

          <div id="right" className=" gap-4 flex-grow flex flex-col">
            <h3 className="uppercase text-lg dark:text-slate-400">
              {" "}
              {t("profile.general")}
            </h3>
            <CardWithTooltipCopy
              title={t("users.createdAt")}
              data={"14.02.2024"}
              showCopyIcon={false}
            />
            <CardWithTooltipCopy
              title="Role"
              data={"Admin"}
              showCopyIcon={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "flowbite-react";
import { type FC } from "react";
import { HiOutlinePlus } from 'react-icons/hi';

export interface EmptyStateProp {
  icon: React.ReactNode;
  buttonText: string;
  title: string;
  description?: string;
  buttonClick: () => void;
}

export const EmptyState: FC<EmptyStateProp> = (props) => {

  return (
    <div className="flex flex-col justify-center items-center gap-2 fit py-8">
      {props.icon}
      <p className="lgb-title text-center">{props.title}</p>
      <p className="lgb-description text-center">{props.description}</p>
      <Button className="mt-4" color="mainGreen" onClick={() => props.buttonClick()} type="submit" >
        <HiOutlinePlus className="mr-2 h-5 w-5" />
        {props.buttonText}
      </Button>
    </div>
  );
};
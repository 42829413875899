//https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component

import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { IWorkspaceAccess } from "../types";

interface WorkspaceContextProps {
  activeWorkspace: IWorkspaceAccess | undefined;
  setActiveWorkspace: (workspace: IWorkspaceAccess | undefined) => void;
}

const WorkspaceContext = createContext<WorkspaceContextProps>(undefined!);

export function WorkspaceProvider({ children }: PropsWithChildren) {
  const [activeWorkspace, setActiveWorkspace] = useState(
    window.localStorage.getItem("workspace")
      ? (JSON.parse(
          window.localStorage.getItem("workspace") ?? "{}",
        ) as IWorkspaceAccess)
      : undefined,
  );

  // Save latest state to localStorage
  useEffect(() => {
    if (activeWorkspace) {
      window.localStorage.setItem("workspace", JSON.stringify(activeWorkspace));
    } else {
      window.localStorage.removeItem("workspace");
    }
  }, [activeWorkspace]);

  return (
    <WorkspaceContext.Provider
      value={{
        activeWorkspace: activeWorkspace,
        setActiveWorkspace: setActiveWorkspace,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
}

export function useWorkspace(): WorkspaceContextProps {
  const context = useContext(WorkspaceContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useWorkspace should be used within the WorkspaceContext provider!",
    );
  }

  return context;
}

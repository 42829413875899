import type { FC } from "react";
import { Select } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { InputValidationResult } from "../../../types";

interface SelectProps {
  id: string;
  defaultValue?: string;
  emptyOption?: boolean | false;
  options: Array<SelectOpionProp>;
  validation?: InputValidationResult;
  onSelectChanged?: (input: string) => void;
}

interface SelectOpionProp {
  key: string;
  value?: string;
  label: string;
}

export const StyledSelect: FC<SelectProps> = function (props: SelectProps) {
  const { t } = useTranslation(["common"]);

  const getValidationMessage = () => {
    if (props.validation?.isMissing) {
      return props.validation?.fieldName + " " + t("common:errors.required");
    }
    if (props.validation?.isInvalid) {
      if (props.validation?.errorMessage !== undefined) {
        return props.validation?.errorMessage;
      }
      return props.validation?.fieldName + " " + t("common:errors.invalid");
    }
    return "";
  };

  return (
    <Select
      id={props.id}
      name={props.id}
      defaultValue={props.defaultValue ?? props.options[0]?.key}
      color={
        props.validation?.show &&
        (props.validation?.isMissing || props.validation?.isInvalid)
          ? "failure"
          : ""
      }
      helperText={props.validation?.show ? getValidationMessage() : ""}
      onChange={(e) =>
        props.onSelectChanged ? props.onSelectChanged(e.target.value) : null
      }
    >
      {props.emptyOption ? <option value=""> </option> : null}
      {props.options.map((option) => (
        <option key={option.key} value={option.value ?? option.key}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

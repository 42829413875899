import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { Order } from "../models";
import { OrderStatus } from "../types/order/IOrder";
import { useCache } from "../context/CacheContext";

export const useOrders = (
  workspaceId: string,
  statuses: OrderStatus[] = [],
) => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData(
    [Order.collectionName, cacheKey, workspaceId, statuses],
    Order.list(workspaceId, statuses),
    {
      subscribe: false,
    },
  );
};

import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import {
  Timestamp,
  IDriver,
  LicensesEnum,
  IDriverPropertyValidated,
} from "../types";
import { query, doc } from "firebase/firestore";
import {
  validateEmail,
  validateMobile,
  validateStringLength,
} from "../helpers/validationHelper";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Driver implements IDriver {
  id: string;
  workspaceId: string;
  companyId:  string;
  firstName: string;
  lastName: string;
  email: string;
  licenses: LicensesEnum[];
  vehicleId?: string;
  phoneNumber?: string;
  createdAt?: Timestamp | null;

  static collectionName = "drivers";

  static converter = {
    toFirestore(driver: Driver) {
      return driver.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IDriver;
      return new Driver({ ...data, id: snapshot.id });
    },
  };

  static createId() {
    return firestore.collection(Driver.collectionName).doc().id;
  }

  constructor({
    id,
    workspaceId,
    companyId,
    firstName,
    lastName,
    email,
    licenses,
    vehicleId: vehcileId,
    phoneNumber,
    createdAt,
  }: IDriver) {
    this.id = id;
    this.workspaceId = workspaceId;
    this.companyId = companyId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.licenses = licenses;
    this.vehicleId = vehcileId || undefined;
    this.phoneNumber = phoneNumber || undefined;
    this.createdAt = createdAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IDriver>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      workspaceId: this.workspaceId,
      companyId: this.companyId,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      licenses: this.licenses || [],
      vehicleId: this.vehicleId || null,
      phoneNumber: this.phoneNumber || null,
      createdAt: this.createdAt || null,
    };
  }

  static default() {
    return new Driver({
      id: "",
      workspaceId: "",
      companyId: "",
      firstName: "",
      lastName: "",
      email: "",
      licenses: [],
      vehicleId: undefined,
      createdAt: undefined,
    });
  };

  static isNew(data: IDriver): boolean {
    return data.id.length === 0;
  }

  static get = (id: string) => {
    return doc(
      firestore
        .collection(Driver.collectionName)
        .withConverter(Driver.converter),
      id,
    );
  };

  static list = (workspaceId: string) => {
    return query(
      firestore
        .collection(Driver.collectionName)
        .where("workspaceId", "==", workspaceId)
        .withConverter(Driver.converter),
    );
  };

  static validateDriver(driver: IDriver) {

    let validationValues = {
      firstName: validateStringLength(driver.firstName, 2),
      lastName: validateStringLength(driver.lastName, 2),
      phoneNumber: validateMobile(driver.phoneNumber || ""),
      email: validateEmail(driver.email || ""),
    } as IDriverPropertyValidated;

    let invalid = Object.entries({
      ...validationValues,
    });
    return {
      isValidDriver: invalid.filter(([key, value]) => !value).length === 0,
      invalidFields: Object.fromEntries(invalid),
    };
  }

  static create = async (workspaceId: string, data: Omit<IDriver, "id" | "createdAt">) => {
    const newDriver = new Driver({
      ...data,
      workspaceId: workspaceId,
      id: Driver.createId(),
      createdAt: Timestamp.now(),
    });
    try {
      await firestore
        .collection(Driver.collectionName)
        .doc(newDriver.id)
        .withConverter(Driver.converter)
        .set(newDriver, { merge: true });
      return true;
    } catch (e) {
      console.warn("Create driver failed with error: ", e);
      return false;
    }
  };

  static update = async (
    driver : Driver,
    updates: Partial<IDriver>
  ) => {
    const updateType =
      (driver.clone && driver.clone()) || new Driver({ ...driver });

      updateType.setData({
      ...updates
    });

    await firestore
      .collection(Driver.collectionName)
      .doc(driver.id)
      .withConverter(Driver.converter)
      .set(updateType, { merge: true });

    return updateType;
  };

  static delete = async (driver : Driver) => {
    return await firestore
        .collection(Driver.collectionName)
        .doc(driver.id)
        .delete();
  };
}

export default Driver;

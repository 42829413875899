import { type FC } from "react";
import { Button } from "flowbite-react";
import { Dialog } from "../..//molecules/Dialog";

export interface DialogNotifyProp {
  show: boolean;
  buttonConfirmColor?: string;
  buttonConfirmText?: string;
  buttonConfirmPrependIcon?: React.ReactNode;
  buttonCloseText?: string;
  buttonClosePrependIcon?: React.ReactNode;
  icon?: React.ReactNode;
  iconBackground?: string;
  text: string;
  toggleModal: () => void;
  confirmButton: (data?: any) => void;
}

export const DialogNotify: FC<DialogNotifyProp> = (props) => {
  const dialogContent = (
    <div className="flex flex-col items-center justify-between gap-6">
      {props.icon}
      <p className="text-xl font-medium text-gray-900">{props.text}</p>
      <div className="flex gap-4">
        {props.buttonCloseText && (
          <Button color="gray" onClick={() => props.toggleModal()}>
            {props.buttonClosePrependIcon}
            {props.buttonCloseText}
          </Button>
        )}
        {props.buttonConfirmText && (
          <Button
            onClick={() => props.confirmButton()}
            color={
              props.buttonConfirmColor ? props.buttonConfirmColor : "primary"
            }
            type="submit"
          >
            {props.buttonConfirmPrependIcon}
            {props.buttonConfirmText}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <Dialog
      size="xl"
      denseHeader
      content={dialogContent}
      show={props.show}
      toggleModal={props.toggleModal}
      hideButtons={true}
    />
  );
};

import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { Workspace } from "../models";
import { useCache } from "../context/CacheContext";

const useWorkspaces = () => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData([Workspace.collectionName, cacheKey], Workspace.list(), {
    subscribe: false,
  });
};

export default useWorkspaces;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState, type FC } from "react";
import { Driver, Order, Vehicle } from "../../../models";
import { SimpleList } from "../SimpleList";
import { useTranslation } from "react-i18next";
import { IOrderProduct } from "../../../types";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { useDrivers, useVehicles } from "../../../hooks";
import { HiOutlineCalendar } from "react-icons/hi";
import moment from "moment";
import OrderStatusCard from "../Cards/OrderStatusCard";
import { RouteTimeline } from "./RouteTimeline";
import { GoodsOverview } from "../GoodsOverview";
import { ImageList } from "../ImageList";
import { OrderStatus } from "../../../types/order/IOrder";
import { HiCheck } from "react-icons/hi";
import { Tooltip } from "flowbite-react";
import { CopyIcon } from "../../../components/atoms/Icons/Icons";
export interface OrderDetailsProps {
  order: Order | undefined;
}

export const OrderDetails: FC<OrderDetailsProps> = (props) => {
  const { t } = useTranslation(["common"]);
  const { activeWorkspace } = useWorkspace();
  const [driver, setDriver] = useState<Driver | undefined>();
  const [vehicle, setVehicle] = useState<Vehicle | undefined>();
  const [hasCopied, setHasCopied] = useState(false);
  const vehiclesQuery = useVehicles(activeWorkspace?.workspaceId ?? "");
  const query = useDrivers(activeWorkspace?.workspaceId ?? "");
  const isQueryLoading = useMemo(
    () =>
      query.isLoading ||
      query.isRefetching ||
      vehiclesQuery.isLoading ||
      vehiclesQuery.isRefetching,
    [query, vehiclesQuery],
  );
  const drivers = useMemo(() => query.data ?? [], [query]);
  const vehicles = useMemo(() => vehiclesQuery.data ?? [], [vehiclesQuery]);

  const calculateTotalVolume = (products: IOrderProduct[]) => {
    const total = products.reduce(
      (partialSum, item) =>
        partialSum + item.quantity > 0
          ? (item.length / 100) *
            (item.height / 100) *
            (item.width / 100) *
            item.quantity
          : 0,
      0,
    );
    return total > 0 && typeof total === "number" ? total.toFixed(3) : 0;
  };

  const calculateTotalWeight = (products: IOrderProduct[]) => {
    const total = products.reduce(
      (partialSum, item) =>
        partialSum + item.quantity > 0 ? item.weight * item.quantity : 0,
      0,
    );
    return total > 0 && typeof total === "number" ? total : 0;
  };

  useEffect(() => {
    if (!isQueryLoading) {
      if (drivers) {
        setDriver(drivers.find((x) => x.email === props.order?.driverId));
      }
      if (vehicles && driver) {
        setVehicle(vehicles.find((x) => x.id === driver.vehicleId));
      }
    }
    setHasCopied(false);
  }, [isQueryLoading, drivers, vehicles, driver, props.order]);

  return (
    <>
      <div className="xl:flex justify-between items-center gap-4 pt-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {props.order?.id}
          </p>
          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-white rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <CopyIcon
                  className="w-6 h-6 cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(props.order?.id ?? "");
                    setHasCopied(true);
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
        <div className="flex gap-2 items-center dark:text-lgb-grey-100 mt-2 xl:mt-0">
          <HiOutlineCalendar size={20} />
          <p>
            {moment(props.order?.route.at(0)?.stopDate).format("DD.MM.YYYY") ??
              ""}
          </p>
        </div>
      </div>

      <OrderStatusCard status={props.order?.status} />

      <RouteTimeline
        route={props.order?.route ?? []}
        cargo={props.order?.cargo ?? []}
      />

      {/* TODO */}
      {/* <img alt="Order" /> */}

      <p className="text-2xl mb-4 font-medium dark:text-white">
        {t("common:general")}
      </p>
      <SimpleList
        items={[
          {
            title: t("common:from"),
            value: props.order?.route[0].location.addressLine || "",
            redirectPath: "/resources/locations",
          },
          {
            title: t("common:to"),
            value:
              props.order?.route[props.order.route.length - 1].location
                .addressLine || "",
            redirectPath: "/resources/locations",
          },

          ...(props.order?.comment.length
            ? [
                {
                  title: t("common:comment"),
                  value: props.order?.comment ?? "",
                },
              ]
            : []),

          ...(driver !== undefined
            ? [
                {
                  title: t("common:driver"),
                  value: driver?.firstName + " " + driver?.lastName ?? "",
                  redirectPath: "/resources/drivers",
                },
              ]
            : []),

          ...(vehicle !== undefined
            ? [
                {
                  title: t("common:vehicle"),
                  value: vehicle.registrationNumber ?? "",
                  redirectPath: "/resources/vehicles",
                },
              ]
            : []),
          // {
          //   title: t("common:picked_up"),
          //   value: "11:22", //TODO
          // },
          // {
          //   title: t("common:delivered"),
          //   value: "16:50", //TODO
          // },
          {
            title: t("common:amount_of_goods"),
            value: props.order?.cargo.length ?? 0,
          },
          {
            title: t("common:total_weight"),
            value:
              (props.order?.cargo
                ? calculateTotalWeight(props.order?.cargo)
                : 0) + " kg",
          },
          {
            title: t("common:total_volume"),
            value: (
              <p>
                {props.order?.cargo
                  ? calculateTotalVolume(props.order?.cargo)
                  : 0}{" "}
                m<sup>3</sup>
              </p>
            ),
          },
          // {
          //   title: t("common:wait_time"),
          //   value: "23 minutes", //TODO
          // },
          // {
          //   title: t("common:extra_services"),
          //   value: "No", //TODO
          // },
          // {
          //   title: t("common:delivery_documents_sent_to"),
          //   value: "post@tskuteservice.no", //TODO
          // },
        ]}
      />

      <div className="my-8">
        <div className="flex justify-between items-center">
          <p className="text-2xl mb-4 font-medium dark:text-white">
            {t("common:cargo")}
          </p>
          {props.order?.status !== OrderStatus.Completed && (
            <p
              onClick={() => console.log("Edit cargo")}
              className="underline cursor-pointer text-lgb-blue-300 hover:text-lgb-blue-400 visited:text-purple-600 dark:text-lgb-blue-300 dark:hover:text-lgb-blue-200 dark:visited:text-purple-200"
            >
              {t("common:cargo_edit")}
            </p>
          )}
        </div>

        <GoodsOverview cargo={props.order?.cargo ?? []} />
      </div>

      <div className="my-8">
        <div className="flex justify-between items-center">
          <p className="text-2xl mb-4 font-medium dark:text-white">
            {t("common:images")}
          </p>
          {/* {props.order?.status !== OrderStatus.Completed && (
            <p
              onClick={() => console.log("Edit images")}
              className="underline cursor-pointer text-lgb-blue-300 hover:text-lgb-blue-400 visited:text-purple-600 dark:text-lgb-blue-300 dark:hover:text-lgb-blue-200 dark:visited:text-purple-200"
            >
              {t("common:images_edit")}
            </p>
          )} */}
        </div>
        <ImageList
          imgUrls={[
            //TEMP
            "https://picsum.photos/720/1080",
            "https://picsum.photos/1080/1080",
          ]}
        />
      </div>
    </>
  );
};

import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import { Timestamp, IAddress, ICompany } from "../types";
import { query, doc } from "firebase/firestore";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Company implements ICompany {
  id: string;
  workspaceId: string;
  name: string;
  contactEmail?: string | null;
  invoiceEmail?: string | null;
  primaryAddress: IAddress | null;
  createdAt?: Timestamp;
  organizationNumber: string;

  static collectionName = "companies";

  static converter = {
    toFirestore(company: Company) {
      return company.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as ICompany;
      return new Company({ ...data, id: snapshot.id });
    },
  };

  static createId() {
    return firestore.collection(Company.collectionName).doc().id;
  }

  constructor({
    id,
    workspaceId,
    name,
    contactEmail,
    invoiceEmail,
    primaryAddress,
    createdAt,
    organizationNumber,
  }: ICompany) {
    this.id = id;
    this.workspaceId = workspaceId;
    this.name = name ?? "";
    this.organizationNumber = organizationNumber;
    this.contactEmail = contactEmail || null;
    this.invoiceEmail = invoiceEmail || null;
    this.primaryAddress = primaryAddress || null;
    this.createdAt = createdAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<ICompany>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      workspaceId: this.workspaceId,
      name: this.name,
      organizationNumber: this.organizationNumber,
      invoiceEmail: this.invoiceEmail || null,
      contactEmail: this.contactEmail || null,
      primaryAddress: this.primaryAddress || null,
      createdAt: this.createdAt || null,
    };
  }

  static default = (): Company => {
    return new Company ({
      id: "",
      workspaceId: "",
      name: "",
      organizationNumber: "",
      createdAt: undefined
    });
  };

  static isNew(data: ICompany): boolean {
    return data.id.length === 0;
  }

  static get = (id: string) => {
    return doc(
      firestore
        .collection(Company.collectionName)
        .withConverter(Company.converter),
      id,
    );
  };

  static list = (workspaceId: string) => {
    return query(
      firestore
        .collection(Company.collectionName)
        .where("workspaceId", "==", workspaceId)
        .withConverter(Company.converter),
    );
  };

  static create = async (workspaceId: string, data: Omit<ICompany, "id" | "createdAt">) => {
    const newCompany = new Company({
      ...data,
      workspaceId: workspaceId,
      id: Company.createId(),
      createdAt: Timestamp.now(),
    });

    await firestore
      .collection(Company.collectionName)
      .doc(newCompany.id)
      .withConverter(Company.converter)
      .set(newCompany, { merge: true });

    return newCompany;
  };

  static update = async (
    company : Company,
    updates: Partial<ICompany>
  ) => {
    const updateType =
      (company.clone && company.clone()) || new Company({ ...company });

      updateType.setData({
      ...updates
    });

    await firestore
      .collection(Company.collectionName)
      .doc(company.id)
      .withConverter(Company.converter)
      .set(updateType, { merge: true });

    return updateType;
  };

  static delete = async (company : Company) => {
    return await firestore
        .collection(Company.collectionName)
        .doc(company.id)
        .delete();
  };
}

export default Company;

import { InputValidationResult } from "../types";

function validateHasValue(val: string | undefined | null) {
  return !(val === undefined || val === null || val.length === 0);
}

function validateEmail(email: string) {
  return /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(email) || false;
}

function validateMobile(mobile: string) {
  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(mobile) || false;
}

function validatePostCode(code: string) {
  return validateStringLength(code, 2, 8);
}

function validateStringLength(val: string, from: number, to?: number) {
  return (
    val.replace(/\s/g, "").length >= from &&
    (to ? val.replace(/\s/g, "").length <= to : true)
  );
}

function validateOrganizationNumber(val: string) {
  return validateStringLength(val, 6, 15);
}

function validateNumberBetween(val: number, from: number, to: number) {
  return val >= from && val <= to;
}

function hasValidationError(validations: InputValidationResult[]) {
  return validations.findIndex((val) => val.isMissing || val.isInvalid) >= 0;
}

export {
  validateHasValue,
  validateEmail,
  validateMobile,
  validatePostCode,
  validateStringLength,
  validateOrganizationNumber,
  validateNumberBetween,
  hasValidationError,
};

import { Timestamp } from ".";

export enum ParameterType {
    All = "All",
    VehicleType = "VehicleType",
    ProductType = "ProductType",
  }

export interface IParameter {
  id: string;
  workspaceId: string;
  code: string;
  type: ParameterType;
  description?: string | null;
  createdAt?: Timestamp;
}

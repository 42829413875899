/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC, PropsWithRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Avatar, Dropdown } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../types";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../../firebaseConfig";
import { useUserSession } from "../../../context/UserContext";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { useUserWorkspaces } from "../../../hooks";
import { IWorkspaceAccess } from "../../../types";
import { UserAvatar } from "../../atoms/Icons/Icons";

export const UserDropdown: FC<PropsWithRef<IUser>> = function (user: IUser) {
  const navigate = useNavigate();
  const { t } = useTranslation("navigation");
  const { userSession, setUserSession, revokeUserSession } = useUserSession();
  const { activeWorkspace, setActiveWorkspace } = useWorkspace();
  const query = useUserWorkspaces(userSession?.user.email ?? "");

  let workspaces: IWorkspaceAccess[] = [];
  if (query && !query.isLoading && query.isFetched && query.data)
    workspaces = query.data;

  useEffect(() => {}, [activeWorkspace, setActiveWorkspace]);

  if (userSession == null || query.isLoading) return null;

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={
        <span>
          <span className="sr-only">{t("navbar.title")}</span>
          <UserAvatar className="h-8 rounded" />
        </span>
      }
    >
      <Dropdown.Header>
        {user.displayName && (
          <span className="block text-sm">{user.displayName}</span>
        )}
        <span className="block truncate text-sm font-medium">{user.email}</span>
      </Dropdown.Header>
      <Dropdown.Item onClick={() => navigate("/profile")}>
        {t("navbar.settings")}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        className="font-light"
        onClick={() => navigate("/my_workspaces")}
      >
        {t("navbar.my_workspaces")}
      </Dropdown.Item>
      {workspaces.map((workspace) => (
        <Dropdown.Item
          className="ml-2"
          key={workspace.workspaceId} 
          onClick={() => {
            setActiveWorkspace(workspace);
            navigate("/");
          }}
        >
          {workspace.workspaceName}
        </Dropdown.Item>
      ))}
      <Dropdown.Divider />

      <Link
        reloadDocument
        to="/login"
        onClick={async () =>
          signOut(firebaseAuth).then((res) => {
            setUserSession(undefined);
            revokeUserSession();
          })
        }
      >
        <Dropdown.Item>{t("navbar.logout")}</Dropdown.Item>
      </Link>
    </Dropdown>
  );
};
